import Button from '@ingka/button'
import {
  breakpointM,
  breakpointXl,
  space50,
  space75,
  space100,
  space150,
} from '@ingka/variables'

import { colourSecondary } from 'styles/tokens/insikt.tokens'
import { colourBorderLight } from 'styles/tokens/oldSkapa.tokens'

import { Heading as OriginalSubHeading } from 'components/primitives/Text'
import styled from 'styled-components'

export const Header = styled.header`
  margin: 0 auto ${space100} auto;
  max-width: ${breakpointXl};

  column-gap: ${space150};

  box-sizing: border-box;

  @media (min-width: ${breakpointM}) {
    margin-top: ${space150};
  }
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  border-bottom: 1px solid ${colourBorderLight};
  padding-bottom: ${space75};
`

export const UserName = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${space50};
`

export const MenuButton = styled(Button)``

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${space100};
  cursor: pointer;
`

export const RoutesWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SubHeading = styled(OriginalSubHeading)`
  color: ${colourSecondary.main};
`

export const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`
