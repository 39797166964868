import { skipToken } from '@reduxjs/toolkit/dist/query'

import { useCurrentCoachingSession } from 'hooks/coachingSessions'
import { useInteractionsForCoachingSession } from 'hooks/coachingSessions/useInteractionsForCoachingSession.hooks'
import { useMissionsForCoachingSession } from 'hooks/coachingSessions/useMissionsForCoachingSession.hooks'

import { CoachingSessionPreviewSkeleton } from 'components/composites/CoachingSessions/CoachingSessionPreview'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { CurrentCoachingSessionHeroProps } from './CoachingSessionHero.types'
import { CurrentCoachingSession } from './CurrentCoachingSession'
import { NoPlannedCoachingSessionPrompt } from './NoPlannedCoachingSessionPrompt'

const CurrentCoachingSessionHero: React.FC<CurrentCoachingSessionHeroProps> = ({
  coworkerId,
}) => {
  const {
    data: currentCoachingSession,
    isLoading: isLoadingCurrentCoachingSession,
    isSuccess: isSuccessCurrentCoachingSession,
    isError: isErrorCurrentCoachingSession,
  } = useCurrentCoachingSession(coworkerId)

  const {
    data: interactions = [],
    isLoading: isLoadingInteractions,
    isSuccess: isSuccessInteractions,
    isError: isErrorInteractions,
  } = useInteractionsForCoachingSession(
    currentCoachingSession
      ? { coworkerId, coachingSessionId: currentCoachingSession.id }
      : skipToken
  )
  const {
    data: missions = [],
    isLoading: isLoadingMissions,
    isSuccess: isSuccessMissions,
    isError: isErrorMissions,
  } = useMissionsForCoachingSession(
    currentCoachingSession
      ? { coworkerId, coachingSessionId: currentCoachingSession.id }
      : skipToken
  )

  const isLoading =
    isLoadingCurrentCoachingSession ||
    isLoadingInteractions ||
    isLoadingMissions

  const isSuccess =
    isSuccessCurrentCoachingSession &&
    isSuccessInteractions &&
    isSuccessMissions

  const isError =
    isErrorCurrentCoachingSession || isErrorInteractions || isErrorMissions

  return (
    <>
      {isLoading && <CoachingSessionPreviewSkeleton />}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {!currentCoachingSession && (
            <NoPlannedCoachingSessionPrompt coworkerId={coworkerId} />
          )}
          {currentCoachingSession && (
            <CurrentCoachingSession
              coworkerId={coworkerId}
              coachingSession={currentCoachingSession}
              interactions={interactions}
              missions={missions}
            />
          )}
        </>
      )}
    </>
  )
}

export default CurrentCoachingSessionHero
