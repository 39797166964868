import { Country } from 'lib/types/country.types'

import { apiSlice } from 'state/slices/api/api.slice'

type UpdateCountrySettingParams = {
  countryCode: string
  setting: string
  value: boolean
}

const countrySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCountries: builder.query<Country[], void>({
      query: (searchTerm) => ({
        url: `/countries`,
      }),
      providesTags: ['Country'],
    }),
    updateCountrySettings: builder.mutation<void, UpdateCountrySettingParams>({
      query: ({ countryCode, setting, value }) => ({
        url: `/countries/${countryCode}/settings/${setting}`,
        method: 'PUT',
        body: { value },
      }),
      invalidatesTags: ['Country'],
    }),
  }),
})

export const {
  useFetchCountriesQuery: useCountries,
  useUpdateCountrySettingsMutation: useUpdateCountrySettings,
} = countrySlice
