import { t } from 'i18next'
import { useMemo } from 'react'

import { MetricId } from 'config/domain/metrics.config'

import { MetricOwnerType } from 'lib/types/metric.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useFormatMetric } from 'hooks/metrics/useFormatMetric'
import { usePreconfiguredMetric } from 'hooks/metrics/usePreconfiguredMetric.hooks'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { getPeriodConfig } from 'components/features/Metrics/Stat/Stat.utils'
import { Caption } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import * as Styled from './MissionCurrentValue.styling'

type MissionCurrentValueProps = {
  id: string
  metricId: MetricId
  idType?: 'coworker' | 'team'
  ownerType: MetricOwnerType.Coworker
  period: {
    start: Date
    end: Date
  }
  data?: number
}

const MissionCurrentValue: React.FC<MissionCurrentValueProps> = ({
  metricId,
  id,
  period,
  ownerType,
}) => {
  const { start, end } = useMemo(() => getPeriodConfig(period), [period])
  const {
    data: currentValue,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = usePreconfiguredMetric({
    metricId: metricId,
    ownerType: MetricOwnerType.Coworker,
    timeRange: {
      start: DateHelper.dateToString(start),
      end: DateHelper.dateToString(end),
    },
    periodType: 'dynamic',
  })

  /** 
  Todo: for now, start value is the average score over the last 3 months until the mission start date 
  **/
  /** 
  Perhaps not the most efficient/right solution either
   */
  const previousStartDate = DateHelper.subtractDays(new Date(start), 90)

  const { data: previousValue } = usePreconfiguredMetric({
    metricId: metricId,
    ownerType: MetricOwnerType.Coworker,
    timeRange: {
      start: DateHelper.dateToString(previousStartDate),
      end: DateHelper.dateToString(start),
    },
    periodType: 'dynamic',
  })

  const formatMetric = useFormatMetric(metricId, id, ownerType)
  let currentMetricValue = currentValue as number
  let previousMetricValue = previousValue as number

  return (
    <>
      {isLoading && <LoadingIndicator height="fit-content" />}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess &&
      !isFetching &&
      currentMetricValue !== undefined &&
      previousMetricValue !== undefined &&
      currentMetricValue > previousMetricValue ? (
        <>
          <Styled.PositiveNumber>
            {currentMetricValue
              ? formatMetric(currentMetricValue)
              : t('common.errors.n/a')}
          </Styled.PositiveNumber>
          <Caption>
            {previousMetricValue
              ? `Start (${formatMetric(previousMetricValue)})`
              : t('common.errors.n/a')}
          </Caption>
        </>
      ) : (
        <>
          <Styled.NegativeNumber>
            {currentMetricValue
              ? formatMetric(currentMetricValue)
              : t('common.errors.n/a')}
          </Styled.NegativeNumber>
          <Caption>
            {previousMetricValue
              ? `Start (${formatMetric(previousMetricValue)})`
              : t('common.errors.n/a')}
          </Caption>
        </>
      )}
    </>
  )
}

export default MissionCurrentValue
