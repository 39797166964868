import { Box, Flex } from '@mantine/core'

import { colourSemanticCaution } from 'styles/tokens/oldSkapa.tokens'

import { Heading } from 'components/primitives/Text'

/**
 * A component for showing the current environment in dev and pre-prod but not prod.
 * @returns React.FC
 */
const EnvironmentStickyHeader: React.FC = () => {
  if (process.env.REACT_APP_ENV === 'prod') return null

  return (
    <>
      <Box
        pos="sticky"
        top="0"
        bg={colourSemanticCaution}
        sx={{
          zIndex: 1000,
        }}
      >
        <Flex justify="center" align="center">
          <Heading>{process.env.REACT_APP_ENV?.toUpperCase()}</Heading>
        </Flex>
      </Box>
    </>
  )
}

export default EnvironmentStickyHeader
