import { Link } from 'react-router-dom'

import { ConditionalLinkProps } from './ConditionalLink.types'

/**
 * Extension of the Link component that only renders a link if a condition is met, otherwise rendering the children by themselves.
 */
const ConditionalLink: React.FC<ConditionalLinkProps> = ({
  condition,
  children,
  ...otherProps
}) => {
  if (condition) {
    return <Link {...otherProps}>{children}</Link>
  }
  return <>{children}</>
}
export default ConditionalLink
