import { CountryOverview } from 'components/features/Countries/CountryOverview'
import { Header } from 'components/layout/Header'

const ManageCountriesPage = () => (
  <>
    <Header title="Manage countries" backHref="/admin" />
    <CountryOverview />
  </>
)

export default ManageCountriesPage
