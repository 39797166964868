import Skeleton from '@ingka/skeleton'
import { Stack } from '@mantine/core'

import { getSkeletonWidth } from 'lib/utils/skeletons.helper'

import { Widget } from 'components/composites/Shared/Widget'

const MISSION_SKELETON_COUNT = 2
const ACTION_POINT_SKELETON_COUNT = 3

const CoachingSessionOverviewSkeleton: React.FC = () => {
  return (
    <Widget>
      <Stack>
        <Skeleton width={getSkeletonWidth(30, 40, 'ch')} />
        {Array.from({ length: MISSION_SKELETON_COUNT }).map((_, index) => (
          <Skeleton width="100%" key={index} />
          // <MissionPreviewSkeleton key={index} />
        ))}

        {Array.from({ length: ACTION_POINT_SKELETON_COUNT }).map((_, index) => (
          <Skeleton width="100%" key={index} />
          // <ActionPointPreviewSkeleton key={index} />
        ))}
      </Stack>
    </Widget>
  )
}

export default CoachingSessionOverviewSkeleton
