import Button from '@ingka/button'
import { Grid } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions } from 'state/slices/api'

import {
  CoachingSessionPreview,
  CoachingSessionPreviewSkeleton,
} from 'components/composites/CoachingSessions/CoachingSessionPreview'
import StickyFooterCoachingSession from 'components/layout/StickyFooter/StickyFooter.component'
import { Paragraph } from 'components/primitives/Text'
import { FeatureToggle } from 'components/shared/FeatureToggle'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { NoPlannedCoachingSessionPrompt } from '../CoachingSessionHero/NoPlannedCoachingSessionPrompt'
import { CoachingSessionPreviewListProps } from './CoachingSessionPreviewList.types'

const SKELETON_COUNT = 4

const CoachingSessionPreviewList: React.FC<CoachingSessionPreviewListProps> = ({
  coworkerId,
}) => {
  const {
    data: coachingSessions,
    isLoading,
    isError,
    isSuccess,
  } = useCoachingSessions(coworkerId)
  const { teamleadPath } = useSectionPaths()
  const pageType = useCurrentPageType()
  const pathname = useLocation()

  const { t } = useTranslation()

  const coachingSessionDraftExists = coachingSessions?.some(
    (coachingSession) => coachingSession.status === CoachingSessionStatus.Draft
  )

  return (
    <>
      {isLoading && (
        <Grid>
          {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
            <Grid.Col md={6} key={index}>
              <CoachingSessionPreviewSkeleton />
            </Grid.Col>
          ))}
        </Grid>
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {(!coachingSessions || coachingSessions.length === 0) && (
            <NoPlannedCoachingSessionPrompt coworkerId={coworkerId} />
          )}
          {coachingSessions && coachingSessions.length !== 0 && (
            <Grid>
              {coachingSessions.map((coachingSession) => (
                <Grid.Col md={6} key={coachingSession.id}>
                  <CoachingSessionPreview coachingSession={coachingSession} />
                </Grid.Col>
              ))}
            </Grid>
          )}
          {pageType === 'teamlead' && (
            <FeatureToggle featureKey="coaching_session">
              {coachingSessions &&
                coachingSessions?.map((coachingSession) => (
                  <StickyFooterCoachingSession key={coachingSession.id}>
                    <Paragraph>
                      {coachingSessionDraftExists
                        ? t(
                            'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.resume-guided-flow.title'
                          )
                        : t(
                            'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.start-guided-flow.title'
                          )}
                    </Paragraph>
                    <Link
                      to={`${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSession.id}/edit/`}
                      state={{ from: pathname }}
                    >
                      <Button
                        small
                        type="primary"
                        text={
                          !coachingSessionDraftExists
                            ? t(
                                'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.start-guided-flow.actions'
                              )
                            : t(
                                'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.resume-guided-flow.actions'
                              )
                        }
                      />
                    </Link>
                  </StickyFooterCoachingSession>
                ))}
            </FeatureToggle>
          )}
        </>
      )}
    </>
  )
}

export default CoachingSessionPreviewList
