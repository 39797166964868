import SkapaText from '@ingka/text'
import { space100, space125 } from '@ingka/variables'

import styled from 'styled-components'

import { TextProps } from './Text.types'

const Text = styled(SkapaText)<TextProps>`
  ${({ $bold }) => ($bold ? 'font-weight: bold;' : '')}
  ${({ $italic }) => ($italic ? 'font-style: italic;' : '')}
`

/** Large text used for titles. */
export const Title = styled(Text)`
  margin-bottom: ${space125};
`

Title.defaultProps = {
  headingSize: 'l',
  tagName: 'h2',
}

/** Large text used short-length text of medium emphasis. */
export const Heading = styled(Text)``

Heading.defaultProps = {
  headingSize: 'm',
  tagName: 'h3',
}

export const Subtitle = styled(Heading)`
  margin-bottom: ${space100};
`

/** Medium-size text used short-length text of lower emphasis. */
export const SubHeading = styled(Text)``

SubHeading.defaultProps = {
  headingSize: 's',
  tagName: 'h4',
}

/** Text used for paragraphs. */
export const Paragraph = styled(Text)``

/* Small text used for annotations. **/
export const Caption = styled(Text)`
  font-style: italic;
`

Caption.defaultProps = {
  bodySize: 's',
}

/** Small text used before titles to introduce headlines. */
export const Overline = styled(Text)`
  text-transform: uppercase;
`

Overline.defaultProps = {
  bodySize: 's',
}
