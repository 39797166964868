import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { CoachingSession } from 'lib/types/coachingSession.types'

import { useUser } from 'hooks/users'

import { useCoworker } from '../../api.hooks'
import { useCoachingSessions } from './coachingSession.slice'

export const useCoachingSessionsWrapper = (coworkerId: string | SkipToken) => {
  const { data: user } = useUser()
  const { role } = user ?? {}

  const isTl = role === 'teamlead'

  const regularQuery = useCoachingSessions(isTl ? skipToken : coworkerId)
  const teamleadQuery = useCoworker(!isTl ? skipToken : coworkerId)

  if (isTl) {
    const { data, ...coworkerMeta } = teamleadQuery
    const coachingSessions = (data as any)?.coachingSessions as
      | CoachingSession[]
      | undefined
    return {
      ...coworkerMeta,
      data: coachingSessions,
      currentData: coachingSessions,
    }
  }

  return regularQuery
}
