import FormField from '@ingka/form-field'
import InlineMessage from '@ingka/inline-message'
import InputField from '@ingka/input-field'
import Table, { TableBody, TableHeader } from '@ingka/table'
import { useDebouncedValue } from '@mantine/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable } from 'react-table'

import { debounceTimeDefault } from 'styles/tokens/insikt.tokens'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import {
  useCoworkerOverviewColumns,
  useCoworkerOverviewData,
  useHandleRowClicked,
} from './CoworkerOverview.hooks'
import * as Styled from './CoworkersOverview.styling'
import { CoworkersOverviewProps } from './CoworkersOverview.types'

const CoworkersOverview = ({
  legacyId,
  showSearchBar,
}: CoworkersOverviewProps) => {
  const { t } = useTranslation()

  const columns = useCoworkerOverviewColumns()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [debouncedSearchQuery] = useDebouncedValue(
    searchQuery,
    debounceTimeDefault
  )

  const {
    data,
    isError: isErrorCoworkers,
    isFetching: isFetchingCoworkers,
    isSuccess: isSuccessCoworkers,
  } = useCoworkerOverviewData(legacyId, debouncedSearchQuery)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  const handleRowClicked = useHandleRowClicked()
  const noCoworkers = data.length === 0

  return (
    <>
      {isFetchingCoworkers && !isSuccessCoworkers && (
        <LoadingIndicator
          message={t('loading-messages.fetching-coworker-profiles')}
        />
      )}
      {isErrorCoworkers && <UnexpectedErrorMessage />}
      {isSuccessCoworkers && (
        <>
          {/** Case when user has access to no co-workers */}
          {noCoworkers && (
            <InlineMessage
              variant="informative"
              title={t('features.coworker.coworkers-overview.errors.no-coworkers.title')}
              body={t(
                'features.coworker.coworkers-overview.errors.no-coworkers.message'
              )}
            />
          )}
          {/** Case when user has access to at least one co-worker */}
          {!noCoworkers && (
            <>
              {showSearchBar && (
                <FormField>
                  <InputField
                    label="Search for a co-worker"
                    id="searchQuery"
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </FormField>
              )}
              <Table
                width="100%"
                inset
                {...getTableProps}
                data-testid="coworkers-table"
              >
                <TableHeader sticky data-testid="coworkers-table-header">
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      data-testid="coworkers-table-header-row"
                    >
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </TableHeader>
                <TableBody
                  data-testid="coworkers-table-body"
                  striped
                  {...getTableBodyProps}
                >
                  {rows.map((row) => {
                    prepareRow(row)
                    return (
                      <Styled.TableRow
                        active={row.original.active}
                        data-testid="coworkers-table-body-row"
                        {...row.getRowProps()}
                        onClick={
                          row.original.active
                            ? () => handleRowClicked(row.original)
                            : undefined
                        }
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </Styled.TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </>
          )}
        </>
      )}
    </>
  )
}

export default CoworkersOverview
