import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import {
  AchievedBadge,
  Coworker,
  InactiveCoworkerProfile,
} from 'lib/types/coworker.types'

import { apiSlice } from 'state/slices/api/api.slice'

type FetchCoworkersByTeamleadIdResponse = {
  activeCoworkers: Coworker[]
  inactiveCoworkers: InactiveCoworkerProfile[]
}

type SetCoworkerRoleParams = {
  coworkerId: string
  role: string
}

type AwardBadgeParams = {
  coworkerId: string
  awarderId: string
  badgeId: string
  message: string
}

const coworkerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    awardBadge: builder.mutation<AchievedBadge, AwardBadgeParams>({
      query: ({ coworkerId, awarderId, badgeId, message }) => ({
        url: `/coworkers/${coworkerId}/award-badge`,
        method: 'POST',
        body: { awarderId, badgeId, message },
      }),
      invalidatesTags: (result, error, arg) => ['Coworker'],
    }),

    setCoworkerRole: builder.mutation<Coworker, SetCoworkerRoleParams>({
      query: ({ coworkerId, role }) => ({
        url: `/coworkers/${coworkerId}/role`,
        method: 'POST',
        body: { coworkerId, role },
      }),
      invalidatesTags: (result, error, arg) => ['Coworker'],
    }),
    findCoworkerById: builder.query<Coworker, string>({
      query: (coworkerId) => `/coworkers/${coworkerId}`,
      providesTags: ['Coworker'],
    }),
    fetchCoworkersByTeamleadId: builder.query<
      FetchCoworkersByTeamleadIdResponse,
      string
    >({
      query: (teamleadId) => `/coworkers/byTeamleadId/${teamleadId}`,
      transformErrorResponse: (error: FetchBaseQueryError) => {
        if (error.status === 404) return []
        else throw error
      },
      providesTags: [
        'Coworker',
        'Mission',
        'Interaction',
        'Action Point',
        'Coaching Session',
      ],
    }),
  }),
})

export const {
  useFindCoworkerByIdQuery,
  useFetchCoworkersByTeamleadIdQuery,
  useAwardBadgeMutation,
  useSetCoworkerRoleMutation,
} = coworkerSlice
