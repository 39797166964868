import { Box, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { BadgeLine } from 'components/composites/Badges'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { Subtitle } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useOrganisedBadges } from './BadgeList.hooks'
import { BadgeListProps } from './BadgeList.types'

const BadgeList: React.FC<BadgeListProps> = ({ coworkerId }) => {
  const { t } = useTranslation()
  const {
    data: { categories, unlockedAwardableBadges },
    isLoading,
    isSuccess,
    isError,
  } = useOrganisedBadges(coworkerId)

  return (
    <>
      {isLoading && (
        <LoadingIndicator message={t('loading-messages.fetching-badges')} />
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <Stack>
          {categories.map(({ name, locked, unlocked }) => (
            <Box key={name}>
              <Subtitle>{name}</Subtitle>
              <Stack>
                {unlocked.map((badge) => {
                  return (
                    <BadgeLine
                      key={badge.current.badgeDefinitionId}
                      badge={badge.current}
                    />
                  )
                })}
                {locked
                  .filter((badge) => !badge.current.secret)
                  .map((badge) => (
                    <BadgeLine
                      key={badge.current.badgeDefinitionId}
                      badge={badge.current}
                    />
                  ))}
              </Stack>
            </Box>
          ))}
          {unlockedAwardableBadges.length !== 0 && (
            <Box>
              <Subtitle>{t('features.badge.awarded-badges')}</Subtitle>
              <Stack>
                {unlockedAwardableBadges.map((badge) => (
                  <BadgeLine key={badge.badgeDefinitionId} badge={badge} />
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      )}
    </>
  )
}

export default BadgeList
