import _ from 'lodash'

import {
  AchievedBadgeDefinition,
  BadgeDefinition,
  isAchievableBadge,
  isAwardableBadge,
  isGroupedBadgeDefinition,
} from 'lib/types/badge.types'
import { AchievedBadge } from 'lib/types/coworker.types'

/**
 * Groups an array badges based on their groupId and type.
 */
export const useBadgeGrouping = (
  badges: BadgeDefinition[] | undefined = []
) => {
  const awardableBadges = badges.filter(isAwardableBadge)
  const achievableBadges = badges.filter(isAchievableBadge)

  const groupedAchievableBadges = _.groupBy(
    achievableBadges.filter(isGroupedBadgeDefinition),
    (badge) => badge.group.groupId
  )

  const ungroupedAchievableBadges = achievableBadges.filter(
    (badge) => !isGroupedBadgeDefinition(badge)
  )

  return {
    groupedAchievableBadges,
    ungroupedAchievableBadges,
    awardableBadges,
  }
}

/**
 * Iterates over a `UnlockedBadge[]` and `BadgeDefinitiin[]` to group badges based on their type,
 * their groups and whether they are unlocked or not.
 */
export const useUnlockedBadgeGrouping = (
  unlockedBadges: AchievedBadge[] | undefined = [],
  badges: BadgeDefinition[] | undefined = []
) => {
  const {
    groupedAchievableBadges,
    ungroupedAchievableBadges,
    awardableBadges,
  } = useBadgeGrouping(badges)

  const allUnlockedAchievableBadges = getUnlockedBadgeDefinitions(
    unlockedBadges,
    badges
  )

  const allLockedAchievableBadges = badges.filter(
    (badge) =>
      !unlockedBadges.some(
        (unlockedBadge) =>
          unlockedBadge.badgeDefinitionId === badge.badgeDefinitionId
      )
  )

  const achievableBadgeGroups = Object.entries(groupedAchievableBadges).map(
    ([groupId, badgeGroup]) => ({
      unlocked: [
        ...getUnlockedBadgeDefinitions(unlockedBadges, badgeGroup),
      ].sort(sortByOrder),
      locked: [
        ...badgeGroup.filter(
          (badge) => !isBadgeUnlocked(unlockedBadges, badge)
        ),
      ].sort(sortByOrder),
    })
  )

  const unlockedUngroupedAchievableBadges = getUnlockedBadgeDefinitions(
    unlockedBadges,
    ungroupedAchievableBadges
  )

  const lockedUngroupedAchievableBadges = ungroupedAchievableBadges.filter(
    (badge) => !isBadgeUnlocked(unlockedBadges, badge)
  )

  const unlockedAwardableBadges = getUnlockedBadgeDefinitions(
    unlockedBadges,
    awardableBadges
  )

  return {
    achievableBadgeGroups,
    unlockedUngroupedAchievableBadges,
    lockedUngroupedAchievableBadges,
    allUnlockedAchievableBadges,
    allLockedAchievableBadges,
    unlockedAwardableBadges,
  }
}

export const getUnlockedBadgeDefinitions = (
  unlockedBadges: AchievedBadge[],
  badgeDefinitions: BadgeDefinition[]
) =>
  (unlockedBadges
    ?.map((unlockedBadge) => {
      const badgeDefinition = findUnlockedBadge(unlockedBadge, badgeDefinitions)
      return (
        badgeDefinition &&
        mergeUnlockedBadgeDefinition(badgeDefinition, unlockedBadge)
      )
    })
    .filter((badge) => badge != null) as AchievedBadgeDefinition[]) ?? []

const findUnlockedBadge = (
  unlockedBadge: AchievedBadge,
  badges: BadgeDefinition[]
) =>
  badges.find(
    (badge) => badge.badgeDefinitionId === unlockedBadge.badgeDefinitionId
  )

const mergeUnlockedBadgeDefinition = (
  badgeDefinition: BadgeDefinition,
  unlockedBadge: AchievedBadge
): AchievedBadgeDefinition => {
  const { badgeDefinitionId: badgeId, ...badgeDefinitionRest } = badgeDefinition
  return {
    ...unlockedBadge,
    ...badgeDefinitionRest,
  }
}

const isBadgeUnlocked = (
  unlockedBadges: AchievedBadge[],
  badge: BadgeDefinition
) =>
  unlockedBadges.some(
    (unlockedBadge) =>
      unlockedBadge.badgeDefinitionId === badge.badgeDefinitionId
  ) ?? false

const sortByOrder = (
  badgeA: BadgeDefinition | AchievedBadgeDefinition,
  badgeB: BadgeDefinition | AchievedBadgeDefinition
) =>
  (badgeA.group?.order ?? Number.MAX_SAFE_INTEGER) -
  (badgeB.group?.order ?? Number.MAX_SAFE_INTEGER)
