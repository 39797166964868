import { LoadingBall } from '@ingka/loading'
import SSRIcon from '@ingka/ssr-icon'
import arrowDown from '@ingka/ssr-icon/paths/arrow-down'
import arrowUp from '@ingka/ssr-icon/paths/arrow-up'
import { Box, Flex } from '@mantine/core'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  isMetricId,
  metricsConfig,
  qualityAssessmentConfig,
} from 'config/domain/metrics.config'
import { isQaMetricId } from 'config/domain/qa.config'

import { FormattingHelper } from 'lib/utils/formatting.helper'

import { useFormatMetric } from 'hooks/metrics/useFormatMetric'
import { usePreconfiguredMetric } from 'hooks/metrics/usePreconfiguredMetric.hooks'
import { useVatInfoText } from 'hooks/metrics/useVatInfoText.hook'

import { coworkerColour, teamColour } from 'styles/tokens/insikt.tokens'

import { Caption, Heading, SubHeading } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useStatValues } from './Stat.hooks'
import * as Styled from './Stat.styling'
import { StatProps } from './Stat.types'
import { getPeriodConfig } from './Stat.utils'

const Stat: React.FC<StatProps> = ({
  title,
  metricId,
  id,
  period,
  ownerType,
}) => {
  const { t } = useTranslation()
  const formatMetric = useFormatMetric(metricId, id, ownerType)

  const { start, end, periodType } = useMemo(
    () => getPeriodConfig(period),
    [period],
  )

  const { data, isError, isFetching, isSuccess } = usePreconfiguredMetric({
    metricId: metricId,
    ownerType,
    timeRange: {
      start: format(start, 'yyyy-MM-dd'),
      end: format(end, 'yyyy-MM-dd'),
    },
    periodType,
  })

  const vatInfoText = useVatInfoText(
    metricId,
    ownerType,
    {
      start,
      end,
    },
    periodType,
  )

  const { thisPeriodValue, difference } = useStatValues({ data })

  const indicatorConfig = isMetricId(metricId)
    ? metricsConfig[metricId]
    : isQaMetricId(metricId)
      ? qualityAssessmentConfig
      : undefined

  const { metricBehaviour } = indicatorConfig ?? {}
  const { decimalPlaces, unit } = metricBehaviour ?? {}

  return (
    <Flex direction="column">
      {title && <Caption>{title}</Caption>}
      {isFetching && <LoadingBall />}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && !isFetching && (
        <>
          <Flex align="center">
            <Box
              h="75%"
              w="0.5rem"
              mr="0.5rem"
              bg={ownerType === 'team' ? teamColour : coworkerColour}
            />
            <Heading headingSize="l">
              {thisPeriodValue !== undefined
                ? formatMetric(thisPeriodValue)
                : t('common.errors.n/a')}
            </Heading>
          </Flex>
          {unit === 'monetary' && (
            <SubHeading>{t(`common.metrics.with-vat`)}</SubHeading>
          )}
          {metricBehaviour?.includesVat && isMetricId(metricId) && (
            <Caption>{vatInfoText}</Caption>
          )}
          {difference != null && difference !== 0 && (
            <Styled.DifferenceText difference={difference}>
              {difference > 0 && <SSRIcon paths={arrowUp} />}
              {difference < 0 && <SSRIcon paths={arrowDown} />}
              {FormattingHelper.formatNumber(difference, decimalPlaces)}
            </Styled.DifferenceText>
          )}
        </>
      )}
    </Flex>
  )
}

export default Stat
