import Button from '@ingka/button'
import arrowLeft from '@ingka/ssr-icon/paths/arrow-left'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { useDebouncedValue } from '@mantine/hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { toNumber } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { DateHelper } from 'lib/utils/date.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useUser } from 'hooks/users'

import {
  use_assignMissionOLDMutation,
  useCoworker,
  useTeamQuery,
} from 'state/slices/api'

import { debounceTimeDefault } from 'styles/tokens/insikt.tokens'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { Header } from 'components/layout/Header'
import {
  DurationStep,
  MetricStep,
  ReviewStep,
  TargetStep,
  TitleStep,
} from 'components/pages/teamlead/AssignMissionPage/Steps'

import * as Styled from './AssignMissionPage.styling'
import { AssignMissionFormFields } from './AssignMissionPage.types'

const AssignMissionPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { coworkerId } = useParams()
  const { teamleadPath } = useSectionPaths()

  const { data: coworker, isLoading: isLoadingCoworker } =
    useCoworker(coworkerId)
  const { data: team, isLoading: isLoadingTeam } = useTeamQuery(
    coworker?.teamId ?? skipToken
  )

  const { data: user } = useUser()
  const form = useForm<AssignMissionFormFields>({
    defaultValues: {
      title: '',
      description: '',
      startDate: new Date(),
      endDate: (() => {
        const date = new Date()
        date.setDate(date.getDate() + 31)
        return date
      })(),
      metricId: undefined,
      target: '',
      connectedMetricId: '',
    },
  })

  const { getValues, handleSubmit, trigger } = form

  const [step, setStep] = useState('metric')

  const [
    assignMission,
    {
      isLoading: isLoadingAssignMission,
      isSuccess: isSuccessAssignMission,
      error,
    },
  ] = use_assignMissionOLDMutation()

  const [debouncedIsLoadingAssignMission] = useDebouncedValue(
    isLoadingAssignMission,
    debounceTimeDefault
  )

  const onSubmit = () => {
    if (!user || !coworkerId) return

    const {
      metricId,
      target,
      startDate,
      endDate,
      title,
      description,
      connectedMetricId,
    } = getValues()
    const missionInput = {
      metricId,
      target: toNumber(target),
      startDate: DateHelper.dateToString(startDate),
      endDate: DateHelper.dateToString(endDate),
      title,
      description,
      assignedBy: user.legacyId,
      connectedMetricId,
    }

    assignMission({
      coworkerId,
      missionInput,
    })
  }

  useEffect(() => {
    if (isSuccessAssignMission) {
      console.log('Mission assigned, renavigating')
      navigate(`${teamleadPath}/coworkers/${coworkerId}/missions`)
    }
  }, [coworkerId, isSuccessAssignMission, navigate, teamleadPath])

  const handleNextClicked = () => {
    if (step === 'metric') {
      trigger(['metricId']).then((valid) => {
        if (valid) {
          setStep('target')
        }
      })
    } else if (step === 'target') {
      trigger(['target']).then((valid) => {
        if (valid) {
          setStep('duration')
        }
      })
    } else if (step === 'duration') {
      trigger(['startDate', 'endDate']).then((valid) => {
        if (valid) {
          setStep('title')
        }
      })
    } else if (step === 'title') {
      trigger(['title', 'description']).then((valid) => {
        if (valid) {
          setStep('review')
        }
      })
    }
  }

  const handleBackClicked = () => {
    if (step === 'target') {
      setStep('metric')
    } else if (step === 'duration') {
      setStep('target')
    } else if (step === 'title') {
      setStep('duration')
    } else if (step === 'review') {
      setStep('title')
    }
  }

  return (
    <>
      <div data-testid="page-assign-mission">
        <Header
          backHref={`${teamleadPath}/coworkers/${coworkerId}`}
          title={`${t('features.mission.assign-mission.title')} - ${
            coworker?.fullName ?? '...'
          }`}
        />
        {isLoadingCoworker && (
          <LoadingIndicator
            message={t('loading-messages.fetching-coworker-profile')}
          />
        )}
        {isLoadingTeam && (
          <LoadingIndicator message={t('loading-messages.fetching-team')} />
        )}
        {coworker && team && (
          <>
            <Styled.NavigationButtonsContainer>
              <Button
                type="secondary"
                ssrIcon={arrowLeft}
                text={t('common.actions.back')}
                onClick={handleBackClicked}
                disabled={step === 'metric'}
              />

              {step !== 'review' && (
                <Button
                  type="primary"
                  ssrIcon={arrowRight}
                  iconPosition="trailing"
                  text={t('common.actions.next')}
                  onClick={handleNextClicked}
                />
              )}
              {step === 'review' && (
                <Button
                  type="primary"
                  ssrIcon={arrowRight}
                  iconPosition="trailing"
                  text={t('common.actions.submit')}
                  onClick={handleSubmit(onSubmit)}
                  loading={debouncedIsLoadingAssignMission}
                />
              )}
            </Styled.NavigationButtonsContainer>
            <div>
              {step === 'metric' && (
                <MetricStep form={form} coworker={coworker} />
              )}
              {step === 'target' && (
                <TargetStep form={form} coworker={coworker} team={team} />
              )}
              {step === 'duration' && (
                <DurationStep form={form} coworker={coworker} />
              )}
              {step === 'title' && (
                <TitleStep form={form} coworker={coworker} />
              )}
              {step === 'review' && (
                <ReviewStep form={form} coworker={coworker} />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default AssignMissionPage
