import Skeleton from '@ingka/skeleton'
import { space50 } from '@ingka/variables'
import { Box, Stack } from '@mantine/core'

import { getSkeletonWidth } from 'lib/utils/skeletons.helper'

import { Widget } from 'components/composites/Shared/Widget'

import * as Styled from '../InteractionCard.styling'

const InteractionCardSkeleton: React.FC = () => {
  return (
    <Widget>
      {/* Interaction Section */}
      <Styled.CardFlex>
        <Stack>
          <Box mb={space50}>
            <Skeleton width="50%" />
          </Box>

          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
        </Stack>
        <Styled.Divider />
        <Stack>
          <Box mb={space50}>
            <Skeleton width="50%" />
          </Box>
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
          <Skeleton height="1rem" width={getSkeletonWidth(70, 100, '%')} />
        </Stack>
      </Styled.CardFlex>
    </Widget>
  )
}

export default InteractionCardSkeleton
