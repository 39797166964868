import { space100, space300 } from "@ingka/variables"
import { Flex, FlexProps } from "@mantine/core"
import * as Styled from "./StickyFooter.styling"

type StickyFooterProps = {
  children: React.ReactNode
} & FlexProps

const StickyFooter: React.FC<StickyFooterProps> = ({
  children,
  ...htmlAttributes
}) => {
  return (
    <Styled.StickyFooterWrapper  {...htmlAttributes}>
      <Styled.StickyFooterInner>
        <Flex
          justify="flex-end"
          align='center'
          px={space300}
          py={space100}
          gap={space100}>
          {children}
        </Flex>
      </Styled.StickyFooterInner>
    </Styled.StickyFooterWrapper>
  )
}

export default StickyFooter
