import { useParams } from 'react-router-dom'

import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

export const useBackHref = () => {
  const { teamleadPath } = useSectionPaths()
  const { coworkerId, coachingSessionId } = useParams()

  const defaultPath = `${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSessionId}`

  return useDefaultBackHref(defaultPath)
}
