import Skeleton from '@ingka/skeleton'
import { space50 } from '@ingka/variables'
import { Stack } from '@mantine/core'

import { getSkeletonWidth } from 'lib/utils/skeletons.helper'

import { Widget } from 'components/composites/Shared/Widget'
import WidgetFooter from 'components/composites/Shared/Widget/WidgetFooter.component'
import WidgetHeader from 'components/composites/Shared/Widget/WidgetHeader.component'

const WidgetSkeleton: React.FC = () => (
  <Widget
    header={
      <WidgetHeader
        title={
          <Stack spacing={space50}>
            <Skeleton width={getSkeletonWidth(30, 40, 'ch')} />
            <Skeleton height="0.75rem" width={getSkeletonWidth(40, 60, 'ch')} />
          </Stack>
        }
      />
    }
    footer={
      <WidgetFooter>
        <Skeleton width={getSkeletonWidth(15, 20, 'ch')} />
      </WidgetFooter>
    }
  />
)

export default WidgetSkeleton
