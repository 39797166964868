import { LoadingBall } from '@ingka/loading'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import {
  Coworker,
  InactiveCoworkerProfile,
  isInactiveCoworkerProfile,
} from 'lib/types/coworker.types'

import { CoworkersOverviewStatus } from 'components/composites/CoworkersOverviewStatus'
import { useCurrentCoachingSession } from 'hooks/coachingSessions'

type MissionStatusProps = {
  coworker: Coworker | InactiveCoworkerProfile
} & React.HTMLAttributes<HTMLDivElement>

/** Renders 0, 1 or multiple tooltip icons based on a co-worker object */
const RECOMMENDED_ASSESSMENT_COUNT = 5
const CoworkerStatus: React.FC<MissionStatusProps> = ({ coworker }) => {
  const { t } = useTranslation()
  const isInactive = isInactiveCoworkerProfile(coworker)

  const { data: currentCoachingSession, isLoading } = useCurrentCoachingSession(coworker.legacyId)
  if (isInactive) {
    return null
  }

  return (
    <>
      {isLoading && (
        <Flex justify="center" align="center">
          <LoadingBall size="medium" style={{ marginTop: '0.75rem' }} />
        </Flex>
      )}
      <Flex justify="center" align="center">

        <CoworkersOverviewStatus status={coworker.status} />
      </Flex>
    </>
  )
}

export default CoworkerStatus
