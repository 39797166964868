import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { useActionPoints, useCoachingSession } from 'state/slices/api'

export const useActionPointsForCoachingSession = (
  args: { coworkerId: string; coachingSessionId: string } | SkipToken
) => {
  const { data: coachingSession, ...metaCoachingSession } =
    useCoachingSession(args)
  const { data: actionPoints, ...metaInteractions } = useActionPoints(
    args === skipToken ? skipToken : args.coworkerId
  )

  const relatedActionPoints =
    (coachingSession &&
      actionPoints?.filter((actionPoint) =>
        coachingSession.actionPoints.includes(actionPoint.id)
      )) ??
    []

  const meta = metaCoachingSession.isSuccess
    ? metaInteractions
    : metaCoachingSession

  return {
    ...meta,
    data: relatedActionPoints,
    currentData: relatedActionPoints,
  }
}
