import laptopPencil from '@ingka/ssr-icon/paths/laptop-pencil'
import people from '@ingka/ssr-icon/paths/people'
import person from '@ingka/ssr-icon/paths/person'
import pieChart from '@ingka/ssr-icon/paths/pie-chart'
import star from '@ingka/ssr-icon/paths/star'
import visibilityShow from '@ingka/ssr-icon/paths/visibility-show'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

import * as Styled from './Sidebar.styling'

const TeamleadSidebarContent: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { teamleadPath } = useSectionPaths()
  const { coworkerId } = useParams()

  const pageType = useCurrentPageType()

  return (
    <>
      <Styled.SidebarButton
        $isActive={pathname === `${teamleadPath}/coworkers`}
        ssrIcon={people}
        onClick={() => navigate(`${teamleadPath}/coworkers`)}
      >
        {t('features.coworker.title')}
      </Styled.SidebarButton>
      {(pathname.startsWith(`${teamleadPath}/coworkers/${coworkerId}`) ||
        pageType === 'coworker') && (
        <>
          <Styled.SidebarButton
            $indentLevel={1}
            $isActive
            ssrIcon={person}
            onClick={() => navigate(`${teamleadPath}/coworkers/${coworkerId}`)}
          >
            {coworkerId}
          </Styled.SidebarButton>

          <Styled.SidebarButton
            $indentLevel={2}
            $isActive={pathname.startsWith(
              `${teamleadPath}/coworkers/${coworkerId}/explore`
            )}
            ssrIcon={pieChart}
            onClick={() =>
              navigate(`${teamleadPath}/coworkers/${coworkerId}/explore`)
            }
          >
            {t('pages.explore.title')}
          </Styled.SidebarButton>
          <Styled.SidebarButton
            $indentLevel={2}
            $isActive={pathname.startsWith(
              `${teamleadPath}/coworkers/${coworkerId}/missions`
            )}
            ssrIcon={star}
            onClick={() =>
              navigate(`${teamleadPath}/coworkers/${coworkerId}/missions`)
            }
          >
            {t('pages.missions.title')}
          </Styled.SidebarButton>

          <Styled.SidebarButton
            $indentLevel={2}
            $isActive={pathname.startsWith(
              `${teamleadPath}/coworkers/${coworkerId}/qa`
            )}
            ssrIcon={laptopPencil}
            onClick={() =>
              navigate(`${teamleadPath}/coworkers/${coworkerId}/qa`)
            }
          >
            {t('pages.qa.title')}
          </Styled.SidebarButton>
          <Styled.SidebarButton
            $indentLevel={2}
            $isActive={pathname.startsWith(`${teamleadPath}/cw/${coworkerId}`)}
            ssrIcon={visibilityShow}
            onClick={() => navigate(`${teamleadPath}/cw/${coworkerId}`)}
          >
            {t('common.see-app-as', { coworker: coworkerId })}
          </Styled.SidebarButton>
        </>
      )}
    </>
  )
}

export default TeamleadSidebarContent
