import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'
import { useParams } from 'react-router-dom'

import { MetricId } from 'config/domain/metrics.config'

import {
  MetricOwnerType,
  MetricValueType,
  PeriodType,
  TimeRange,
} from 'lib/types/metric.types'

import {
  QueryDynamicMetricParams,
  QueryMetricParams,
  useCoworker,
  useMetric,
  useMetrics,
} from 'state/slices/api'

type UsePreconfiguredMetricArgs = {
  metricId: MetricId
  periodType: PeriodType | 'dynamic'
  ownerType: MetricOwnerType
  timeRange: TimeRange
}

/**
 * Hook used to fetch indicators for a coworker or team based on the
 * currently signed in co-worker and the configuration provided to the hook.
 */
export const usePreconfiguredMetric = (
  args: UsePreconfiguredMetricArgs | SkipToken
) => {
  const { coworkerId } = useParams()
  const useCoworkerHook = useCoworker(coworkerId)
  const { data: coworker, ...meta } = useCoworkerHook

  let metricArgs: SkipToken | QueryDynamicMetricParams = skipToken
  let metricsArgs: SkipToken | QueryMetricParams = skipToken

  if (args === skipToken) {
    metricArgs = skipToken
    metricsArgs = skipToken
  } else if (coworker) {
    const { metricId, ownerType, periodType, timeRange } = args
    const ownerId =
      args.ownerType === MetricOwnerType.Coworker
        ? coworker?.legacyId
        : coworker?.teamId

    if (periodType === 'dynamic') {
      metricArgs = {
        ...args,
        metricId: metricId,
        owner: {
          id: ownerId,
          type: ownerType,
        },
        timeRange,
      }
    } else {
      metricsArgs = {
        metricId: metricId,
        owner: {
          id: ownerId,
          type: ownerType,
        },
        valueType: MetricValueType.Raw,
        timeRange,
        periodType,
      }
    }
  }

  const useMetricHook = useMetric(metricArgs)
  const useMetricsHook = useMetrics(metricsArgs)

  if (metricArgs !== skipToken) {
    return useMetricHook
  } else if (metricsArgs !== skipToken) {
    return useMetricsHook
  }

  return {
    data: undefined,
    ...meta,
  }
}
