import { useParams } from 'react-router-dom'

import { CoachingSession } from 'lib/types/coachingSession.types'

import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

export const useCoachingSessionHref = (coachingSession: CoachingSession) => {
  const { coworkerPath, teamleadPath } = useSectionPaths()
  const { coworkerId } = useParams()
  const pageType = useCurrentPageType()

  return pageType === 'coworker'
    ? `${coworkerPath}/coaching-sessions/${coachingSession.id}`
    : pageType === 'teamlead'
    ? `${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSession.id}`
    : '/'
}
