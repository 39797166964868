import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import documentPencil from '@ingka/ssr-icon/paths/document-pencil'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import * as Styled from './ReviewMissionMessage.styling'
import { ReviewMissionProps } from './ReviewMissionMessage.types'

const MissionsReadyForReviewMessage: React.FC<ReviewMissionProps> = ({
  coworker,
  missions,
}) => {
  const navigate = useNavigate()
  const { teamleadPath } = useSectionPaths()
  const { pathname } = useLocation()
  const { coworkerId } = useParams()

  const { t } = useTranslation()

  return (
    <Styled.InlineMessage
      variant="informative"
      title={t('pages.tl-missions.review-mission.teaser', {
        coworker: coworker?.firstName,
        count: missions.length,
        postProcess: 'interval',
      })}
      ssrIcon={documentPencil}
      actions={missions.map((mission) => ({
        text: `${t(
          'pages.manage-coworker.widgets.review-mission.start-review'
        )} - ${mission.title}`,
        ssrIcon: arrowRight,
        iconPosition: 'trailing',
        onClick: () => {
          navigate(
            `${teamleadPath}/coworkers/${coworkerId}/missions/${mission.id}/review`,
            {
              state: {
                from: pathname,
              },
            }
          )
        },
      }))}
    />
  )
}

export default MissionsReadyForReviewMessage
