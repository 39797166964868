import { toNumber } from 'lodash'
import { useTranslation } from 'react-i18next'

import { isQaMetricId } from 'config/domain/qa.config'

import { DateHelper } from 'lib/utils/date.helper'

export const useValidators = () => {
  const { t } = useTranslation()

  const validateMetric = (metric: string) => {
    if (isQaMetricId(metric)) {
      return true
    }

    return t(
      'features.mission.assign-mission.fields.metric.errors.invalid-question',
      {
        metric,
      }
    )
  }

  const validateTarget = (target: string) => {
    const parsedTarget = toNumber(target)
    if (Number.isNaN(parsedTarget)) {
      return t('features.mission.assign-mission.fields.target.errors.nan')
    }

    if (parsedTarget < 0) {
      return t('features.mission.assign-mission.fields.target.errors.qa-min')
    }

    if (parsedTarget > 100) {
      return t('features.mission.assign-mission.fields.target.errors.qa-max')
    }

    return true
  }
  const validateStartDate = (startDate: Date) => {
    const today = new Date()

    if (startDate > today) {
      return t(
        'features.mission.assign-mission.fields.start-date.errors.future-date'
      )
    }

    return true
  }

  const validateEndDate = (startDate: Date, endDate: Date) => {
    // Don't validate the end date if the start date has not been set yet
    if (startDate == null) {
      return true
    }

    const today = new Date()
    today.setHours(0, 0)
    const daysDifference = DateHelper.getDaysBetweenDates(startDate, endDate)

    if (endDate < today) {
      return t(
        'features.mission.assign-mission.fields.end-date.errors.past-date'
      )
    }

    if (daysDifference < 1) {
      return t(
        'features.mission.assign-mission.fields.end-date.errors.minimum-duration'
      )
    }

    return true
  }
  return {
    validateMetric,
    validateTarget,
    validateStartDate,
    validateEndDate,
  }
}
