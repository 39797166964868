import { ISODateString } from 'lib/types/common.types'

export enum UserRole {
  Admin = 'admin',
  TeamLead = 'teamlead',
  Coworker = 'coworker',
  Guest = 'guest',
}

export type User = {
  id: string
  createdDate: ISODateString
  legacyId: string
  name: string
  email: string
  role: UserRole
  prefs: UserPrefs
  notifications?: Notification[]
}

export type Notification = {
  id: string
  createdDate: ISODateString
  title: string
  message?: string
  link?: string
  read: boolean
}

export type UserPrefs = {
  locale?: string
  latestAppVersion?: string
  showCustomerComments?: boolean
  introduction: IntroductionPrefs
}

type IntroductionPrefs = {
  isProfileReady: boolean
  showWelcomeModal: boolean
}
