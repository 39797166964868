import Button from '@ingka/button'
import menu from '@ingka/ssr-icon/paths/menu'
import person from '@ingka/ssr-icon/paths/person'
import { space25 } from '@ingka/variables'
import { Flex } from '@mantine/core'

import { useUser } from 'hooks/users'

import { useShowSideBar, useShowSideMenu } from 'state/slices/ui/ui.hooks'
import { setShowSideBar, setShowSideMenu } from 'state/slices/ui/ui.slice'
import { useAppDispatch } from 'state/utilities'

import { NotificationsButton } from 'components/features/General/NotificationsButton'
import { BackButton } from 'components/layout/Header/BackButton'
import {
  ShowOnlyOnLargerDevice,
  ShowOnlyOnMobile,
} from 'components/layout/Helpers'
import { SideMenu } from 'components/layout/SideMenu'
import { Heading } from 'components/primitives/Text'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import * as Styled from './Header.styling'

type HeaderProps = {
  title: string
  backHref?: string
  helpContent?: string[]
}

const Header: React.FC<HeaderProps> = ({ title, backHref, helpContent }) => {
  const showSideBar = useShowSideBar()
  const showSideMenu = useShowSideMenu()

  const dispatch = useAppDispatch()
  const { data: user } = useUser()
  const { notifications = [] } = user || {}

  const toggleShowSidebar = () => dispatch(setShowSideBar(!showSideBar))

  return (
    <>
      <Styled.Header data-testid="header">
        {user && (
          <Styled.HeaderContent>
            <div>
              <ShowOnlyOnMobile>
                <Styled.MenuButton
                  iconOnly
                  small
                  type="tertiary"
                  ssrIcon={menu}
                  onClick={toggleShowSidebar}
                />
              </ShowOnlyOnMobile>
              <ShowOnlyOnLargerDevice>
                <Styled.UserName>
                  {backHref && <BackButton to={backHref} />}
                  <Heading>{title}</Heading>
                </Styled.UserName>
              </ShowOnlyOnLargerDevice>
            </div>
            <Flex align="center" gap={space25}>
              <FeatureToggle featureKey="notifications">
                <NotificationsButton notifications={notifications} />
              </FeatureToggle>
              <Button
                data-testid="btn-show-sidemenu"
                type="tertiary"
                small
                iconOnly
                ssrIcon={person}
                onClick={() => dispatch(setShowSideMenu(true))}
              />
            </Flex>
            <Styled.FlexBreak />
            <ShowOnlyOnMobile>
              <Styled.UserName>
                {backHref && <BackButton to={backHref} />}
                <Heading>{title}</Heading>
              </Styled.UserName>
            </ShowOnlyOnMobile>
          </Styled.HeaderContent>
        )}
      </Styled.Header>
      {user && (
        <SideMenu
          show={showSideMenu}
          onClose={() => {
            dispatch(setShowSideMenu(false))
          }}
        />
      )}
    </>
  )
}

export default Header
