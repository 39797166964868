import { useAwardableBadges } from 'hooks/badges/useAwardableBadges'
import { useCategorizedBadges } from 'hooks/badges/useCategorizedBadges'

import { useBadgesQuery, useCoworker } from 'state/slices/api'

export const useOrganisedBadges = (coworkerId: string) => {
  const { data: coworker } = useCoworker(coworkerId)

  const { data: badges, ...meta } = useBadgesQuery()

  const categories = useCategorizedBadges(coworker?.badges, badges)
  const { unlockedAwardableBadges } = useAwardableBadges(
    coworker?.badges,
    badges
  )

  return {
    data: {
      categories,
      unlockedAwardableBadges,
    },
    ...meta,
  }
}
