import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { BadgeList } from 'components/features/Badges/BadgeList'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

import { useBackHref } from './BadgesPage.hooks'

const BadgesPage: React.FC = () => {
  const { t } = useTranslation()
  const { coworkerId } = useParams()

  const backHref = useBackHref()

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  return (
    <div data-testid="page-badges">
      <Header title={t('pages.badges.title')} backHref={backHref} />
      <BadgeList coworkerId={coworkerId} />
    </div>
  )
}

export default BadgesPage
