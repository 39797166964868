import Text from '@ingka/text'
import { Stack } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useState } from 'react'

import { debounceTimeDefault } from 'styles/tokens/insikt.tokens'

import { Widget } from 'components/composites/Shared/Widget'
import { UsersOverview } from 'components/features/Users/UsersOverview'
import { Header } from 'components/layout/Header'

import * as Styled from './UsersPage.styling'

const UsersPage = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm] = useDebouncedValue(
    searchTerm,
    debounceTimeDefault
  )

  // Redeploy test

  return (
    <>
      <Header backHref="/admin" title="Users" />

      <Stack>
        <Widget>
          <Text tagName="p">
            {'You can search for any user here by their full name or IKEA ID.'}
          </Text>
          <Text tagName="p">
            {'You can also search for just the beginning of a name or ID.'}
          </Text>

          <Styled.SearchField
            id="userSearchTerm"
            type="text"
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
          <UsersOverview query={debouncedSearchTerm} />
        </Widget>
      </Stack>
    </>
  )
}

export default UsersPage
