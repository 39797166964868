import { CoworkerRole } from 'config/domain/coworkerRoles.config'

import { ActionPoint } from './actionPoint.types'
import { CoachingSession } from './coachingSession.types'
import { ISODateString } from './common.types'
import { Mission } from './mission.types'

export type AchievedBadge = {
  badgeDefinitionId: string
  unlockDate: ISODateString
  expiryDate: ISODateString
  isNew: boolean
}

export type AwardedBadge = AchievedBadge & {
  awarderId: string
  message: string
}
export enum CoworkerCoachingStatus {
  MissingAssessments = 'missing-assessments',
  PrepareSession = 'prepare-session',
  SessionDraft = 'session-draft',
  SessionReady = 'session-ready',
  AwaitingSign = 'awaiting-sign',
  NoActions = 'no-actions',
}

export type Coworker = {
  legacyId: string
  fullName: string
  firstName: string
  lastName: string
  email: string
  country: string
  teamId: string
  role: CoworkerRole
  badges: AchievedBadge[]
  coachingSessions?: CoachingSession[] | undefined
  missions?: Mission[]
  actionPoints?: ActionPoint[]
  status: CoworkerCoachingStatus
}

export type InactiveCoworkerProfile = {
  legacyId: string
  firstName: string
  lastName: string
  email: string
  team: string
}

export const isInactiveCoworkerProfile = (
  coworker: Coworker | InactiveCoworkerProfile
): coworker is InactiveCoworkerProfile => {
  return (coworker as Coworker).role === undefined
}

export const isCoworker = (
  coworker: Coworker | InactiveCoworkerProfile
): coworker is Coworker => {
  return (coworker as Coworker).role !== undefined
}
