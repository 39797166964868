import Select, { OptGroup, Option } from '@ingka/select'
import { Stack } from '@mantine/core'
import { endOfYesterday } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { qarGroups } from 'config/domain/qarForm.config'
import { qasGroups } from 'config/domain/qasForm.config'
import { qsaGroups } from 'config/domain/qsaForm.config'

import { DateHelper } from 'lib/utils/date.helper'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useCoworker } from 'state/slices/api'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import InteractionList from 'components/features/Interactions/InteractionList/InteractionList.component'
import { MetricLineChart } from 'components/features/Metrics/MetricLineChart'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import {
  useSelectedDateRange,
  useSelectedMetric,
} from 'components/pages/shared/ExplorePage/ExplorePage.hooks'
import { DatePicker } from 'components/primitives/DatePicker'
import { Subtitle } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useBackHref } from './QaPage.hooks'
import * as Styled from './QaPage.styling'

const QaPage: React.FC = () => {
  const { coworkerId } = useParams()
  const { t } = useTranslation()

  const backHref = useBackHref()

  const {
    data: coworker,
    isLoading: isLoadingCoworker,
    isError: isErrorCoworkers,
    isSuccess: isSuccessCoworker,
  } = useCoworker(coworkerId)

  const isError = isErrorCoworkers
  const isSuccess = isSuccessCoworker

  const {
    metricName,
    metricType,
    handleMetricNameChanged,
    handleMetricTypeChanged,
  } = useSelectedMetric('QAR', 'QARTT')

  const {
    selectedStartDate,
    startDate,
    startDateError,
    selectedEndDate,
    endDateError,
    endDate,
    handleStartDateChanged,
    handleEndDateChanged,
  } = useSelectedDateRange(90)

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  return (
    <div data-testid="page-qa">
      <Header backHref={backHref} title={`${t('pages.qa.title')}`} />
      {isLoadingCoworker && (
        <LoadingIndicator
          message={t('loading-messages.fetching-coworker-profile')}
        />
      )}

      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <Stack>
          <Subtitle>{t('pages.qa.averages')}</Subtitle>
          <Styled.Controls>
            <Styled.MetricField>
              <Select
                label={t('pages.qa.fields.assessment-type')}
                id="metricType"
                value={metricType}
                hintText=""
                onChange={(e) => handleMetricTypeChanged(e.target.value)}
              >
                <Option name={t('qa.assessment-type.QAR')} value="QAR" />
                <Option name={t('qa.assessment-type.QAS')} value="QAS" />
                <Option name={t('qa.assessment-type.QSA')} value="QSA" />
              </Select>
            </Styled.MetricField>
            <Styled.MetricField>
              <Select
                label={t('pages.qa.fields.question')}
                id="metricName"
                onChange={(e) => handleMetricNameChanged(e.target.value)}
                value={metricName}
                hintText=""
              >
                <>
                  {metricType === 'QAR' && (
                    <>
                      <Option name={t('qa.QARTT')} value="QARTT" />
                      {Object.values(qarGroups).map((group) => (
                        <OptGroup
                          groupName={t(`qa.${group.id}`)}
                          key={group.id}
                        >
                          <Option name={t(`qa.${group.id}`)} value={group.id} />
                          {Object.values(group.questions).map((question) => (
                            <Option
                              key={question.id}
                              name={t(`qa.${question.id}`)}
                              value={question.id}
                            />
                          ))}
                        </OptGroup>
                      ))}
                    </>
                  )}
                  {metricType === 'QAS' && (
                    <>
                      <Option name={t('qa.QASTT')} value="QASTT" />
                      {Object.values(qasGroups).map((group) => (
                        <OptGroup
                          groupName={t(`qa.${group.id}`)}
                          key={group.id}
                        >
                          <Option name={t(`qa.${group.id}`)} value={group.id} />
                          {Object.values(group.questions).map((question) => (
                            <Option
                              key={question.id}
                              name={t(`qa.${question.id}`)}
                              value={question.id}
                            />
                          ))}
                        </OptGroup>
                      ))}
                    </>
                  )}
                  {metricType === 'QSA' && (
                    <>
                      <Option name={t('qa.QSATT')} value="QSATT" />
                      {Object.values(qsaGroups).map((group) => (
                        <OptGroup
                          groupName={t(`qa.${group.id}`)}
                          key={group.id}
                        >
                          <Option name={t(`qa.${group.id}`)} value={group.id} />
                          {Object.values(group.questions).map((question) => (
                            <Option
                              key={question.id}
                              name={t(`qa.${question.id}`)}
                              value={question.id}
                            />
                          ))}
                        </OptGroup>
                      ))}
                    </>
                  )}
                </>
              </Select>
            </Styled.MetricField>

            <Styled.DateField
              shouldValidate={true}
              valid={startDateError == null}
              validation={{
                msg: startDateError,
              }}
            >
              <DatePicker
                id="startDate"
                label={t('pages.explore.fields.start-date.title')}
                onChange={handleStartDateChanged}
                value={selectedStartDate}
                minDate={DateHelper.dataCutoffDate}
                maxDate={endOfYesterday()}
              />
            </Styled.DateField>

            <Styled.DateField
              shouldValidate={true}
              valid={endDateError == null}
              validation={{
                msg: endDateError,
              }}
            >
              <DatePicker
                id="endDate"
                label={t('pages.explore.fields.end-date.title')}
                onChange={handleEndDateChanged}
                value={selectedEndDate}
                minDate={DateHelper.dataCutoffDate}
                maxDate={endOfYesterday()}
              />
            </Styled.DateField>
          </Styled.Controls>
          <MetricLineChart
            metricId={metricName}
            coworkerId={coworkerId}
            teamId={coworker?.teamId}
            startDate={startDate}
            endDate={endDate}
            controls={{ smoothing: true }}
          />
          <Subtitle>{t('pages.qa.all-scorecards')}</Subtitle>
          <InteractionList coworkerId={coworkerId} />
        </Stack>
      )}
    </div>
  )
}

export default QaPage
