import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import plusCircled from '@ingka/ssr-icon/paths/plus-circle'
import { Box, Grid, Stack } from '@mantine/core'
import { toNumber } from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { OperationalMetricId } from 'config/domain/metrics.config'
import { QaMetricId } from 'config/domain/qa.config'

import { MissionStatus } from 'lib/types/mission.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useMissionsForCoachingSession } from 'hooks/coachingSessions/useMissionsForCoachingSession.hooks'
import { useUser } from 'hooks/users'

import { useCreateMissionDraft } from 'state/slices/api/modules/coachingSession/coachingSession.slice'

import { MissionStatusIndicator } from 'components/composites/Missions/MissionStatusIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { MissionDraftModal } from './MissionDraftModal'
import { MissionDraftSkeleton } from './MissionDraftSkeleton'
import {
  CoachingSessionMissionDraftProps,
  MissionDraftFormFields,
} from './MissionsEditor.types'

const SKELETON_COUNT = 2

const MissionsEditor: React.FC<CoachingSessionMissionDraftProps> = ({
  coworkerId,
  coachingSessionId,
}) => {
  const { t } = useTranslation()
  const [showCreateMissionDraft, setShowCreateMissionDraft] = useState(false)
  const { data: user } = useUser()

  const form = useForm<MissionDraftFormFields>({
    defaultValues: {
      target: '',
      metricId: undefined,
      endDate: (() => {
        const date = new Date()
        date.setDate(date.getDate() + 31)
        return date
      })(),
      connectedMetricId: undefined,
    },
  })
  const { getValues } = form
  const [createMissionDraft, { isLoading: isLoadingCreateMissionDraft }] =
    useCreateMissionDraft()

  const onSubmit = () => {
    if (!user || !coworkerId) return

    const { metricId, connectedMetricId, target, endDate } = getValues()
    const mission = {
      metricId: metricId as QaMetricId,
      connectedMetricId: connectedMetricId as OperationalMetricId,
      target: toNumber(target),
      endDate: DateHelper.dateToString(endDate),
      createdDate: DateHelper.dateToString(new Date()),
      createdBy: user.legacyId,
    }
    
    createMissionDraft({
      coworkerId,
      coachingSessionId,
      mission,
    }).then(() => {
      setShowCreateMissionDraft(false)
      form.reset()
    })
  }
  const {
    data: missions,
    isLoading: isLoadingMissionsDraft,
    isError: isErrorMissionsDraft,
    isSuccess: isSuccessMissionsDraft,
  } = useMissionsForCoachingSession({ coworkerId, coachingSessionId })

  return (
    <>
      {isLoadingMissionsDraft && (
        <Grid>
          {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
            <Grid.Col md={6} key={index}>
              <MissionDraftSkeleton />
            </Grid.Col>
          ))}
        </Grid>
      )}
      {isErrorMissionsDraft && <UnexpectedErrorMessage />}

      {isSuccessMissionsDraft && (
        <Stack>
          {missions?.length !== 0 && missions && (
            <Grid>
              {missions?.map((mission) => (
                <Grid.Col md={6} key={mission.id}>
                  <Widget
                    header={
                      <WidgetHeader
                        title={t(
                          'features.coaching-session.set-new-plan.create-mission-draft.title',
                          { title: mission.metricId }
                        )}
                        subtitle={t(
                          'features.coaching-session.set-new-plan.create-mission-draft.subtitle'
                        )}
                        rightSideContent={
                          <MissionStatusIndicator
                            status={MissionStatus.Draft}
                          />
                        }
                      />
                    }
                    footer={
                      <WidgetFooter>
                        <Button
                          small
                          type="tertiary"
                          text={t(
                            'features.coaching-session.set-new-plan.create-mission-draft.actions.edit'
                          )}
                          ssrIcon={arrowRight}
                          iconPosition="trailing"
                        />
                      </WidgetFooter>
                    }
                  />
                </Grid.Col>
              ))}
            </Grid>
          )}
          <Box>
            <Button
              small
              type="primary"
              text={t(
                'features.coaching-session.set-new-plan.create-mission-draft.actions.create'
              )}
              iconPosition="trailing"
              ssrIcon={plusCircled}
              onClick={() => setShowCreateMissionDraft(true)}
            />
          </Box>
        </Stack>
      )}
      <MissionDraftModal
        form={form}
        handleFormSubmit={onSubmit}
        visible={showCreateMissionDraft}
        handleCloseBtn={() => setShowCreateMissionDraft(false)}
        isLoadingCreateMissionDraft={isLoadingCreateMissionDraft}
      />
    </>
  )
}

export default MissionsEditor
