import { Prompt as SkapaPrompt } from '@ingka/modal'
import { space75,space100, space200 } from '@ingka/variables'

import styled from 'styled-components'

// The prompt does not allow us to override its styles by setting the individual style attributes
// Instead, we have to override the class that sets the max width
export const Prompt = styled(SkapaPrompt)`
  .prompt__content-wrapper {
    padding-top: ${space200};
    max-width: 50rem;
  }
`

export const ModalBodyImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${space100};
  display: block;
  max-height: 160px;
`

export const Paragraph = styled.p`
  margin-bottom: ${space75};
  margin-top: ${space75};
`
