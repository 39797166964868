import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { auth } from 'config/firebase/firebase.config'
import {
  defaultLocale,
  locales,
} from 'config/internationalisation/locales.config'

import { useFetchBadgesQuery } from 'state/slices/api/modules/badge'
import {
  useFetchCoworkersByTeamleadIdQuery,
  useFindCoworkerByIdQuery,
} from 'state/slices/api/modules/coworker'
import { useFetchTeamQuery } from 'state/slices/api/modules/team'
import {
  useFetchPrefsQuery,
  useFetchUserQuery,
} from 'state/slices/api/modules/user'

// To avoid a circular dependency, we declare useUser locally here instead of using the
// hook defined in src/hooks/users.ts
const useUser = () => useFetchUserQuery(auth.currentUser?.uid ?? skipToken)

export const useLocale = () => {
  const { data: prefs } = useUserPrefsQuery()
  const localeIsoCode = prefs?.locale ?? defaultLocale.isoCode

  return locales[localeIsoCode]
}

export const useUserPrefsQuery = () => {
  const { data: user } = useFetchUserQuery(auth.currentUser?.uid ?? skipToken)
  const userId = user?.id ?? skipToken
  return useFetchPrefsQuery(userId)
}

const useTeamleadCoworkers = (teamleadId: string | undefined | SkipToken) => {
  return useFetchCoworkersByTeamleadIdQuery(teamleadId ?? skipToken)
}

/** Returns active and inactive co-workers that a user with the given legacyId has access to. */
export const useFetchAllCoworkers = (
  legacyId: string | undefined | SkipToken
) => {
  const { data: user, ...meta } = useUser()

  const { isSuccess: isUserSuccess } = meta

  const teamleadQuery = useFetchCoworkersByTeamleadIdQuery(
    legacyId ?? skipToken
  )

  if (isUserSuccess) {
    return teamleadQuery
  }

  return { data: undefined, ...meta }
}

const useCoworkerRegular = (coworkerId: string | undefined | SkipToken) =>
  useFindCoworkerByIdQuery(coworkerId ?? skipToken)
export const useCoworkerAsTeamlead = (
  coworkerId: string | undefined | SkipToken
) => {
  const { data: user } = useUser()
  const teamleadId = user?.legacyId
  const skip = coworkerId === skipToken

  const { data: coworkers, ...rest } = useFetchCoworkersByTeamleadIdQuery(
    teamleadId ?? skipToken,
    {
      skip,
    }
  )

  return {
    data: coworkers?.activeCoworkers.find(
      (coworker) => coworker.legacyId === coworkerId
    ),
    ...rest,
  }
}

const useAdminCoworkers = (teamleadId: string | undefined | SkipToken) => {
  return useFetchCoworkersByTeamleadIdQuery(teamleadId ?? skipToken)
}
export const useCoworker = (coworkerId: string | undefined | SkipToken) => {
  const { data: user, isSuccess } = useUser()
  const { role } = user ?? {}

  const isTl = role === 'teamlead'

  const regularQuery = useCoworkerRegular(
    (isSuccess && !isTl && coworkerId) || skipToken
  )

  const teamleadQuery = useCoworkerAsTeamlead((isTl && coworkerId) || skipToken)

  if (isTl) {
    return teamleadQuery
  }

  return regularQuery
}

export const useCoworkers = (teamleadId: string | undefined) => {
  const { data: user, isSuccess } = useUser()
  const { role } = user ?? {}

  const isTl = role === 'teamlead'

  const teamleadQuery = useTeamleadCoworkers(
    (isSuccess && isTl && teamleadId) || skipToken
  )
  const regularQuery = useAdminCoworkers((isSuccess && teamleadId) || skipToken)

  if (isTl) {
    return teamleadQuery
  }

  return regularQuery
}

export const useBadgesQuery = () => useFetchBadgesQuery()
export const useTeamQuery = (teamId?: string | SkipToken) => {
  return useFetchTeamQuery(teamId ?? skipToken)
}
