import 'react-datepicker/dist/react-datepicker.css'
import './index.scss'
import calendar from '@ingka/ssr-icon/paths/calendar'
import { useFocusWithin } from '@mantine/hooks'
import cx from 'classnames'
import * as locales from 'date-fns/locale'
import { useCallback } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

import { useLocale } from 'state/slices/api/api.hooks'

import * as Styled from './DatePicker.styling'

type DatePickerProps = {
  className?: string
  clearable?: boolean
  describedById?: string
  disabled?: boolean
  error?: string
  id: string
  label: string
  maxDate?: Date
  minDate?: Date
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange: (
    date: Date,
    event: React.SyntheticEvent<unknown, Event> | undefined
  ) => void
  required?: boolean
  shouldValidate?: boolean
  todayButton?: boolean
  valid?: boolean
  value?: Date
  [x: string]: unknown
}
/**
 * Date selection component adapted from rc-kategorisera.
 */
const DatePicker: React.FC<DatePickerProps> = ({
  className,
  describedById,
  disabled,
  error,
  id,
  clearable = true,
  label: lbl,
  minDate,
  maxDate,
  onBlur,
  onChange,
  required,
  shouldValidate,
  todayButton = false,
  value,
  ...props
}) => {
  const { t } = useTranslation()
  const { dateFnsCode } = useLocale()
  const dateFnsLocale = locales[dateFnsCode]

  const valid = !!shouldValidate && !error
  const isClearable = !!clearable && !disabled && !!value

  const { ref: wrapperRef, focused } = useFocusWithin<HTMLDivElement>()

  // Focus the input when the icon is clicked
  const handleIconClicked = useCallback(() => {
    const element = document.getElementById(id)
    if (element) {
      element.focus()
    }
  }, [id])

  return (
    <div
      className={cx(
        'input-field',
        'label-wrapper',
        {
          focus: focused,
          'input-field--labelled': !!value || focused,
          'input-field--error': !!shouldValidate && !valid,
          'input-field--success': !!shouldValidate && !!valid,
        },
        'datePicker'
      )}
      ref={wrapperRef}
    >
      <label htmlFor={id} className={'label'}>
        {lbl}
      </label>
      <div
        className={cx('input-field__wrapper', {
          disabled: disabled,
        })}
      >
        <ReactDatePicker
          {...props}
          aria-describedby={describedById}
          aria-required={required}
          aria-invalid={shouldValidate ? !valid : null}
          className={cx(className, { clearable: isClearable })}
          dateFormat={
            dateFnsLocale.formatLong?.date({ width: 'short' }) ?? 'yyyy-MM-dd'
          }
          disabled={disabled}
          id={id}
          isClearable={isClearable}
          key={id}
          locale={dateFnsLocale}
          maxDate={maxDate}
          minDate={minDate}
          peekNextMonth={false}
          placeholderText=""
          required={required}
          shouldCloseOnSelect
          showPopperArrow={false}
          todayButton={todayButton && t('common.today')}
          autoComplete="off"
          onChange={onChange}
          onBlur={onBlur}
          selected={value}
        />
        <Styled.InputIcon
          paths={calendar}
          onClick={handleIconClicked}
          style={{ cursor: 'pointer' }}
        />
        <span className={cx({ 'input-field__border': !disabled })} />
      </div>
    </div>
  )
}

export default DatePicker
