import _ from 'lodash'

import { BadgeDefinition, BadgeType } from 'lib/types/badge.types'
import { AchievedBadge } from 'lib/types/coworker.types'

import { getUnlockedBadgeDefinitions } from './useBadgeGrouping'

/**
 * Iterates over an `UnlockedBadge[]` and `BadgeDefinition[]` to group them by category
 */
export const useCategorizedBadges = (
  unlockedBadges: AchievedBadge[] | undefined = [],
  badges: BadgeDefinition[] | undefined = []
) => {
  const achievableBadges = badges.filter(
    (badge) => badge.type === BadgeType.Achievable
  )
  const categorizedBadges = _.groupBy(
    achievableBadges,
    (badge) => badge.category
  )
  const allUnlockedBadges = getUnlockedBadgeDefinitions(
    unlockedBadges,
    achievableBadges
  )

  const categories = Object.entries(categorizedBadges).map(
    ([category, badges]) => {
      const allLockedBadges = badges.filter(
        (badge) =>
          !unlockedBadges.some(
            (unlockedBadge) =>
              unlockedBadge.badgeDefinitionId === badge.badgeDefinitionId
          )
      )

      const ungroupedLockedBadges = allLockedBadges.filter(
        (badge) => badge.group == null
      )

      const groupedLockedBadges = Object.values(
        _.groupBy(
          allLockedBadges.filter((badge) => badge.group != null),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (badge) => badge.group!.groupId
        )
      )

      const sortedLockedBadges = [
        ...ungroupedLockedBadges.map((badge) => ({
          current: badge,
          after: [],
        })),
        ...groupedLockedBadges.map((group) => {
          const [current, ...after] = group
          return {
            current,
            after,
          }
        }),
      ]

      const ungroupedUnlockedBadges = allUnlockedBadges.filter(
        (badge) => badge.group == null && badge.category === category
      )

      const groupedUnlockedBadges = Object.values(
        _.groupBy(
          allUnlockedBadges.filter(
            (badge) => badge.group != null && badge.category === category
          ),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (badge) => badge.group!.groupId
        )
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ).map((group) => _.orderBy(group, (a) => a.group!.order, 'desc'))

      const sortedUnlockedBadges = [
        ...ungroupedUnlockedBadges.map((badge) => ({
          current: badge,
          previous: [],
        })),
        ...groupedUnlockedBadges.map((group) => {
          const [current, ...previous] = group
          return {
            current,
            previous,
          }
        }),
      ]

      sortedLockedBadges.sort((badgeA, badgeB) =>
        badgeA.current.title.localeCompare(badgeB.current.title)
      )

      sortedUnlockedBadges.sort(
        (badgeA, badgeB) =>
          new Date(badgeB.current.unlockDate).getTime() -
          new Date(badgeA.current.unlockDate).getTime()
      )

      return {
        name: category,
        locked: sortedLockedBadges,
        unlocked: sortedUnlockedBadges,
      }
    }
  )

  return categories
}
