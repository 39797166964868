import checkmark from '@ingka/ssr-icon/paths/checkmark-circle'
import star from '@ingka/ssr-icon/paths/star'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { HeaderIcon } from 'components/composites/Shared/Widget/Widget.styling'
import { ActionPlanEditor } from 'components/features/CoachingSessions/CoachingSessionEditor/ActionPlanEditor'
import { CoachingSessionEditorStepProps } from 'components/features/CoachingSessions/CoachingSessionEditor/CoachingSessionEditor.types'
import { MissionsEditor } from 'components/features/CoachingSessions/CoachingSessionMissionDraft'
import { Heading } from 'components/primitives/Text'

const NewPlan: React.FC<CoachingSessionEditorStepProps> = ({
  coworker,
  coachingSession,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex align="center">
        <HeaderIcon paths={star} />
        <Heading>{t('features.coaching-session.review-plan.missions')}</Heading>
      </Flex>
      <MissionsEditor
        coworkerId={coworker.legacyId}
        coachingSessionId={coachingSession.id}
      />
      <Flex align="center">
        <HeaderIcon paths={checkmark} />
        <Heading>{t('features.coaching-session.action-plan.title')}</Heading>
      </Flex>
      <ActionPlanEditor
        coworkerId={coworker.legacyId}
        coachingSessionId={coachingSession.id}
      />
    </>
  )
}

export default NewPlan
