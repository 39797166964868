import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { Interaction } from 'lib/types/interaction.types'

import { useFormatDate } from 'hooks/formatting'
import { useHumanizeDuration } from 'hooks/formatting'

import { Heading, Paragraph, Subtitle } from 'components/primitives/Text'

import * as Styled from './InteractionCard.styling'
import { QualityAssessment } from './QualityAssessment'

type InteractionCardProps = {
  interaction: Interaction
  enableComments?: boolean
}

const InteractionCard: React.FC<InteractionCardProps> = ({
  interaction,
  enableComments,
}) => {
  const humanizeDuration = useHumanizeDuration()
  const formatDate = useFormatDate()
  const { t } = useTranslation()
  const { handledTimestamp } = interaction

  return (
    <Styled.InteractionCard>
      {/* Interaction Section */}
      <Styled.CardFlex>
        <Box>
          <Subtitle>
            {t('features.interaction.interaction-card.summary')}
          </Subtitle>
          <Styled.InteractionGrid>
            <Paragraph $bold>
              {t('features.interaction.interaction-card.id')}
            </Paragraph>
            <div>{interaction.id}</div>

            <Paragraph $bold>
              {t('features.interaction.interaction-card.resource-id')}
            </Paragraph>
            <div>{interaction.resourceId}</div>

            <Paragraph $bold>
              {t('features.interaction.interaction-card.handled-date')}
            </Paragraph>
            <div>{formatDate(new Date(handledTimestamp))}</div>

            <Paragraph $bold>
              {t('features.interaction.interaction-card.media')}
            </Paragraph>
            <div>
              {t(`common.media-types.${interaction.media.toLowerCase()}`)}
            </div>

            <Paragraph $bold>
              {t('features.interaction.interaction-card.ivr-queue-time')}
            </Paragraph>
            <div>
              {/** humanizeDuration uses milliseconds, totalQueueTime is in seconds */}
              {humanizeDuration(
                (interaction.totalQueueTime + interaction.ivrTime) * 1000
              )}
            </div>

            <Paragraph $bold>
              {t('features.interaction.interaction-card.coworkers-involved')}
            </Paragraph>
            <div>{interaction.numberOfCoworkersInvolved}</div>

            <Paragraph $bold>
              {t('features.interaction.interaction-card.outcome')}
            </Paragraph>
            <div>
              {t(
                `common.interaction-outcomes.${interaction.outcome.toLowerCase()}`
              )}
            </div>

            <Paragraph $bold>{'CSAT'}</Paragraph>
            <div>
              {interaction.csat
                ? interaction.csat
                : t('features.interaction.interaction-card.no-review')}
            </div>
          </Styled.InteractionGrid>
          {interaction.comment && enableComments && (
            <>
              <Paragraph $bold>
                {t('features.interaction.interaction-card.customer-comment')}
              </Paragraph>
              <div>{interaction.comment}</div>
            </>
          )}
        </Box>
        <Styled.Divider />
        <Box>
          {/* Scorecard Section */}
          <Heading>
            {t('features.interaction.interaction-card.scorecard')}
          </Heading>
          {interaction.qualityAssessment?.evaluationDate && (
            <Styled.EvaluationDateText>
              {t('features.interaction.interaction-card.evaluation-date', {
                date: formatDate(
                  new Date(interaction.qualityAssessment.evaluationDate)
                ),
              })}
            </Styled.EvaluationDateText>
          )}
          <QualityAssessment interaction={interaction} />
        </Box>
      </Styled.CardFlex>
    </Styled.InteractionCard>
  )
}

export default InteractionCard
