import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'

import { useLayoutData } from './CoworkerLayout.hooks'

const CoworkerLayout: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, isSuccess } = useLayoutData()

  return (
    <>
      {isSuccess && <Outlet />}
      {isLoading && (
        <LoadingIndicator
          message={t('loading-messages.fetching-coworker-profile')}
        />
      )}
    </>
  )
}

export default CoworkerLayout
