import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { Interaction } from 'lib/types/interaction.types'
import {
  QualityAssessmentQuestion,
  QualityAssessmentQuestionGroup,
} from 'lib/types/qualityAssessment.types'

import { Paragraph } from 'components/primitives/Text'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import * as Styled from '../InteractionCard.styling'

type QualityAssessmentProps = {
  interaction: Interaction
}

const Question = ({
  question: { score, title },
}: {
  question: QualityAssessmentQuestion
}) => (
  <Styled.ScoreRow>
    <Paragraph>{title}</Paragraph>
    <Paragraph>{score?.toFixed(0)}</Paragraph>
  </Styled.ScoreRow>
)

const Group = ({
  group: { notes, questions, score, title },
}: {
  group: QualityAssessmentQuestionGroup
}) => {
  const sortedQuestions = _.orderBy(questions, (question) => question.order)
  return (
    <Styled.Group>
      <Styled.ScoreRow>
        <Paragraph $bold>{title}</Paragraph>
        <Paragraph $bold>{score?.toFixed(0)}</Paragraph>
        <FeatureToggle featureKey="qa_notes">
          <>{notes && <Styled.NotesRow>{notes}</Styled.NotesRow>}</>
        </FeatureToggle>
      </Styled.ScoreRow>
      {sortedQuestions?.map((question) => (
        <Question question={question} key={question.title} />
      ))}
    </Styled.Group>
  )
}

const QualityAssessment: React.FC<QualityAssessmentProps> = ({
  interaction,
}) => {
  const { t } = useTranslation()
  const { qualityAssessment } = interaction
  if (!qualityAssessment) return null

  const { id, groups, score, notes } = qualityAssessment

  const formType = id?.substring(0, 3)

  const type = formType
    ? t(`qa.assessment-type.${formType}`)
    : t('common.unknown')

  const sortedGroups = _.orderBy(groups, (group) => group.order)

  return (
    <div>
      <Styled.ScoreRow>
        <Paragraph $bold>
          {t('features.interaction.interaction-card.assessment-type')}
        </Paragraph>
        <Paragraph>{type}</Paragraph>
      </Styled.ScoreRow>
      <Styled.ScoreRow>
        <Paragraph $bold>
          {t('features.interaction.interaction-card.total')}
        </Paragraph>
        <Paragraph $bold>{score?.toFixed(0)}</Paragraph>
        <FeatureToggle featureKey="qa_notes">
          <>{notes && <Styled.NotesRow>{notes}</Styled.NotesRow>}</>
        </FeatureToggle>
      </Styled.ScoreRow>
      {sortedGroups.map((group) => (
        <Group group={group} key={group.title} />
      ))}
    </div>
  )
}

export default QualityAssessment
