import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { Box, Flex } from '@mantine/core'
import { Link, useLocation } from 'react-router-dom'

import { useFormatDate } from 'hooks/formatting'

import { Widget } from 'components/composites/Shared/Widget'
import { Caption, Paragraph } from 'components/primitives/Text'

import { useInteractionHref } from './InteractionPreview.hooks'
import { InteractionPreviewProps } from './InteractionPreview.types'

const InteractionPreview: React.FC<InteractionPreviewProps> = ({
  interaction,
}) => {
  const formatDate = useFormatDate()
  const interactionHref = useInteractionHref(interaction.id)
  const { pathname } = useLocation()

  return (
    <Widget>
      <Flex w="100%" justify="space-between">
        <Box>
          <Paragraph $bold>{interaction.id}</Paragraph>
          <Caption>
            {formatDate(new Date(interaction.handledTimestamp))}
          </Caption>
        </Box>
        <Link to={interactionHref} state={{ from: pathname }}>
          <Button type="tertiary" small iconOnly ssrIcon={arrowRight} />
        </Link>
      </Flex>
    </Widget>
  )
}

export default InteractionPreview
