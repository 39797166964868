import Select, { Option } from '@ingka/select'
import { endOfYesterday } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { MetricOwnerType } from 'lib/types/metric.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useCoworker, useTeamQuery } from 'state/slices/api'

import { Widget, WidgetHeader } from 'components/composites/Shared/Widget'
import { MetricLineChart } from 'components/features/Metrics/MetricLineChart'
import { Stat } from 'components/features/Metrics/Stat'
import { Header } from 'components/layout/Header'
import { useCoworkerLabel } from 'components/pages/shared/shared.hooks'
import { DatePicker } from 'components/primitives/DatePicker'
import { Divider } from 'components/primitives/Divider'

import {
  useAllowedMetrics,
  useBackHref,
  useMetricBehaviour,
  useSelectedDateRange,
  useSelectedMetric,
} from './ExplorePage.hooks'
import * as Styled from './ExplorePage.styling'

const ExplorePage: React.FC = () => {
  const { t } = useTranslation()

  const { coworkerId } = useParams()

  const { data: coworker } = useCoworker(coworkerId)
  const { data: team } = useTeamQuery(coworker?.teamId)

  const { metricName, handleMetricNameChanged } = useSelectedMetric()

  const {
    selectedStartDate,
    startDate,
    startDateError,
    selectedEndDate,
    endDateError,
    endDate,
    handleStartDateChanged,
    handleEndDateChanged,
  } = useSelectedDateRange()

  const coworkerLabel = useCoworkerLabel(coworker)
  const backHref = useBackHref()
  const allowedMetrics = useAllowedMetrics()
  const { noTeamData } = useMetricBehaviour(metricName) || {}

  return (
    <div data-testid="page-explore">
      <Header title={t('pages.explore.title')} backHref={backHref} />
      <Widget header={<WidgetHeader title={t('pages.explore.overview')} />}>
        <Styled.Controls>
          <Styled.MetricField>
            <Select
              label={t('pages.explore.fields.metric.title')}
              id="metricName"
              onChange={(e) => handleMetricNameChanged(e.target.value)}
              value={metricName}
              hintText=""
            >
              <>
                {allowedMetrics.map((metric) => (
                  <Option
                    key={metric.id}
                    label={t(`common.metrics.${metric.id}.name`)}
                    value={metric.id}
                  />
                ))}
              </>
            </Select>
          </Styled.MetricField>

          <Styled.DateField
            shouldValidate={true}
            valid={startDateError == null}
            validation={{
              msg: startDateError,
            }}
          >
            <DatePicker
              id="startDate"
              label={t('pages.explore.fields.start-date.title')}
              onChange={handleStartDateChanged}
              value={selectedStartDate}
              minDate={DateHelper.dataCutoffDate}
              maxDate={endOfYesterday()}
            />
          </Styled.DateField>

          <Styled.DateField
            shouldValidate={true}
            valid={endDateError == null}
            validation={{
              msg: endDateError,
            }}
          >
            <DatePicker
              id="endDate"
              label={t('pages.explore.fields.end-date.title')}
              onChange={handleEndDateChanged}
              value={selectedEndDate}
              minDate={DateHelper.dataCutoffDate}
              maxDate={endOfYesterday()}
            />
          </Styled.DateField>
        </Styled.Controls>

        <Styled.StatContainer>
          {coworker && (
            <>
              <Stat
                title={coworkerLabel}
                metricId={metricName}
                ownerType={MetricOwnerType.Coworker}
                id={coworker.legacyId}
                period={{
                  start: startDate,
                  end: endDate,
                }}
              />
              {!noTeamData && (
                <Stat
                  title={team?.name}
                  metricId={metricName}
                  ownerType={MetricOwnerType.Team}
                  id={coworker.teamId}
                  period={{
                    start: startDate,
                    end: endDate,
                  }}
                />
              )}
            </>
          )}
        </Styled.StatContainer>
        <Divider />

        {coworkerId && (
          <MetricLineChart
            metricId={metricName}
            coworkerId={coworkerId}
            teamId={coworker?.teamId}
            startDate={startDate}
            endDate={endDate}
            controls={{ smoothing: true }}
          />
        )}
      </Widget>
    </div>
  )
}

export default ExplorePage
