import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'
import { DateHelper } from 'lib/utils/date.helper'

import { Paragraph, Subtitle } from 'components/primitives/Text'

import { AssignMissionFormFields } from '../AssignMissionPage.types'

type Props = {
  form: UseFormReturn<AssignMissionFormFields>
  coworker: Coworker
}

const ReviewStep: React.FC<Props> = ({ form, coworker }) => {
  const { t } = useTranslation()
  const { watch } = form

  const { metricId: metric, endDate, target } = watch()

  return (
    <>
      <Subtitle>{t('features.mission.assign-mission.steps.summary')}</Subtitle>
      <Paragraph>
        {metric && target && endDate ? (
          <>
            {t('features.mission.assign-mission.mission-summary', {
              coworker: coworker.firstName,
              metric,
              target,
              endDate: DateHelper.dateToString(endDate),
            })}
          </>
        ) : (
          <>{t('features.mission.assign-mission.summary-not-available-yet')}</>
        )}
      </Paragraph>
    </>
  )
}

export default ReviewStep
