import {
  isQarGroupKey,
  isQarKey,
  isQarQuestionKey,
  QarGroupKey,
  qarGroups,
  QarKey,
  QarQuestionKey,
} from './qarForm.config'
import {
  isQasGroupKey,
  isQasKey,
  isQasQuestionKey,
  QasGroupKey,
  qasGroups,
  QasKey,
  QasQuestionKey,
} from './qasForm.config'
import {
  isQsaGroupKey,
  isQsaKey,
  isQsaQuestionKey,
  QsaGroupKey,
  qsaGroups,
  QsaKey,
  QsaQuestionKey,
} from './qsaForm.config'

export const allGroups = {
  ...qarGroups,
  ...qasGroups,
  ...qsaGroups,
}

const groupToQaIds = (group: Record<string, any>) =>
  Object.values(group).map(({ id, questions }) => [
    id,
    ...Object.values(questions).map((question) => (question as any).id),
  ])

export type QaGroupKey = QarGroupKey | QasGroupKey | QsaGroupKey
export type QaQuestionKey = QarQuestionKey | QasQuestionKey | QsaQuestionKey
export type QaMetricId = QarKey | QasKey | QsaKey

export const isQaGroupKey = (id: string): id is QaGroupKey =>
  isQarGroupKey(id) || isQasGroupKey(id) || isQsaGroupKey(id)
export const isQaQuestionKey = (id: string): id is QaQuestionKey =>
  isQarQuestionKey(id) || isQasQuestionKey(id) || isQsaQuestionKey(id)
export const isQaMetricId = (id: string): id is QaMetricId =>
  isQarKey(id) || isQasKey(id) || isQsaKey(id)
