import { space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'

import { useBadgeGrouping } from 'hooks/badges/useBadgeGrouping'

import { useBadgesQuery } from 'state/slices/api'

import LoadingIndicator from 'components/composites/Shared/LoadingIndicator/LoadingIndicator.component'
import { Header } from 'components/layout/Header'
import { Divider } from 'components/primitives/Divider'
import { Subtitle } from 'components/primitives/Text'

import { BadgeCreate } from './BadgeCreate'
import { BadgeEdit } from './BadgeEdit'
import { BadgeGroupEdit } from './BadgeGroupEdit'

const ManageBadgesPage = () => {
  const {
    data: badges,
    isLoading: isLoadingBadges,
    isSuccess: isSuccessBadges,
  } = useBadgesQuery()

  const {
    groupedAchievableBadges,
    ungroupedAchievableBadges,
    awardableBadges,
  } = useBadgeGrouping(badges)

  return (
    <>
      <Header backHref="/admin" title="Manage Badges" />
      {isLoadingBadges && (
        <LoadingIndicator message="Fetching badges" />
      )}
      {isSuccessBadges && (
        <div>
          {groupedAchievableBadges &&
            Object.keys(groupedAchievableBadges).length !== 0 && (
              <>
                <Subtitle>{'Achievable Badge Groups'}</Subtitle>
                <Flex wrap="wrap" gap={space100}>
                  {Object.entries(groupedAchievableBadges).map(
                    ([groupId, group]) => (
                      <BadgeGroupEdit key={groupId} badgeGroup={group} />
                    )
                  )}
                </Flex>
                <Divider />
              </>
            )}
          {ungroupedAchievableBadges.length !== 0 && (
            <>
              <Subtitle>{'Achievable Badges'}</Subtitle>
              <Flex wrap="wrap" gap={space100}>
                {ungroupedAchievableBadges.map((badge) => (
                  <BadgeEdit key={badge.badgeDefinitionId} badge={badge} />
                ))}
              </Flex>
              <Divider />
            </>
          )}
          {awardableBadges.length !== 0 && (
            <>
              <Subtitle>{'Awardable Badges'}</Subtitle>
              <Flex wrap="wrap" gap={space100}>
                {awardableBadges.map((badge) => (
                  <BadgeEdit key={badge.badgeDefinitionId} badge={badge} />
                ))}
              </Flex>
            </>
          )}
          <BadgeCreate />
        </div>
      )}
    </>
  )
}

export default ManageBadgesPage
