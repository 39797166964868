import { AchievedBadge,AwardedBadge } from 'lib/types/coworker.types'

export enum BadgeType {
  Achievable = 'achieved',
  Awardable = 'awarded',
}

export enum BadgeStatus {
  Locked = 'locked',
  Unlocked = 'unlocked',
}

export type BadgeIconType = 'bronze' | 'silver' | 'gold' | 'awardable'

export type BadgeGroupMembership = {
  groupId: string
  order: number
}

export type BadgeDefinition = {
  badgeDefinitionId: string
  title: string
  description: string
  flair: string
  category: string
  iconType: BadgeIconType
  type: BadgeType
  secret: boolean
  group?: BadgeGroupMembership
}

export type GroupedBadgeDefinition = BadgeDefinition & {
  group: BadgeGroupMembership
}

export type AchievedBadgeDefinition = Omit<BadgeDefinition, 'badgeId'> &
  AchievedBadge

export type AwardedBadgeDefinition = Omit<BadgeDefinition, 'badgeId'> &
  AwardedBadge

export type GroupedUnlockedBadgeDefinition = Omit<
  GroupedBadgeDefinition,
  'badgeId'
> &
  AchievedBadge

export type BadgeService = {
  createOrEditBadge: (badge: BadgeDefinition) => Promise<BadgeDefinition>
  fetchAllBadges: () => Promise<BadgeDefinition[]>
}

export const isGroupedBadgeDefinition = (
  badge: BadgeDefinition
): badge is GroupedBadgeDefinition => badge.group !== undefined

export const isGroupedUnlockedBadgeDefinition = (
  badge: AchievedBadgeDefinition
): badge is GroupedUnlockedBadgeDefinition => badge.group !== undefined

export const isAchievedBadgeDefinition = (
  badge: BadgeDefinition | AchievedBadgeDefinition
): badge is AchievedBadgeDefinition => {
  const asUnlocked = badge as AchievedBadgeDefinition
  return asUnlocked.unlockDate != null
}

export const isAwardedBadgeDefinition = (
  badge: BadgeDefinition | AchievedBadgeDefinition | AwardedBadgeDefinition
): badge is AwardedBadgeDefinition => {
  const asType = badge as AwardedBadgeDefinition
  return asType.awarderId != null
}

export const isAchievableBadge = (badge: BadgeDefinition) =>
  badge.type === BadgeType.Achievable
export const isAwardableBadge = (badge: BadgeDefinition) =>
  badge.type === BadgeType.Awardable
