import { space25 } from '@ingka/variables'
import { Flex } from '@mantine/core'

import { MissionStatus } from 'lib/types/mission.types'

import { CancelMissionButton } from './CancelMissionButton'
import { MissionActionsProps } from './MissionActions.types'

const MissionActions: React.FC<MissionActionsProps> = ({
  coworkerId,
  mission,
}) => (
  <Flex align="center" gap={space25}>
    <CancelMissionButton
      coworkerId={coworkerId}
      mission={mission}
      disabled={mission.status === MissionStatus.Closed}
    />
  </Flex>
)

export default MissionActions
