import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { Mission } from 'lib/types/mission.types'

import { useUser } from 'hooks/users'

import { useCoworker } from '../../api.hooks'
import { useMissions } from './mission.slice'

export const useMissionsWrapper = (coworkerId: string | SkipToken) => {
  const { data: user } = useUser()
  const { role } = user ?? {}

  const isTl = role === 'teamlead'

  const regularQuery = useMissions(isTl ? skipToken : coworkerId)
  const teamleadQuery = useCoworker(!isTl ? skipToken : coworkerId)

  if (isTl) {
    const { data, ...coworkerMeta } = teamleadQuery
    const missions = (data as any)?.missions as Mission[] | undefined

    return {
      ...coworkerMeta,
      data: missions,
      currentData: missions,
    }
  }

  return regularQuery
}
