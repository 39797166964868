import { useParams } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

export const useBackHref = () => {
  const { coworkerId } = useParams()
  const { adminPath, teamleadPath } = useSectionPaths()

  const tlSectionHref = `${teamleadPath}/coworkers/${coworkerId}`
  const adminSectionHref = `${adminPath}/users`

  if (teamleadPath) {
    return tlSectionHref
  }

  if (adminPath) {
    return adminSectionHref
  }

  return undefined
}
