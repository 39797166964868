import { space100 } from '@ingka/variables'
import { Flex, FlexProps } from '@mantine/core'

type WidgetFooterProps = {
  children: React.ReactNode
} & FlexProps

const WidgetFooter: React.FC<WidgetFooterProps> = ({ children }) => (
  <Flex wrap="wrap" gap={space100}>
    {children}
  </Flex>
)

export default WidgetFooter
