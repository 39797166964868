import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { space100 } from '@ingka/variables'
import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { useFormatDate } from 'hooks/formatting'

import { MissionStatusIndicator } from 'components/composites/Missions/MissionStatusIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { Caption, Paragraph } from 'components/primitives/Text'

import { useMissionHref } from './MissionPreview.hooks'
import { MissionPreviewProps } from './MissionPreview.types'

const MissionPreview: React.FC<MissionPreviewProps> = ({
  mission,
  headerContentRight,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const href = useMissionHref(mission)

  const formatDate = useFormatDate()

  const { description, endDate, metricId, result, startDate, status, title } =
    mission

  const formattedDateRange = `${formatDate(new Date(startDate))} - 
  ${formatDate(new Date(endDate))}`

  return (
    <Widget
      header={
        <WidgetHeader
          title={title}
          rightSideContent={
            headerContentRight ? (
              headerContentRight
            ) : (
              <MissionStatusIndicator
                status={result ? result.outcome : status}
              />
            )
          }
        />
      }
      footer={
        <WidgetFooter>
          <Link to={href} state={{ from: pathname }}>
            <Button
              type="tertiary"
              small
              text={t('features.mission.actions.view-mission')}
              iconPosition="trailing"
              ssrIcon={arrowRight}
            />
          </Link>
        </WidgetFooter>
      }
    >
      <Caption>{formattedDateRange}</Caption>

      <Box component="span" mb={space100}>
        <Caption $bold>{t(`${metricId}`)}</Caption>
      </Box>

      <Paragraph>{description}</Paragraph>
    </Widget>
  )
}

export default MissionPreview
