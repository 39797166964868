import Button from '@ingka/button'
import InlineMessage from '@ingka/inline-message'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import { space50 } from '@ingka/variables'
import { Box, Grid, Stack } from '@mantine/core'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useInteractionsForCoachingSession } from 'hooks/coachingSessions/useInteractionsForCoachingSession.hooks'
import { useMissionsForCoachingSession } from 'hooks/coachingSessions/useMissionsForCoachingSession.hooks'
import { useFormatDate } from 'hooks/formatting/useFormatDate.hooks'
import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSession, useSignCoachingSession } from 'state/slices/api'

import { CoachingSessionStatusIndicator } from 'components/composites/CoachingSessions/CoachingSessionStatusIndicator'
import { InteractionPreviewList } from 'components/composites/Interactions/InteractionPreviewList'
import { MissionPreview } from 'components/composites/Missions/MissionPreview'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { CoachingSessionOverviewSkeleton } from 'components/features/CoachingSessions/CoachingSessionOverview/CoachingSessionOverviewSkeleton'
import { CoachingSessionTeamleadActions } from 'components/features/CoachingSessions/CoachingSessionTeamleadActions'
import { StickyFooter } from 'components/layout/StickyFooter'
import ConditionalTooltip from 'components/primitives/ConditionalTooltip/ConditionalTooltip.component'
import { Paragraph, SubHeading } from 'components/primitives/Text'
import { FeatureToggle } from 'components/shared/FeatureToggle'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { CoachingSessionOverviewProps } from './CoachingSessionOverview.types'

const CoachingSessionOverview: React.FC<CoachingSessionOverviewProps> = ({
  coworkerId,
  coachingSessionId,
}) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  const pageType = useCurrentPageType()
  const [signCoachingSession, { isLoading: isLoadingSignCoachingSession }] =
    useSignCoachingSession()

  const handleSignCoachingSessionClicked = useCallback(
    () =>
      signCoachingSession({
        coworkerId,
        coachingSessionId,
        signedDate: DateHelper.dateToString(new Date()),
      }).then(() => setShowSignModal(false)),
    [coachingSessionId, coworkerId, signCoachingSession]
  )

  const [showSignModal, setShowSignModal] = useState(false)
  const { teamleadPath } = useSectionPaths()
  const { pathname } = useLocation()

  const {
    data: coachingSession,
    isLoading: isLoadingCoachingSession,
    isSuccess: isSuccessCoachingSession,
    isError: isErrorCoachingSession,
  } = useCoachingSession({
    coworkerId,
    coachingSessionId,
  })

  const {
    data: interactions,
    isLoading: isLoadingInteractions,
    isSuccess: isSuccessInteractions,
    isError: isErrorInteractions,
  } = useInteractionsForCoachingSession({ coworkerId, coachingSessionId })

  const {
    data: missions,
    isLoading: isLoadingMissions,
    isSuccess: isSuccessMissions,
    isError: isErrorMissions,
  } = useMissionsForCoachingSession({ coworkerId, coachingSessionId })

  const formattedPlannedDate =
    coachingSession && formatDate(new Date(coachingSession.plannedDate))

  const isLoading =
    isLoadingCoachingSession || isLoadingInteractions || isLoadingMissions
  const isSuccess =
    isSuccessCoachingSession && isSuccessInteractions && isSuccessMissions
  const isError =
    isErrorCoachingSession || isErrorInteractions || isErrorMissions

  return (
    <>
      {isLoading && <CoachingSessionOverviewSkeleton />}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {!coachingSession && <InlineMessage title={t('errors.not-found')} />}
          {coachingSession && (
            <>
              <Widget
                header={
                  <WidgetHeader
                    title={t('features.coaching-session.session-planned-for', {
                      date: formattedPlannedDate,
                    })}
                    rightSideContent={
                      <CoachingSessionStatusIndicator
                        status={coachingSession.status}
                      />
                    }
                  />
                }
                footer={
                  pageType === 'coworker' ? (
                    <WidgetFooter>
                      <ConditionalTooltip
                        condition={
                          coachingSession.status !==
                          CoachingSessionStatus.Completed
                        }
                        tooltipText={t(
                          'features.coaching-session.warnings.cannot-sign-incomplete-coaching-session'
                        )}
                      >
                        <Button
                          type="primary"
                          small
                          disabled={
                            coachingSession.status !==
                            CoachingSessionStatus.Completed
                          }
                          text={t(
                            'features.coaching-session.sign-coaching-session.title'
                          )}
                          onClick={() => setShowSignModal(true)}
                        />
                      </ConditionalTooltip>
                    </WidgetFooter>
                  ) : pageType === 'teamlead' ? (
                    <WidgetFooter>
                      <CoachingSessionTeamleadActions
                        coworkerId={coworkerId}
                        coachingSessionId={coachingSession.id}
                      />
                    </WidgetFooter>
                  ) : null
                }
              >
                <Stack>
                  <Stack spacing={space50}>
                    <SubHeading>{t('features.interaction.title')}</SubHeading>
                    <InteractionPreviewList interactions={interactions} />
                  </Stack>
                  <Stack spacing={space50}>
                    <SubHeading>{t('features.mission.title')}</SubHeading>
                    {(!missions || missions.length === 0) && (
                      <InlineMessage
                        variant="cautionary"
                        title={t('features.mission.warnings.no-data')}
                      />
                    )}
                    {missions && missions.length !== 0 && (
                      <Grid>
                        {missions.map((mission) => (
                          <Grid.Col md={6} key={mission.id}>
                            <MissionPreview mission={mission} />
                          </Grid.Col>
                        ))}
                      </Grid>
                    )}
                  </Stack>

                  <Box>
                    <SubHeading>
                      {t('features.coaching-session.coworker-comment.title')}
                    </SubHeading>
                    <Paragraph>
                      {coachingSession.coworkerComment ||
                        t(
                          'features.coaching-session.coworker-comment.no-comment'
                        )}
                    </Paragraph>
                  </Box>
                </Stack>
              </Widget>
              <Modal
                visible={showSignModal}
                handleCloseBtn={() => setShowSignModal(false)}
              >
                <Prompt
                  title={t(
                    'features.coaching-session.actions.sign-coaching-session'
                  )}
                  titleId="sign-session-modal"
                  header={<ModalHeader />}
                  footer={
                    <ModalFooter>
                      <Button
                        small
                        type="primary"
                        text={t(
                          'features.coaching-session.actions.sign-coaching-session'
                        )}
                        onClick={handleSignCoachingSessionClicked}
                        loading={isLoadingSignCoachingSession}
                      />
                      <Button
                        small
                        type="secondary"
                        text={t('common.actions.cancel')}
                        onClick={() => setShowSignModal(false)}
                      />
                    </ModalFooter>
                  }
                >
                  <Paragraph>
                    {t('features.coaching-session.sign-coaching-session.body')}
                  </Paragraph>
                </Prompt>
              </Modal>
              <FeatureToggle featureKey="coaching_session">
                {pageType === 'teamlead' && (
                  <>
                    {coachingSession.status ===
                      CoachingSessionStatus.Completed && (
                      <StickyFooter>
                        <CoachingSessionTeamleadActions
                          coworkerId={coworkerId}
                          coachingSessionId={coachingSession.id}
                        />
                      </StickyFooter>
                    )}

                    {(coachingSession.status === CoachingSessionStatus.Draft ||
                      coachingSession.status ===
                        CoachingSessionStatus.InProgress) && (
                      <StickyFooter>
                        <Paragraph>
                          {t(
                            'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.resume-guided-flow.title'
                          )}
                        </Paragraph>
                        <Link
                          to={`${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSession.id}/edit/`}
                          state={{ from: pathname }}
                        >
                          <Button
                            small
                            type="primary"
                            text={t(
                              'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.resume-guided-flow.actions'
                            )}
                          />
                        </Link>
                      </StickyFooter>
                    )}
                  </>
                )}
              </FeatureToggle>
            </>
          )}
        </>
      )}
    </>
  )
}

export default CoachingSessionOverview
