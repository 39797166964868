import { useTranslation } from 'react-i18next'

import { Paragraph } from 'components/primitives/Text'

import * as Styled from './WelcomeModal.styling'

const CoworkerContentIntro = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Styled.ModalBodyImage src="/images/party-popper.gif" />
      <Paragraph>
        <Styled.Paragraph>
          {t('introduction.welcome-modal.coworker-content.app-goal')}
        </Styled.Paragraph>
      </Paragraph>
    </div>
  )
}

export default CoworkerContentIntro
