import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import trashCan from '@ingka/ssr-icon/paths/trash-can'
import { space50 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

import { ActionPoint } from 'lib/types/actionPoint.types'

import {
  useDeleteActionPointDraft,
  useUpdateActionPointDraft,
} from 'state/slices/api'

type EditableActionPointPreviewProps = {
  actionPoint: ActionPoint
  coworkerId: string
  coachingSessionId: string
}

const EditableActionPointPreview: React.FC<EditableActionPointPreviewProps> = ({
  actionPoint,
  coworkerId,
  coachingSessionId,
}) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(actionPoint.title)
  const [debouncedTitle] = useDebounce(title, 2000)

  const [deleteActionPoint, { isLoading }] = useDeleteActionPointDraft()
  const [updateActionPoint] = useUpdateActionPointDraft()

  useEffect(() => {
    updateActionPoint({
      coworkerId,
      coachingSessionId,
      actionPointId: actionPoint.id,
      title: debouncedTitle,
    })
  }, [
    actionPoint,
    coachingSessionId,
    coworkerId,
    debouncedTitle,
    updateActionPoint,
  ])

  const handleDeleteClicked = useCallback(() => {
    deleteActionPoint({
      coworkerId,
      coachingSessionId,
      actionPointId: actionPoint.id,
    })
  }, [actionPoint.id, coachingSessionId, coworkerId, deleteActionPoint])

  return (
    <Flex align="center" gap={space50}>
      <FormField style={{ flex: 1, marginBottom: 0 }}>
        <InputField
          type="text"
          id={actionPoint.id}
          value={title}
          onChange={(event) => setTitle(event.currentTarget.value)}
        />
      </FormField>
      <Button
        small
        type="tertiary"
        text={t('common.actions.delete')}
        ssrIcon={trashCan}
        onClick={handleDeleteClicked}
        loading={isLoading}
      />
    </Flex>
  )
}

export default EditableActionPointPreview
