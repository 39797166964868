import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import Select, { OptGroup, Option } from '@ingka/select'
import { t } from 'i18next'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

import { connectedMetricsMatrix } from 'config/domain/connectedMetrics.config'
import { qarGroups } from 'config/domain/qarForm.config'
import { qasGroups } from 'config/domain/qasForm.config'
import { qsaGroups } from 'config/domain/qsaForm.config'

import { DatePicker } from 'components/primitives/DatePicker'

import { MissionDraftModalProps } from '../MissionsEditor.types'

const MissionDraftModal: React.FC<MissionDraftModalProps> = ({
  visible,
  handleCloseBtn,
  form,
  handleFormSubmit,
  isLoadingCreateMissionDraft,
}) => {
  const { handleSubmit, control, formState, watch } = form
  const [assessmentType, setAssessmentType] = useState('QAR')

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Modal visible={visible} handleCloseBtn={handleCloseBtn}>
        <Prompt
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                small
                text={t('common.actions.create')}
                htmlType="submit"
                loading={isLoadingCreateMissionDraft}
              />
              <Button
                type="secondary"
                small
                text={t('common.actions.cancel')}
                onClick={handleCloseBtn}
              />
            </ModalFooter>
          }
          title={t(
            'features.coaching-session.set-new-plan.create-mission-draft.modal.title'
          )}
          titleId={''}
        >
          {/* Set mission target */}
          <Controller
            name="target"
            control={control}
            rules={{
              required: t(
                'features.mission.assign-mission.fields.target.errors.missing'
              ),
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField
                data-testid="form-field-target"
                fieldHelper={{
                  msg: t('features.mission.assign-mission.fields.target.hint', {
                    // coworker: coworker.firstName,
                  }),
                }}
                shouldValidate={formState.errors.target !== undefined}
                valid={formState.errors.target == null}
                validation={{
                  msg: formState.errors.target?.message,
                }}
              >
                <InputField
                  label={t(
                    'features.mission.assign-mission.fields.target.title'
                  )}
                  id="target"
                  data-testid="input-field-target"
                  type="number"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              </FormField>
            )}
          />

          {/* Set mission due date */}
          <Controller
            name="endDate"
            control={control}
            rules={{
              required: t(
                'features.mission.assign-mission.fields.end-date.errors.missing'
              ),
              pattern: {
                value: /^\d{4}-\d{1,2}-\d{1,2}$/,
                message: t(
                  'features.mission.assign-mission.fields.end-date.errors.invalid-pattern'
                ),
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField
                data-testid="form-field-end-date"
                fieldHelper={{
                  msg: t(
                    'features.mission.assign-mission.fields.end-date.hint'
                  ),
                }}
                shouldValidate={formState.errors.endDate !== undefined}
                valid={formState.errors.endDate == null}
                validation={{
                  msg: formState.errors.endDate?.message,
                }}
              >
                <DatePicker
                  id="endDate"
                  data-testid="input-field-endDate"
                  label={t(
                    'features.mission.assign-mission.fields.end-date.title'
                  )}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              </FormField>
            )}
          />

          {/* Select QA metric and question/connected metric  */}
          <FormField
            data-testid="form-field-metric"
            fieldHelper={{
              msg: t(
                'features.mission.assign-mission.fields.assessment-type.hint'
              ),
            }}
          >
            <Select
              label={t(
                'features.mission.assign-mission.fields.assessment-type.title'
              )}
              id="metric"
              data-testid="input-field-assessment-type"
              onChange={(e) => setAssessmentType(e.target.value)}
            >
              <Option name={t('qa.assessment-type.QAR')} value="QAR" />
              <Option name={t('qa.assessment-type.QAS')} value="QAS" />
              <Option name={t('qa.assessment-type.QSA')} value="QSA" />
            </Select>
          </FormField>

          {/* Controller for QA select from assignMission */}
          <Controller
            name="metricId"
            control={control}
            rules={{
              required: t(
                'features.mission.assign-mission.fields.metric.errors.missing'
              ),
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField
                data-testid="form-field-metric"
                fieldHelper={{
                  msg: t('features.mission.assign-mission.fields.metric.hint', {
                    // coworker: coworker.firstName,
                  }),
                }}
                shouldValidate={formState.errors.metricId !== undefined}
                valid={formState.errors.metricId == null}
                validation={{
                  msg: formState.errors.metricId?.message,
                }}
              >
                <Select
                  label={t(
                    'features.mission.assign-mission.fields.metric.title'
                  )}
                  id="metric"
                  data-testid="input-field-metric"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                >
                  {assessmentType === 'QAR' && (
                    <>
                      <Option name={t('qa.QARTT')} value="QARTT" />
                      {Object.values(qarGroups).map((group) => (
                        <OptGroup
                          groupName={t(`qa.${group.id}`)}
                          key={group.id}
                        >
                          <Option name={t(`qa.${group.id}`)} value={group.id} />
                          {Object.values(group.questions).map((question) => (
                            <Option
                              key={question.id}
                              name={t(`qa.${question.id}`)}
                              value={question.id}
                            />
                          ))}
                        </OptGroup>
                      ))}
                    </>
                  )}
                  {assessmentType === 'QAS' && (
                    <>
                      <Option name={t('qa.QASTT')} value="QASTT" />
                      {Object.values(qasGroups).map((group) => (
                        <OptGroup
                          groupName={t(`qa.${group.id}`)}
                          key={group.id}
                        >
                          <Option name={t(`qa.${group.id}`)} value={group.id} />
                          {Object.values(group.questions).map((question) => (
                            <Option
                              key={question.id}
                              name={t(`qa.${question.id}`)}
                              value={question.id}
                            />
                          ))}
                        </OptGroup>
                      ))}
                    </>
                  )}
                  {assessmentType === 'QSA' && (
                    <>
                      <Option name={t('qa.QSATT')} value="QSATT" />
                      {Object.values(qsaGroups).map((group) => (
                        <OptGroup
                          groupName={t(`qa.${group.id}`)}
                          key={group.id}
                        >
                          <Option name={t(`qa.${group.id}`)} value={group.id} />
                          {Object.values(group.questions).map((question) => (
                            <Option
                              key={question.id}
                              name={t(`qa.${question.id}`)}
                              value={question.id}
                            />
                          ))}
                        </OptGroup>
                      ))}
                    </>
                  )}
                </Select>
              </FormField>
            )}
          />
          <Controller
            name="connectedMetricId"
            control={control}
            rules={{
              required: t(
                'features.mission.assign-mission.fields.connected-metric.errors.missing'
              ),
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormField
                data-testid="form-field-connected-metric"
                fieldHelper={{
                  msg: t(
                    'features.mission.assign-mission.fields.connected-metric.hint'
                  ),
                }}
                shouldValidate={
                  formState.errors.connectedMetricId !== undefined
                }
                valid={formState.errors.connectedMetricId == null}
                validation={{
                  msg: formState.errors.connectedMetricId?.message,
                }}
              >
                <Select
                  label={t(
                    'features.mission.assign-mission.fields.connected-metric.title'
                  )}
                  id="connectedMetric"
                  data-testid="input-field-connected-metric"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                >
                  {watch('metricId') &&
                    connectedMetricsMatrix[watch('metricId')].map(
                      (metricId) => (
                        <Option
                          key={metricId}
                          name={t(`common.metrics.${metricId}.name`)}
                          value={metricId}
                        />
                      )
                    )}
                </Select>
              </FormField>
            )}
          />
        </Prompt>
      </Modal>
    </form>
  )
}
export default MissionDraftModal
