import SSRIcon from "@ingka/ssr-icon"
import orderedList from "@ingka/ssr-icon/paths/ordered-list"
import document from "@ingka/ssr-icon/paths/document"
import documentPencil from "@ingka/ssr-icon/paths/document-pencil"
import feedbackSatisfied from "@ingka/ssr-icon/paths/feedback-satisfied"
import feedbackHappy from "@ingka/ssr-icon/paths/feedback-happy"
import chat from "@ingka/ssr-icon/paths/chat"
import checkMark from "@ingka/ssr-icon/paths/checkmark"

import { Stack, Stepper } from "@mantine/core"
import { t } from "i18next"
import { space200 } from "@ingka/variables"

type CoachingGuidedFlowStepperProps = {
  activeStep: number
  setActiveStep: (activeStep: number) => void
}

const CoachingGuidedFlowStepper: React.FC<CoachingGuidedFlowStepperProps> = ({ activeStep, setActiveStep }) => {

  return (
    <Stack m="auto">
      <Stepper
        active={activeStep}
        onStepClick={setActiveStep}
        completedIcon={<SSRIcon paths={checkMark} />}
        breakpoint="sm"
        iconSize={32}
        color="dark"
        size="md"
      >
        <Stepper.Step
          icon={<SSRIcon paths={orderedList} />}
          description={t('features.coaching-session.performance-overview.title')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        </Stepper.Step>
        <Stepper.Step
          icon={<SSRIcon paths={document} />}
          description={t('features.coaching-session.review-last-plan.title')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
        </Stepper.Step>
        <Stepper.Step
          icon={<SSRIcon paths={documentPencil} />}
          description={t('features.coaching-session.set-new-plan.title')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
        </Stepper.Step>
        <Stepper.Step
          px={space200}
          icon={<SSRIcon paths={feedbackHappy} />}
          description={t('features.coaching-session.coworker-feedback.title')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
        </Stepper.Step>
        <Stepper.Step
          icon={<SSRIcon paths={chat} />}
          description={t('features.coaching-session.summary.title')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
        </Stepper.Step>
      </Stepper>
    </Stack>
  )
}

export default CoachingGuidedFlowStepper
