import { BadgeDefinition, isAwardableBadge } from 'lib/types/badge.types'
import { AchievedBadge } from 'lib/types/coworker.types'

import { getUnlockedBadgeDefinitions } from './useBadgeGrouping'

/**
 * Filters badges to find unlocked and locked awardable badges
 */
export const useAwardableBadges = (
  unlockedBadges: AchievedBadge[] | undefined = [],
  badges: BadgeDefinition[] | undefined = []
) => {
  const awardableBadges = badges.filter(isAwardableBadge)
  const unlockedAwardableBadges = getUnlockedBadgeDefinitions(
    unlockedBadges,
    awardableBadges
  )

  const lockedAwardableBadges = awardableBadges.filter(
    (badge) =>
      !unlockedAwardableBadges.some(
        (unlockedBadge) =>
          unlockedBadge.badgeDefinitionId === badge.badgeDefinitionId
      )
  )

  return {
    lockedAwardableBadges,
    unlockedAwardableBadges,
  }
}
