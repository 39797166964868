import { space100 } from '@ingka/variables'

import styled from 'styled-components'

export const NavigationButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: ${space100};
  margin-top: 2rem;
`
