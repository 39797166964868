import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import checkmark from '@ingka/ssr-icon/paths/checkmark'
import cross from '@ingka/ssr-icon/paths/cross'
import plusCircle from '@ingka/ssr-icon/paths/plus-circle'
import { space50 } from '@ingka/variables'
import { Box, Flex, Stack } from '@mantine/core'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DateHelper } from 'lib/utils/date.helper'

import { useActionPointsForCoachingSession } from 'hooks/coachingSessions/useActionPointsForCoachingSession.hooks'
import { useUser } from 'hooks/users'

import { useCreateActionPointDraft } from 'state/slices/api'

import { ActionPlanEditorProps } from './ActionPlanEditor.types'
import { EditableActionPointPreview } from './EditableActionPointPreview'

const ActionPlanEditor: React.FC<ActionPlanEditorProps> = ({
  coworkerId,
  coachingSessionId,
}) => {
  const { data: user } = useUser()

  const [createActionPoint, { isLoading }] = useCreateActionPointDraft()
  const { t } = useTranslation()

  const createActionPointForm = useForm({
    defaultValues: {
      title: '',
    },
  })

  const { control, getValues, handleSubmit, reset } = createActionPointForm

  const [showCreateActionPoint, setShowCreateActionPoint] = useState(false)

  const handleCreateActionPoint = useCallback(() => {
    if (!user) return
    const { title } = getValues()
    const actionPoint = {
      title,
      createdBy: user.legacyId,
      createdDate: DateHelper.dateToString(new Date()),
    }

    createActionPoint({
      actionPoint,
      coworkerId,
      coachingSessionId,
    }).then(() => {
      setShowCreateActionPoint(false)
      reset()
    })
  }, [coachingSessionId, coworkerId, createActionPoint, getValues, reset, user])

  const handleCancelCreateActionPoint = useCallback(() => {
    setShowCreateActionPoint(false)
    reset()
  }, [reset])

  const { data: actionPoints } = useActionPointsForCoachingSession({
    coworkerId,
    coachingSessionId,
  })

  return (
    <>
      <Stack>
        {actionPoints.length !== 0 && (
          <Box>
            {actionPoints.map((actionPoint) => (
              <EditableActionPointPreview
                key={actionPoint.id}
                actionPoint={actionPoint}
                coworkerId={coworkerId}
                coachingSessionId={coachingSessionId}
              />
            ))}
          </Box>
        )}
        {showCreateActionPoint && (
          <form onSubmit={handleSubmit(handleCreateActionPoint)}>
            <Flex align="center" gap={space50}>
              <Controller
                name="title"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <FormField style={{ flex: 1, marginBottom: 0 }}>
                      <InputField
                        type="text"
                        id="content"
                        value={value}
                        onChange={onChange}
                      />
                    </FormField>
                    <Button
                      iconOnly
                      small
                      ssrIcon={checkmark}
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    />
                    <Button
                      iconOnly
                      small
                      ssrIcon={cross}
                      type="secondary"
                      onClick={handleCancelCreateActionPoint}
                    />
                  </>
                )}
              />
            </Flex>
          </form>
        )}
        {!showCreateActionPoint && (
          <Box>
            <Button
              small
              type="primary"
              ssrIcon={plusCircle}
              iconPosition="trailing"
              text={t(
                'features.coaching-session.action-plan.actions.add-action-point'
              )}
              onClick={() => setShowCreateActionPoint(true)}
            />
          </Box>
        )}
      </Stack>
    </>
  )
}

export default ActionPlanEditor
