import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { useCoachingSession, useInteractions } from 'state/slices/api'

// Interactions that have happened within the last week are considered related to the coaching session
const RELATED_INTERACTION_TIME = 1000 * 60 * 60 * 24 * 7

export const useInteractionsForCoachingSession = (
  args:
    | {
        coworkerId: string
        coachingSessionId: string
      }
    | SkipToken
) => {
  const { data: coachingSession, ...metaCoachingSession } =
    useCoachingSession(args)

  const { data: interactions, ...metaInteractions } = useInteractions(
    args === skipToken ? skipToken : args.coworkerId
  )

  const plannedDate =
    coachingSession && new Date(coachingSession.plannedDate).getTime()

  const relatedInteractions =
    (plannedDate != null &&
      interactions?.filter((interaction) => {
        const interactionDate = new Date(interaction.handledTimestamp).getTime()
        return (
          interactionDate < plannedDate &&
          interactionDate > plannedDate - RELATED_INTERACTION_TIME
        )
      })) ||
    []

  const meta = metaCoachingSession.isSuccess
    ? metaInteractions
    : metaCoachingSession

  return {
    ...meta,
    data: relatedInteractions,
    currentData: relatedInteractions,
  }
}
