import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AchievedBadgeDefinition,
  AwardedBadgeDefinition,
  BadgeDefinition,
  isAwardedBadgeDefinition,
} from 'lib/types/badge.types'

import { useFormatDate } from 'hooks/formatting'

import {
  DynamicTooltip,
  TooltipBody,
  TooltipCaption,
  TooltipTitle,
} from 'components/composites/Shared/Tooltip'
import { Stack } from 'components/layout/Stack'

import { Badge } from '../Badge'
import * as Styled from './BadgeWithTooltip.styling'

type BadgeWithTooltipProps = {
  badge: AwardedBadgeDefinition | AchievedBadgeDefinition | BadgeDefinition
  showInColor?: boolean
  shouldWeShowLock?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const BadgeWithTooltip: React.FC<BadgeWithTooltipProps> = ({
  badge,
  showInColor,
  shouldWeShowLock,
  ...props
}) => {
  const { t } = useTranslation()

  const formatDate = useFormatDate()

  const [showTooltip, setShowtooltip] = useState(false)

  const unlocked = isAwardedBadgeDefinition(badge)

  const unlockDate = unlocked
    ? formatDate(new Date(badge.unlockDate))
    : undefined

  let tooltipContent
  if (isAwardedBadgeDefinition(badge)) {
    tooltipContent = badge.message
  } else {
    tooltipContent = badge.description
  }


  return (
    <Stack>
      <Styled.Wrapper>
        <DynamicTooltip hidden={!showTooltip}>
          <TooltipTitle>{badge.title}</TooltipTitle>
          {unlocked && (
            <TooltipCaption>
              {t('features.badge.unlocked-on-date', { date: unlockDate })}
            </TooltipCaption>
          )}
          <TooltipBody>{tooltipContent}</TooltipBody>
        </DynamicTooltip>
        <Styled.BadgeWrapper colored={unlocked || showInColor}>
          <Badge
            {...props}
            badge={badge}
            showLock={shouldWeShowLock}
            showInColor={unlocked || showInColor}
            onMouseEnter={() => setShowtooltip(true)}
            onMouseLeave={() => setShowtooltip(false)}
          />
        </Styled.BadgeWrapper>
      </Styled.Wrapper>
      <Styled.TextWrapper bodySize="s" tagName="h4" headingSize="xs">
        {badge.title}
      </Styled.TextWrapper>
    </Stack>
  )
}

export default BadgeWithTooltip
