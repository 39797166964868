import { durationStandardWeb, easeEnter } from '@ingka/variables'

import { colourDisabledUi } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

type TableRowProps = {
  active?: boolean
}

export const TableRow = styled.tr<TableRowProps>`
  transition: background-color ${easeEnter} ${durationStandardWeb};

  td {
    color: ${(props) => (props.active ? 'inherit' : colourDisabledUi)};
  }

  &:hover {
    cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  }
`

export const CenteredText = styled.div`
  text-align: center;
`
