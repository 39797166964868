export class FormattingHelper {
  static formatNumber(
    value: number | string | undefined,
    decimalPlaces: number = 0,
    locale: Intl.LocalesArgument = undefined
  ): string | undefined {
    if (value === undefined) return undefined

    return value.toLocaleString(locale, {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    })
  }
}
