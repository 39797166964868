import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import { space100 } from '@ingka/variables'
import { Flex, Stack } from '@mantine/core'
import { toNumber } from 'lodash'
import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { QaMetricId } from 'config/domain/qa.config'

import { Coworker } from 'lib/types/coworker.types'
import { MetricOwnerType } from 'lib/types/metric.types'
import { Team } from 'lib/types/team.types'
import { DateHelper } from 'lib/utils/date.helper'

import { MetricLineChart } from 'components/features/Metrics/MetricLineChart'
import { Stat } from 'components/features/Metrics/Stat'
import { DatePicker } from 'components/primitives/DatePicker'
import { Subtitle } from 'components/primitives/Text'

import { AssignMissionFormFields } from '../AssignMissionPage.types'
import { useValidators } from '../AssignMissionPage.validation'

type Props = {
  form: UseFormReturn<AssignMissionFormFields>
  coworker: Coworker
  team: Team
}

const TargetStep: React.FC<Props> = ({ form, coworker, team }) => {
  const { t } = useTranslation()
  const { control, formState, watch } = form
  const { metricId: metric, startDate, target } = watch()

  const { validateTarget } = useValidators()

  const numberOfPreviewDays = 90

  const lastPeriodStartDate = DateHelper.subtractDays(
    startDate,
    numberOfPreviewDays
  )
  lastPeriodStartDate.setHours(0, 0, 0, 0)
  const lastPeriodEndDate = new Date(startDate)

  const [previewStartDate, setPreviewStartDate] = useState(lastPeriodStartDate)

  const dateSpan = DateHelper.getDaysBetweenDates(
    previewStartDate,
    lastPeriodEndDate
  )

  return (
    <>
      <Subtitle>{t('features.mission.assign-mission.steps.target')}</Subtitle>
      <Flex justify="space-between" w="100%" gap={space100}>
        <Controller
          name="target"
          control={control}
          rules={{
            required: t(
              'features.mission.assign-mission.fields.target.errors.missing'
            ),
            validate: () => validateTarget(target),
            pattern: {
              value: /^([0-9]+\.?[0-9]*|\.[0-9]+)$/,

              message: t(
                'features.mission.assign-mission.fields.target.errors.invalid-pattern'
              ),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              style={{ maxWidth: '10rem' }}
              data-testid="form-field-target"
              fieldHelper={{
                msg: t('features.mission.assign-mission.fields.target.hint', {
                  coworker: coworker.firstName,
                }),
              }}
              shouldValidate={formState.errors.target !== undefined}
              valid={formState.errors.target == null}
              validation={{
                msg: formState.errors.target?.message,
              }}
            >
              <InputField
                label={t('features.mission.assign-mission.fields.target.title')}
                id="target"
                data-testid="input-field-target"
                type="number"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            </FormField>
          )}
        />

        <FormField
          style={{ width: '16rem' }}
          fieldHelper={{
            msg: t(
              'features.mission.assign-mission.fields.preview-start-date.hint'
            ),
          }}
        >
          <DatePicker
            id="previewStartDate"
            label={t(
              'features.mission.assign-mission.fields.preview-start-date.title'
            )}
            onChange={(date) => {
              date instanceof Date && setPreviewStartDate(date)
            }}
            value={previewStartDate}
            minDate={DateHelper.dataCutoffDate}
            maxDate={DateHelper.yesterday}
          />
        </FormField>
      </Flex>

      <Stack>
        <Flex gap={space100}>
          <Stat
            title={t('features.mission.assign-mission.last-x-days', {
              days: dateSpan,
              name: coworker.firstName,
            })}
            metricId={metric as QaMetricId}
            id={coworker.legacyId}
            ownerType={MetricOwnerType.Coworker}
            period={{
              start: previewStartDate,
              end: lastPeriodEndDate,
            }}
          />
          <Stat
            title={t('features.mission.assign-mission.last-x-days', {
              days: dateSpan,
              name: team.name,
            })}
            metricId={metric as QaMetricId}
            id={coworker.teamId}
            ownerType={MetricOwnerType.Team}
            period={{
              start: previewStartDate,
              end: lastPeriodEndDate,
            }}
          />
        </Flex>

        <MetricLineChart
          metricId={metric as QaMetricId}
          startDate={previewStartDate}
          endDate={lastPeriodEndDate}
          target={target ? toNumber(target) : undefined}
          coworkerId={coworker.legacyId}
          teamId={coworker.teamId}
        />
      </Stack>
    </>
  )
}

export default TargetStep
