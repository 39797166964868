import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useBadgesQuery } from 'state/slices/api'

export const useScrollToBadge = () => {
  const { isSuccess: isSuccessBadges } = useBadgesQuery()

  const [hasScrolled, setHasScrolled] = useState(false)
  const [searchParams] = useSearchParams()
  const badgeId = useMemo(() => searchParams.get('badgeId'), [searchParams])

  useEffect(() => {
    if (!hasScrolled && !badgeId && isSuccessBadges) {
      const badge = document.getElementById(`badge-${badgeId}`)
      if (badge) {
        setHasScrolled(true)
        badge.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [badgeId, isSuccessBadges, searchParams, hasScrolled])
}

export const useBackHref = () => {
  const { coworkerPath } = useSectionPaths()
  return useDefaultBackHref(coworkerPath)
}
