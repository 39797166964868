import Button from '@ingka/button'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import { Stack } from '@mantine/core'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { DateHelper } from 'lib/utils/date.helper'

import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

import { useCoworker } from 'state/slices/api'
import {
  useCoachingSession,
  useCompleteCoachingSession,
} from 'state/slices/api/modules/coachingSession/coachingSession.slice'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { CoachingSessionEditorProps } from 'components/features/CoachingSessions/CoachingSessionEditor/CoachingSessionEditor.types'
import StickyFooterCoachingSession from 'components/layout/StickyFooter/StickyFooter.component'
import ConditionalTooltip from 'components/primitives/ConditionalTooltip/ConditionalTooltip.component'
import { Paragraph } from 'components/primitives/Text'
import { FeatureToggle } from 'components/shared/FeatureToggle'
import UnexpectedErrorMessage from 'components/shared/UnexpectedErrorMessage/UnexpectedErrorMessage.component'

import { CoachingSessionGuidedStepper } from '../CoachingSessionFooter/CoachingSessionGuidedStepper'
import { CoworkerComment } from './CoworkerComment'
import { NewPlan } from './NewPlan'
import { PerformanceOverview } from './PerformanceOverview'
import { ReviewPlan } from './PlanReview'
import { Summary } from './Summary'

const CoachingSession: React.FC<CoachingSessionEditorProps> = ({
  coworkerId,
  coachingSessionId,
}) => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)

  const {
    data: coworker,
    isLoading: isLoadingCoworker,
    isSuccess: isSuccessCoworker,
    isError: isErrorCoworker,
  } = useCoworker(coworkerId)
  const {
    data: coachingSession,
    isLoading: isLoadingCoachingSession,
    isSuccess: isSuccessCoachingSession,
    isError: isErrorCoachingSession,
  } = useCoachingSession({
    coworkerId,
    coachingSessionId,
  })

  const [showCompletionModal, setShowCompletionModal] = useState(false)

  const { teamleadPath } = useSectionPaths()
  const pageType = useCurrentPageType()

  const [
    completeCoachingSession,
    { isLoading: isLoadingCompleteCoachingSession },
  ] = useCompleteCoachingSession()
  const navigate = useNavigate()
  const handleCompleteCoachingSessionClicked = useCallback(() => {
    completeCoachingSession({
      coworkerId,
      coachingSessionId,
      completedDate: DateHelper.dateToString(new Date()),
    }).then(() => {
      navigate(
        `${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSessionId}`
      )
    })
  }, [
    coachingSessionId,
    completeCoachingSession,
    coworkerId,
    navigate,
    teamleadPath,
  ])

  const isCompleteCoachingSessionDisabled =
    activeStep === 4 &&
    coachingSession?.status !== CoachingSessionStatus.InProgress

  const isLoading = isLoadingCoworker || isLoadingCoachingSession
  const isSuccess =
    isSuccessCoworker && isSuccessCoachingSession && coworker && coachingSession
  const isError = isErrorCoworker || isErrorCoachingSession

  return (
    <>
      {isLoading && (
        <LoadingIndicator
          message={
            isLoadingCoworker
              ? t('loading-messages.fetching-coworker-profile')
              : isLoadingCoachingSession
              ? t('loading-messages.fetching-coaching-session')
              : ''
          }
        />
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <Stack m="auto">
          {/* CoachingSession footer stepper */}
          <FeatureToggle featureKey="coaching_session">
            <StickyFooterCoachingSession>
              {activeStep > 1 && (
                <Button
                  small
                  text={t('common.actions.back')}
                  onClick={() => setActiveStep(activeStep - 1)}
                  disabled={activeStep === 0}
                />
              )}
              <CoachingSessionGuidedStepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
              <ConditionalTooltip
                condition={isCompleteCoachingSessionDisabled}
                tooltipText={t(
                  'features.coaching-session.warnings.cannot-complete-draft-coaching-session'
                )}
              >
                <Button
                  small
                  type="primary"
                  text={
                    activeStep === 4
                      ? t(
                          'features.coaching-session.actions.complete-coaching-session'
                        )
                      : t('common.actions.next')
                  }
                  onClick={
                    activeStep === 4
                      ? () => setShowCompletionModal(true)
                      : () => setActiveStep(activeStep + 1)
                  }
                  disabled={isCompleteCoachingSessionDisabled}
                />
              </ConditionalTooltip>
            </StickyFooterCoachingSession>
          </FeatureToggle>
          {/* Current step */}
          {activeStep === 0 && <PerformanceOverview />}
          {activeStep === 1 && (
            <ReviewPlan
              coworkerId={coworkerId}
              coachingSession={coachingSession}
            />
          )}
          {activeStep === 2 && (
            <NewPlan coworker={coworker} coachingSession={coachingSession} />
          )}
          {activeStep === 3 && (
            <CoworkerComment
              coworker={coworker}
              coachingSession={coachingSession}
            />
          )}
          {activeStep === 4 && <Summary />}
        </Stack>
      )}
      <Modal
        visible={showCompletionModal}
        handleCloseBtn={() => setShowCompletionModal(false)}
      >
        <Prompt
          title={t('features.coaching-session.complete-coaching-session.title')}
          titleId="complete-coaching-session-modal"
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                small
                type="primary"
                text={t(
                  'features.coaching-session.actions.complete-coaching-session'
                )}
                onClick={handleCompleteCoachingSessionClicked}
                loading={isLoadingCompleteCoachingSession}
              />

              <Button
                small
                type="secondary"
                text={t('common.actions.cancel')}
                onClick={() => setShowCompletionModal(false)}
              />
            </ModalFooter>
          }
        >
          <Paragraph>
            {t(
              'features.coaching-session.complete-coaching-session.confirmation-message'
            )}
          </Paragraph>
        </Prompt>
      </Modal>
    </>
  )
}

export default CoachingSession
