import { breakpointM, space1350 } from '@ingka/variables';
import styled from 'styled-components';
import { colourNeutralWhite, shadowSizeDefault } from '../../../styles/tokens/oldSkapa.tokens';

export const StickyFooterWrapper = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
@media (min-width: ${breakpointM}) {
 padding-left: ${space1350};
 }
 &:hover, &:focus {
 box-shadow: ${shadowSizeDefault};
 }; 
`;

export const StickyFooterInner = styled.div`
background-color: ${colourNeutralWhite};
box-shadow: ${shadowSizeDefault};
z-index: 1000;
flex: 1 0 0;
width: 100%;
`;
