import { useTranslation } from 'react-i18next'

import { formatDate } from 'config/internationalisation/locales.config'

import { DateHelper } from 'lib/utils/date.helper'

import { useLocale } from 'state/slices/api/api.hooks'

import { CoachingSessionStatusIndicator } from 'components/composites/CoachingSessions/CoachingSessionStatusIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { CoachingSessionTeamleadActions } from 'components/features/CoachingSessions/CoachingSessionTeamleadActions'
import { Paragraph } from 'components/primitives/Text'

import { CurrentCoachingSessionProps } from './CurrentCoachingSession.types'

const CurrentCoachingSession: React.FC<CurrentCoachingSessionProps> = ({
  coworkerId,
  coachingSession,
  interactions,
  missions,
}) => {
  const { dateFnsCode } = useLocale()
  const { t } = useTranslation()

  const { plannedDate } = coachingSession

  // TODO: For now, we simply look at the assessments that have been handled within the last week
  const preparedInteractions = interactions.filter(
    (interaction) =>
      DateHelper.getDaysBetweenDates(
        new Date(interaction.handledTimestamp),
        new Date()
      ) <= 7
  )

  const formattedPlannedDate = formatDate(new Date(plannedDate), dateFnsCode)

  const assessmentsPrepared = preparedInteractions.length
  const missionsPrepared = missions.length

  return (
    <>
      <Widget
        header={
          <WidgetHeader
            title={t('features.coaching-session.session-planned-for', {
              date: formattedPlannedDate,
            })}
            rightSideContent={
              <CoachingSessionStatusIndicator status={coachingSession.status} />
            }
          />
        }
        footer={
          <WidgetFooter>
            <CoachingSessionTeamleadActions
              coworkerId={coworkerId}
              coachingSessionId={coachingSession.id}
            />
          </WidgetFooter>
        }
      >
        {/* TODO: Show time until planned session */}
        {/* TODO: Show QAs conducated since last session */}
        <Paragraph $bold>
          {t('features.coaching-session.assessments-prepared', {
            count: assessmentsPrepared,
          })}
        </Paragraph>
        {/* TODO: Show other relevant info (e.g. QA missions) */}
        <Paragraph $bold>
          {t('features.coaching-session.missions-prepared', {
            count: missionsPrepared,
          })}
        </Paragraph>
      </Widget>
    </>
  )
}

export default CurrentCoachingSession
