import { useTranslation } from 'react-i18next'

import { DateHelper } from 'lib/utils/date.helper'

export const useValidators = () => {
  const { t } = useTranslation()
  const validateEndDate = (endDate: Date) => {
    const today = new Date()
    today.setHours(0, 0)
    const daysDifference = DateHelper.getDaysBetweenDates(today, endDate)

    if (endDate < today) {
      return t('pages.review-mission.fields.end-date.errors.past-date')
    }

    if (daysDifference < 1) {
      return t(
        'features.mission.assign-mission.fields.end-date.errors.minimum-duration'
      )
    }

    return true
  }
  return {
    validateEndDate,
  }
}
