import { User } from 'lib/types/user.types'

import { apiSlice } from 'state/slices/api/api.slice'

type MarkNotificationAsReadParams = {
  userId: string
  notificationId: string
}

const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerSignIn: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}/register-sign-in`,
        method: 'POST',
      }),
    }),
    dismissNotification: builder.mutation<void, MarkNotificationAsReadParams>({
      query: ({ userId, notificationId }) => ({
        url: `/users/${userId}/notifications/${notificationId}/mark-read`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => ['User'],
    }),

    fetchUser: builder.query<User, string>({
      query: (userId) => `/users/${userId}`,
      providesTags: ['User'],
    }),
    searchForUsers: builder.query<User[], string>({
      query: (searchTerm) => ({
        url: `/users/search/${searchTerm}`,
      }),
      providesTags: (result, error, arg) =>
        result
          ? result.map((user) => ({ type: 'User' as const, id: user.legacyId }))
          : ['User'],
    }),
    syncUserInfo: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}/sync-info`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
  }),
})

export const {
  useRegisterSignInMutation,
  useDismissNotificationMutation,
  useFetchUserQuery,
  useSearchForUsersQuery,
  useSyncUserInfoMutation: useSyncUserInfo,
} = userSlice
