import { PeriodType } from 'lib/types/metric.types'
import { DateHelper } from 'lib/utils/date.helper'

export const getPeriodConfig = (
  period:
    | 'last_month'
    | 'yesterday'
    | {
        start: Date
        end: Date
      }
) => {
  let start = DateHelper.today
  let end = DateHelper.today

  if (period === 'last_month') {
    start.setMonth(start.getMonth() - 1)
    start.setDate(1)
    end.setDate(0)
  } else if (period === 'yesterday') {
    start.setDate(start.getDate() - 2)
    end.setDate(end.getDate() - 1)
  } else {
    start = period.start
    end = period.end
  }

  let periodType: PeriodType | 'dynamic'
  if (period === 'last_month') {
    periodType = PeriodType.Month
  } else if (period === 'yesterday') {
    periodType = PeriodType.Day
  } else {
    periodType = 'dynamic'
  }

  return { start, end, periodType }
}
