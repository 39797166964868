import { useEffect } from 'react'

import { useUser } from 'hooks/users'

import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { useNavigateWithReplace } from 'components/pages/global/StartPage/StartPage.hooks'

/** Pseudo-page used to redirect the user to the correct page based on their role. */
const StartPage: React.FC = () => {
  const navigate = useNavigateWithReplace()

  const { data: user } = useUser()

  useEffect(() => {
    if (user) {
      const { role, legacyId } = user
      if (role === 'admin') {
        navigate('/admin')
      }
      if (role === 'teamlead') {
        navigate(`/tl/${legacyId}/coworkers`)
      }
      if (role === 'coworker') {
        navigate(`/cw/${legacyId}`)
      }
      if (role === 'guest') {
        navigate('/guest')
      }
    }
  }, [navigate, user])

  return <RedirectingLayout />
}

export default StartPage
