import TextArea from '@ingka/text-area'
import { Box, Stack } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateCoworkerComment } from 'state/slices/api'

import { Paragraph, Subtitle } from 'components/primitives/Text'

import { CoachingSessionEditorStepProps } from '../CoachingSessionEditor.types'

const SAVE_DELAY = 1000

const CoworkerComment: React.FC<CoachingSessionEditorStepProps> = ({
  coworker,
  coachingSession,
}) => {
  const { id: coachingSessionId, coworkerComment: initialCoworkerComment } =
    coachingSession
  const [coworkerComment, setCoworkerComment] = useState(
    initialCoworkerComment ?? ''
  )
  const [debouncedCoworkerComment] = useDebouncedValue(
    coworkerComment,
    SAVE_DELAY
  )
  const [updateCoworkerComment] = useUpdateCoworkerComment()

  const { t } = useTranslation()

  useEffect(() => {
    updateCoworkerComment({
      coworkerId: coworker.legacyId,
      coachingSessionId,
      coworkerComment: debouncedCoworkerComment,
    })
  }, [
    coachingSessionId,
    coworker.legacyId,
    debouncedCoworkerComment,
    updateCoworkerComment,
  ])

  return (
    <Box>
      <Subtitle>
        {t('features.coaching-session.coworker-comment.title')}
      </Subtitle>
      <Stack>
        <Paragraph>
          {t('features.coaching-session.coworker-comment.description', {
            coworker: coworker.firstName,
          })}
        </Paragraph>
        <TextArea
          id="feedback"
          data-testid="feedback"
          onChange={(e) => setCoworkerComment(e.target.value)}
          value={coworkerComment}
          label=""
        />
      </Stack>
    </Box>
  )
}

export default CoworkerComment
