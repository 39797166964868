import Button from '@ingka/button'
import { useTranslation } from 'react-i18next'

import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'

import { useHandleCreateCoachingSessionClicked } from './NoPlannedCoachingSessionPrompt.hooks'
import { NoPlannedCoachingSessionPromptProps } from './NoPlannedCoachingSessionPrompt.types'

const NoPlannedCoachingSessionPrompt: React.FC<
  NoPlannedCoachingSessionPromptProps
> = ({ coworkerId }) => {
  const { t } = useTranslation()

  /** TODO: prompt the user to input a planned date for the session */
  const [
    handleCreateCoachingSessionClicked,
    { isLoading: isLoadingCreateCoachingSession },
  ] = useHandleCreateCoachingSessionClicked(coworkerId)

  return (
    <Widget
      header={
        <WidgetHeader
          title={t('features.coaching-session.no-coaching-session-planned')}
        />
      }
      footer={
        <WidgetFooter>
          <Button
            small
            type="primary"
            text={t(
              'features.coaching-session.actions.create-new-coaching-session'
            )}
            onClick={handleCreateCoachingSessionClicked}
            loading={isLoadingCreateCoachingSession}
          />
        </WidgetFooter>
      }
    />
  )
}

export default NoPlannedCoachingSessionPrompt
