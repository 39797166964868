import Text from '@ingka/text'
import { space50 } from '@ingka/variables'
import { Group } from '@mantine/core'

import * as Styled from './StatusIndicator.styling'
import { StatusIndicatorProps } from './StatusIndicator.types'

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ label, color }) => (
  <Group spacing={space50}>
    <Styled.Dot color={color} />
    <Text>{label}</Text>
  </Group>
)

export default StatusIndicator
