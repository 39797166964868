import { useParams } from 'react-router-dom'

import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

export const useInteractionHref = (interactionId: string) => {
  const pageType = useCurrentPageType()
  const { coworkerPath, teamleadPath } = useSectionPaths()
  const { coworkerId } = useParams()

  if (pageType === 'coworker') {
    return `${coworkerPath}/qa/${interactionId}`
  }

  if (pageType === 'teamlead' && coworkerId) {
    return `${teamleadPath}/coworkers/${coworkerId}/qa/${interactionId}`
  }

  return '/'
}
