import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  isMetricId,
  MetricId,
  metricsConfig,
  OperationalMetricId,
} from 'config/domain/metrics.config'

import { MetricOwnerType, PeriodType } from 'lib/types/metric.types'
import { UserRole } from 'lib/types/user.types'
import { DateHelper } from 'lib/utils/date.helper'
import { FormattingHelper } from 'lib/utils/formatting.helper'

import { useUser } from 'hooks/users'

import { usePreconfiguredMetric } from './usePreconfiguredMetric.hooks'

export const getExclVatMetricId = (
  metricId: OperationalMetricId
): OperationalMetricId => {
  const exclVatMetricId = `${metricId}_excl_vat`

  const exists = metricsConfig[exclVatMetricId as OperationalMetricId]

  if (exists) return exclVatMetricId as OperationalMetricId

  throw Error(`Missing excl vat configuration for: ${metricId}`)
}

export const useVatInfoText = (
  metricId: MetricId,
  ownerType: MetricOwnerType,
  period: { start: Date; end: Date },
  periodType: PeriodType | 'dynamic'
) => {
  const { data: user } = useUser()

  const { t } = useTranslation()

  const metricIncludesVat = isMetricId(metricId)
    ? metricsConfig[metricId].metricBehaviour.includesVat
    : false

  const { data, isSuccess } = usePreconfiguredMetric(
    metricIncludesVat
      ? {
          metricId: getExclVatMetricId(metricId as OperationalMetricId),
          ownerType,
          timeRange: {
            start: DateHelper.dateToString(period.start),
            end: DateHelper.dateToString(period.end),
          },
          periodType,
        }
      : skipToken
  )

  const vatInfoText = React.useMemo(() => {
    let vat: number | undefined

    if (typeof data === 'number') {
      vat = data
    } else if (data != null && Array.isArray(data) && data.length !== 0) {
      vat = data[data.length === 1 ? 0 : 1].value
    }

    if (isSuccess && vat)
      return `${FormattingHelper.formatNumber(vat)} ${t(
        'common.metrics.without-vat'
      )}`

    return undefined
  }, [data, isSuccess, t])

  return vatInfoText
}
