import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Column, useTable } from 'react-table'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useSearchForUsersQuery } from 'state/slices/api'

import { UsersOverviewRowData } from './UsersOverview.types'

const useUsersOverviewColumns = () => {
  const { t } = useTranslation()
  return useMemo<Column<UsersOverviewRowData>[]>(
    () => [
      {
        Header: t('features.user.id'),
        accessor: 'id',
      },
      {
        Header: t('features.user.name'),
        accessor: 'name',
      },
      {
        Header: t('features.user.email'),
        accessor: 'email',
      },
      {
        Header: t('features.user.role'),
        accessor: 'role',
      },
    ],
    [t]
  )
}

export const useUsersOverviewTable = (query: string) => {
  const columns = useUsersOverviewColumns()
  const { data = [] } = useUsersOverviewData(query)
  return useTable({ columns, data })
}

export const useUsersOverviewData = (query: string) => {
  let queryArgs: string | SkipToken = query
  if (query === '') {
    queryArgs = skipToken
  }

  const { data: users, ...meta } = useSearchForUsersQuery(queryArgs)

  const tableData = useMemo<UsersOverviewRowData[] | undefined>(
    () =>
      users?.map((user) => ({
        id: user.legacyId,
        name: user.name,
        email: user.email,
        role: user.role,
      })),
    [users]
  )?.sort((a, b) => a.name.localeCompare(b.name))

  return {
    data: tableData,
    ...meta,
  }
}

export const useHandleUserClicked = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { adminPath } = useSectionPaths()

  return useCallback(
    (row: UsersOverviewRowData) => {
      if (row.role === 'teamlead') {
        navigate(`${adminPath}/tl/${row.id}/coworkers`, {
          state: {
            from: pathname,
          },
        })
      }
      if (row.role === 'coworker') {
        navigate(`${adminPath}/cw/${row.id}`, {
          state: {
            from: pathname,
          },
        })
      }
    },
    [navigate, adminPath, pathname]
  )
}
