import InlineMessage from '@ingka/inline-message'
import { Grid } from '@mantine/core'
import { t } from 'i18next'

import { useMissions } from 'state/slices/api'

import {
  MissionPreview,
  MissionPreviewSkeleton,
} from 'components/composites/Missions/MissionPreview'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { MissionPreviewListProps } from './MissionPreviewList.types'

const SKELETON_COUNT = 4

const MissionPreviewList: React.FC<MissionPreviewListProps> = ({
  coworkerId,
}) => {
  const {
    data: missions,
    isLoading,
    isError,
    isSuccess,
  } = useMissions(coworkerId)

  return (
    <>
      {isLoading && (
        <Grid>
          {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
            <Grid.Col md={6} key={index}>
              <MissionPreviewSkeleton />
            </Grid.Col>
          ))}
        </Grid>
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {(!missions || missions.length === 0) && (
            <InlineMessage
              variant="cautionary"
              title={t('features.mission.warnings.no-data')}
            />
          )}
          {missions && missions.length !== 0 && (
            <Grid>
              {missions.map((mission) => (
                <Grid.Col md={6} key={mission.id}>
                  <MissionPreview mission={mission} />
                </Grid.Col>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default MissionPreviewList
