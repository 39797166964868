import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sheets,
} from '@ingka/modal'
import { Stack } from '@mantine/core'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { Notification as NotificationModel } from 'lib/types/user.types'

import { Notification } from 'components/features/General/NotificationsButton/Notification'
import { Paragraph, Subtitle } from 'components/primitives/Text'

type NotificationsMenuProps = {
  onClose: () => void
  notifications: NotificationModel[]
  show?: boolean
}

const NotificationsMenu: React.FC<NotificationsMenuProps> = ({
  onClose,
  notifications,
  show,
}) => {
  const { t } = useTranslation()

  const sortedNotifications = _.orderBy(
    notifications,
    (notif) => new Date(notif.createdDate),
    'desc'
  ).slice(0, 5)

  return (
    <Modal visible={show} handleCloseBtn={onClose}>
      <Sheets header={<ModalHeader />} footer={<ModalFooter />}>
        <ModalBody>
          <Subtitle>{t('features.notifications.title')}</Subtitle>
          <Stack>
            {sortedNotifications.length === 0 && (
              <Paragraph>{t('features.notifications.warnings.no-notifications')}</Paragraph>
            )}
            {sortedNotifications.map((notification) => (
              <Notification notification={notification} key={notification.id} />
            ))}
          </Stack>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default NotificationsMenu
