import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import InlineMessage from '@ingka/inline-message'
import Select, { Option } from '@ingka/select'
import Switch from '@ingka/switch'
import { Stack } from '@mantine/core'
import { Controller } from 'react-hook-form'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { Widget, WidgetFooter } from 'components/composites/Shared/Widget'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import {
  useManageCountryForm,
  useSelectedCountry,
} from './CountryOverview.hooks'

const CountryOverview: React.FC = () => {
  const [
    { countries, selectedCountry, isLoading, isSuccess, isError },
    { control: countrySelectionControl },
  ] = useSelectedCountry()

  const [
    {
      control,
      reset,
      handleSubmit,
      formState: { isDirty, isValid },
    },
    { isLoading: isUpdatingSettings },
  ] = useManageCountryForm(selectedCountry)

  return (
    <form onSubmit={handleSubmit}>
      <Widget
        footer={
          <WidgetFooter>
            <Button
              type="primary"
              disabled={!isDirty || !isValid || isUpdatingSettings}
              htmlType="submit"
            >
              {'Save changes'}
            </Button>

            <Button
              disabled={!isDirty || isUpdatingSettings}
              onClick={() => reset()}
            >
              {'Reset'}
            </Button>
          </WidgetFooter>
        }
      >
        {isLoading && <LoadingIndicator height="400px" />}
        {isError && <UnexpectedErrorMessage />}
        {isSuccess && (
          <Stack>
            <Controller
              name="selectedCountryId"
              control={countrySelectionControl}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormField>
                  <Select
                    label="Country"
                    id="country"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  >
                    {countries?.map((country) => (
                      <Option
                        key={country.countryCode}
                        value={country.countryCode}
                        label={country.name}
                      />
                    ))}
                  </Select>
                </FormField>
              )}
            />
            {selectedCountry && (
              <Stack>
                <Controller
                  name="isAppEnabled"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormField>
                      <Switch
                        label="Enable INSIKT"
                        id="isAppEnabled"
                        value="isAppEnabled"
                        checked={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    </FormField>
                  )}
                />
              </Stack>
            )}
            {!selectedCountry && (
              <InlineMessage
                variant="informative"
                title="Please select a country from the list."
              />
            )}
          </Stack>
        )}
      </Widget>
    </form>
  )
}

export default CountryOverview
