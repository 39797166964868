import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { DateHelper } from 'lib/utils/date.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useStartCoachingSession } from 'state/slices/api'

export const useHandleStartEarlyClicked = (
  coworkerId: string,
  coachingSessionId: string
) => {
  const { teamleadPath } = useSectionPaths()
  const navigate = useNavigate()

  const [startCoachingSession, meta] = useStartCoachingSession()

  const baseHref = useMemo(
    () =>
      `${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSessionId}`,
    [coachingSessionId, coworkerId, teamleadPath]
  )

  const startCoachingSessionWithNavigate = useCallback(() => {
    startCoachingSession({
      coworkerId,
      coachingSessionId,
      startedDate: DateHelper.dateToString(new Date()),
    }).then(() => {
      navigate(`${baseHref}/start`)
    })
  }, [baseHref, coachingSessionId, coworkerId, navigate, startCoachingSession])

  return [startCoachingSessionWithNavigate, meta] as const
}
