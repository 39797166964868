import { Outlet } from 'react-router-dom'

import { useShowSideBar } from 'state/slices/ui/ui.hooks'
import { setShowSideBar } from 'state/slices/ui/ui.slice'
import { useAppDispatch } from 'state/utilities'

import { NewVersionToast } from 'components/features/General/NewVersionToast'
import { WelcomeModal } from 'components/features/General/WelcomeModal'
import { Sidebar } from 'components/layout/Sidebar'

import * as Styled from './DefaultPageLayout.styling'
import { EnvironmentStickyHeader } from './EnvironmentStickyHeader'

const DefaultPageLayout: React.FC = () => {
  const showSidebar = useShowSideBar()

  const dispatch = useAppDispatch()

  return (
    <Styled.DefaultPageLayout>
      <Sidebar
        show={showSidebar}
        onCloseClicked={() => dispatch(setShowSideBar(false))}
      />
      <Styled.PageContent>
        <EnvironmentStickyHeader />
        <WelcomeModal />
        <NewVersionToast />
        <Styled.MainContent>
          <Outlet />
        </Styled.MainContent>
      </Styled.PageContent>
    </Styled.DefaultPageLayout>
  )
}
export default DefaultPageLayout
