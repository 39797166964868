import { Team } from 'lib/types/team.types'

import { apiSlice } from 'state/slices/api/api.slice'

const teamSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchTeam: builder.query<Team, string>({
      query: (teamId) => `/teams/${teamId}`,
      providesTags: (result, error, arg) =>
        result ? [{ type: 'Team', id: result.id }] : ['Team'],
    }),
  }),
})

export const { useFetchTeamQuery } = teamSlice
