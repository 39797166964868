import { format } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'

type DateFnsLocaleKey = keyof typeof dateFnsLocales

export type Locale = {
  isoCode: string
  name: string
  nameTranslationKey: string
  dateFnsCode: DateFnsLocaleKey
}

export const locales: Record<string, Locale> = {
  'en-XZ': {
    isoCode: 'en-XZ',
    name: 'English (International)',
    nameTranslationKey: 'localisation.english',
    dateFnsCode: 'enGB' as DateFnsLocaleKey,
  },
  'en-CA': {
    isoCode: 'en-CA',
    name: 'English (Canada)',
    nameTranslationKey: 'localisation.english-canada',
    dateFnsCode: 'enCA' as DateFnsLocaleKey,
  },
  'fr-CA': {
    isoCode: 'fr-CA',
    name: 'French (Canada)',
    nameTranslationKey: 'localisation.french-canada',
    dateFnsCode: 'frCA' as DateFnsLocaleKey,
  },
  'da-DK': {
    isoCode: 'da-DK',
    name: 'Danish',
    nameTranslationKey: 'localisation.danish',
    dateFnsCode: 'da' as DateFnsLocaleKey,
  },
  'sv-SE': {
    isoCode: 'sv-SE',
    name: 'Swedish',
    nameTranslationKey: 'localisation.swedish',
    dateFnsCode: 'sv' as DateFnsLocaleKey,
  },
  'pt-PT': {
    isoCode: 'pt-PT',
    name: 'Portuguese',
    nameTranslationKey: 'localisation.portuguese',
    dateFnsCode: 'pt' as DateFnsLocaleKey,
  },
  'es-ES': {
    isoCode: 'es-ES',
    name: 'Spanish',
    nameTranslationKey: 'localisation.spanish',
    dateFnsCode: 'es' as DateFnsLocaleKey,
  },
  'de-CH': {
    isoCode: 'de-CH',
    name: 'German (Switzerland)',
    nameTranslationKey: 'localisation.german-switzerland',
    dateFnsCode: 'deCH' as DateFnsLocaleKey,
  },
  'fr-CH': {
    isoCode: 'fr-CH',
    name: 'French (Switzerland)',
    nameTranslationKey: 'localisation.french-switzerland',
    dateFnsCode: 'frCH' as DateFnsLocaleKey,
  },
  'it-CH': {
    isoCode: 'it-CH',
    name: 'Italian (Switzerland)',
    nameTranslationKey: 'localisation.italian-switzerland',
    dateFnsCode: 'itCH' as DateFnsLocaleKey,
  },
}

export const defaultLocale = locales['en-XZ']

type SupportedLocaleIsoCode = keyof typeof locales
export const isSupportedLocaleIsoCode = (
  code: string
): code is SupportedLocaleIsoCode => Object.keys(locales).includes(code)

export const getDateFnsLocale = (localeCode: DateFnsLocaleKey) =>
  dateFnsLocales[localeCode]
export const formatDate = (date: Date, localeCode: DateFnsLocaleKey) =>
  format(date, 'P', { locale: dateFnsLocales[localeCode] })
