import { PeriodicMetric } from 'lib/types/metric.types'

type StatValueProps = {
  data: number | PeriodicMetric[] | undefined
}

export const useStatValues = ({ data }: StatValueProps) => {
  let thisPeriodValue: number | undefined
  let previousPeriodValue: number | undefined

  if (typeof data === 'number') {
    thisPeriodValue = data
  } else if (data != null && Array.isArray(data) && data.length !== 0) {
    if (data.length === 1) {
      thisPeriodValue = data[0].value
    } else {
      thisPeriodValue = data[1].value
      previousPeriodValue = data[0].value
    }
  }

  const difference =
    previousPeriodValue != null && thisPeriodValue != null
      ? thisPeriodValue - previousPeriodValue
      : null

  return {
    thisPeriodValue,
    difference,
  }
}
