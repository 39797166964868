import { SubHeading } from "components/primitives/Text"
import { useTranslation } from 'react-i18next'

const Summary: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <SubHeading>{t('features.coaching-session.summary.title')}</SubHeading>
    </>
  )
}

export default Summary
