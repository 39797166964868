import Button from '@ingka/button'
import Modal, { ModalFooter } from '@ingka/modal'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from 'hooks/users'

import { useUserPrefsQuery } from 'state/slices/api'
import { useUpdatePrefsMutation } from 'state/slices/api/modules/user/userPrefs.slice'

import CoworkerContentInstructions from './CoworkerContentInstructions.component'
import CoworkerContentIntro from './CoworkerContentIntro.component'
import CoworkerContentRoleSelection from './CoworkerContentRoleSelection.component'
import TeamleadWelcomeModalContent from './TeamleadContent.component'
import * as Styled from './WelcomeModal.styling'

const WelcomeModal = () => {
  const { t } = useTranslation()

  const [updatePrefs] = useUpdatePrefsMutation()
  const [visible, setVisible] = useState(false)

  const [contentIndex, setContentIndex] = useState<number>(0)

  const { data: user } = useUser()
  const { data: prefs, isSuccess } = useUserPrefsQuery()

  useEffect(() => {
    if (prefs && prefs.introduction.showWelcomeModal) {
      setVisible(true)
    }
  }, [prefs])

  if (!isSuccess || !user) {
    return null
  }

  const handleNextClicked = () => {
    if (user.role === 'coworker') {
      if (contentIndex === 2) {
        handleClose()
      } else {
        setContentIndex(contentIndex + 1)
      }
    } else {
      handleClose()
    }
  }

  // If the user is not a co-worker or they are on the last page (2), they can close the modal
  const canClose = user.role !== 'coworker' || contentIndex === 2

  const handleClose = () => {
    // Co-workers *must* complete the welcome modal, as it sets their role
    // Therefore, we do not allow closing the modal unless if they're at the last page
    if (!canClose) {
      return
    }

    const updatedPrefs = {
      ...prefs,
      introduction: {
        ...prefs.introduction,
        showWelcomeModal: false,
      },
    }

    setVisible(false)
    updatePrefs({ userId: user.id, prefs: updatedPrefs })
  }

  const modalTitle =
    user.role === 'coworker'
      ? contentIndex === 1
        ? t('introduction.welcome-modal.coworker-content.select-role-title')
        : contentIndex === 2
        ? t('introduction.welcome-modal.coworker-content.instructions-title')
        : t('introduction.welcome-modal.title')
      : t('introduction.welcome-modal.title')
  return (
    <Modal visible={visible} handleCloseBtn={handleClose}>
      <Styled.Prompt
        title={modalTitle}
        titleId="welcome-modal"
        footer={
          <ModalFooter>
            {/* Don't show the button on the second content page where the co-worker selects their role */}
            {contentIndex !== 1 && (
              <Button
                type="primary"
                iconOnly
                ssrIcon={arrowRight}
                onClick={handleNextClicked}
              />
            )}
          </ModalFooter>
        }
      >
        {user.role === 'teamlead' && <TeamleadWelcomeModalContent />}
        {user.role === 'coworker' && (
          <>
            {contentIndex === 0 && <CoworkerContentIntro />}
            {contentIndex === 1 && (
              <CoworkerContentRoleSelection
                onRoleSelected={() => setContentIndex(2)}
              />
            )}
            {contentIndex === 2 && <CoworkerContentInstructions />}
          </>
        )}
      </Styled.Prompt>
    </Modal>
  )
}

export default WelcomeModal
