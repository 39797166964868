import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const StyledLink = styled(Link as any)`
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`
