import Button from '@ingka/button'
import { LoadingBall as SkapaLoadingBall } from '@ingka/loading'
import {
  breakpointM,
  space50,
  space100,
  space150,
  space200,
  space1350,
} from '@ingka/variables'

import { colourPrimary } from 'styles/tokens/insikt.tokens'
import {
  colourBgAccent,
  colourTextInverse,
} from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

export const Sidebar = styled.nav<{ show: boolean }>`
  @media only screen and (max-width: ${breakpointM}) {
    display: ${(props) => (props.show ? 'flex' : 'none')};
  }

  position: fixed;
  background-color: ${colourBgAccent};
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${space1350};
  max-width: ${space1350};
  z-index: 100;
  padding-top: ${space100};
  padding-bottom: ${space100};
`

type SidebarButtonProps = {
  $isActive?: boolean
  $indentLevel?: 0 | 1 | 2 | 3
}

export const SidebarButton = styled(Button)<SidebarButtonProps>`
  border-radius: 0px;

  &:hover {
    background-color: ${colourPrimary.dark};
  }

  color: ${colourTextInverse};

  background-color: ${(props) =>
    props.$isActive ? colourPrimary.dark : 'inherit'};

  .btn__inner {
    justify-content: flex-start;
    text-align: start;

    margin-left: ${({ $indentLevel }) =>
      $indentLevel === 1
        ? space100
        : $indentLevel === 2
        ? space150
        : $indentLevel === 3
        ? space200
        : space50};
    margin-right: ${space50};
  }
`

SidebarButton.defaultProps = {
  type: 'plain',
}

export const CloseButton = styled(SidebarButton)`
  @media (min-width: ${breakpointM}) {
    display: none;
  }
`
export const LoadingBall = styled(SkapaLoadingBall)`
  margin: 0 auto;
`

export const ExtraMenu = styled.div`
  position: absolute;
  left: ${space1350};
  bottom: 0;
  background-color: ${colourPrimary.dark};
  display: flex;
  flex-direction: column;
`

export const ExtraMenuButton = styled(SidebarButton)`
  .btn__inner {
    justify-content: flex-start;
    white-space: nowrap;
    margin-left: ${space50};
    margin-right: ${space50};
  }

  text-decoration: none;

  &:hover {
    color: ${colourTextInverse};
    text-decoration: underline;
  }
`
