import Button from '@ingka/button'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import Skeleton from '@ingka/skeleton'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSession, useInteractions } from 'state/slices/api'

import { ConditionalLink } from 'components/primitives/ConditionalLink'
import ConditionalTooltip from 'components/primitives/ConditionalTooltip/ConditionalTooltip.component'
import { Paragraph } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useHandleStartEarlyClicked } from './CoachingSessionTeamleadActions.hooks'
import { CoachingSessionTeamleadActionsProps } from './CoachingSessionTeamleadActions.types'

const RECOMMENDED_ASSESSMENT_COUNT = 5

const CoachingSessionTeamleadActions: React.FC<
  CoachingSessionTeamleadActionsProps
> = ({ coworkerId, coachingSessionId }) => {
  const { t } = useTranslation()

  const { pathname } = useLocation()
  const { teamleadPath } = useSectionPaths()

  const {
    data: coachingSession,
    isLoading: isLoadingCoachingSession,
    isSuccess: isSuccessCoachingSession,
    isError: isErrorCoachingSession,
  } = useCoachingSession({ coworkerId, coachingSessionId })
  const {
    data: interactions = [],
    isLoading: isLoadingInteractions,
    isSuccess: isSuccessInteractions,
    isError: isErrorInteractions,
  } = useInteractions(coworkerId)

  const { plannedDate } = coachingSession || {}

  const baseHref = useMemo(
    () =>
      `${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSessionId}`,
    [coworkerId, coachingSessionId, teamleadPath]
  )

  const [
    handleStartEarlyClicked,
    { isLoading: isLoadingStartCoachingSession },
  ] = useHandleStartEarlyClicked(coworkerId, coachingSessionId)

  const [showStartSessionEarlyWarning, setShowStartSessionEarlyWarning] =
    useState(false)
  const sessionIsDue =
    plannedDate && new Date() > DateHelper.getStartOfDate(new Date(plannedDate))

  const preparedInteractions = interactions.filter(
    (interaction) =>
      DateHelper.getDaysBetweenDates(
        new Date(interaction.handledTimestamp),
        new Date()
      ) <= 7
  )

  const coachingSessionIsCompleted =
    coachingSession?.status === CoachingSessionStatus.Completed ||
    coachingSession?.status === CoachingSessionStatus.Signed

  const assessmentsPrepared = preparedInteractions.length

  const coachingSessionIsInProgress =
    coachingSession?.status === CoachingSessionStatus.InProgress

  const coachingSessionIsEarly =
    coachingSession?.status === CoachingSessionStatus.Draft &&
    (!sessionIsDue || assessmentsPrepared < RECOMMENDED_ASSESSMENT_COUNT)

  const isLoading = isLoadingCoachingSession || isLoadingInteractions
  const isSuccess = isSuccessCoachingSession && isSuccessInteractions
  const isError = isErrorInteractions || isErrorCoachingSession

  return (
    <>
      {isLoading && (
        <>
          <Skeleton width="12ch" />
          <Skeleton width="12ch" />
          <Skeleton width="12ch" />
        </>
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          <ConditionalLink
            condition={
              !(coachingSessionIsInProgress || coachingSessionIsCompleted)
            }
            to={`${baseHref}/edit`}
            state={{ from: pathname }}
          >
            <ConditionalTooltip
              condition={
                coachingSessionIsInProgress || coachingSessionIsCompleted
              }
              tooltipText={
                coachingSessionIsInProgress
                  ? t(
                      'features.coaching-session.warnings.cannot-prepare-started-coaching-session'
                    )
                  : t(
                      'features.coaching-session.warnings.cannot-prepare-completed-coaching-session'
                    )
              }
            >
              <Button
                small
                type={sessionIsDue ? 'secondary' : 'primary'}
                text={t(
                  'features.coaching-session.actions.prepare-coaching-session'
                )}
                disabled={
                  coachingSessionIsInProgress || coachingSessionIsCompleted
                }
              />
            </ConditionalTooltip>
          </ConditionalLink>

          {!coachingSessionIsEarly && (
            <ConditionalLink
              condition={!coachingSessionIsCompleted}
              to={`${baseHref}/edit`}
              state={{ from: pathname }}
            >
              <ConditionalTooltip
                condition={coachingSessionIsCompleted}
                tooltipText={t(
                  'features.coaching-session.warnings.cannot-start-completed-coaching-session'
                )}
              >
                <Button
                  small
                  type={sessionIsDue ? 'primary' : 'secondary'}
                  text={
                    coachingSessionIsInProgress
                      ? t(
                          'features.coaching-session.actions.continue-coaching-session'
                        )
                      : t(
                          'features.coaching-session.actions.start-coaching-session'
                        )
                  }
                  disabled={coachingSessionIsCompleted}
                />
              </ConditionalTooltip>
            </ConditionalLink>
          )}

          {coachingSessionIsEarly && (
            <Button
              small
              type={sessionIsDue ? 'primary' : 'secondary'}
              text={t('features.coaching-session.actions.start-session-early')}
              onClick={() => setShowStartSessionEarlyWarning(true)}
              disabled={coachingSessionIsCompleted}
            />
          )}
          <Modal
            visible={showStartSessionEarlyWarning}
            handleCloseBtn={() => setShowStartSessionEarlyWarning(false)}
          >
            <Prompt
              title={t('features.coaching-session.start-session-early.title')}
              titleId="start-session-early-warning-modal"
              header={<ModalHeader />}
              footer={
                <ModalFooter>
                  <Button
                    small
                    type="secondary"
                    text={t(
                      'features.coaching-session.actions.start-session-early'
                    )}
                    onClick={handleStartEarlyClicked}
                    loading={isLoadingStartCoachingSession}
                  />

                  <Button
                    small
                    type="primary"
                    text={t('common.actions.cancel')}
                    onClick={() => setShowStartSessionEarlyWarning(false)}
                  />
                </ModalFooter>
              }
            >
              <Paragraph>
                {t('features.coaching-session.start-session-early.warning')}
              </Paragraph>
            </Prompt>
          </Modal>
        </>
      )}
    </>
  )
}

export default CoachingSessionTeamleadActions
