import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Column } from 'react-table'

import { Coworker, InactiveCoworkerProfile } from 'lib/types/coworker.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useFetchAllCoworkers } from 'state/slices/api/api.hooks'

import { OLDCoworkersOverviewRowData } from './OLDCoworkersOverview.types'

export const useCoworkerOverviewColumns = () => {
  const { t } = useTranslation()
  return useMemo<Column<OLDCoworkersOverviewRowData>[]>(
    () => [
      {
        Header: t('features.coworker.coworkers-overview.name'),
        accessor: 'name',
      },
      {
        Header: t('features.coworker.coworkers-overview.id'),
        accessor: 'id',
      },
    ],
    [t]
  )
}

export const useCoworkerOverviewData = (legacyId: string, query: string) => {
  const { data: coworkers, ...meta } = useFetchAllCoworkers(legacyId)

  const { activeCoworkers = [], inactiveCoworkers = [] } = coworkers ?? {}

  const data = useMemo<OLDCoworkersOverviewRowData[]>(
    () =>
      activeCoworkers
        .map((coworker) => ({
          id: coworker.legacyId,
          name: coworker.fullName,
          active: true,
          coworker: coworker as Coworker | InactiveCoworkerProfile,
        }))
        .concat(
          inactiveCoworkers.map((coworker) => ({
            id: coworker.legacyId,
            name: `${coworker.firstName} ${coworker.lastName}`,
            active: false,
            coworker: coworker,
          }))
        ),
    [activeCoworkers, inactiveCoworkers]
  )
    .filter((row) =>
      query === ''
        ? true
        : row.name.toLowerCase().includes(query.toLowerCase()) ||
          row.id.toLowerCase().includes(query.toLowerCase())
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  return {
    data,
    ...meta,
  }
}

export const useHandleRowClicked = () => {
  const { fullPath } = useSectionPaths()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return useCallback(
    (row: OLDCoworkersOverviewRowData) => {
      navigate(`${fullPath}/coworkers/${row.id}`, {
        state: {
          from: pathname,
        },
      })
    },
    [navigate, fullPath, pathname]
  )
}
