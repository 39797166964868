import { Flex } from "@mantine/core"
import { useFormatDate } from "hooks/formatting"
import { CoachingSession } from "lib/types/coachingSession.types"
import { Coworker, InactiveCoworkerProfile, isCoworker, } from "lib/types/coworker.types"
import { orderBy } from "lodash"

type NextScheduledCoachingProps = {
  coachingSessions?: CoachingSession[]
  coworker: Coworker | InactiveCoworkerProfile
}

const NextScheduledCoaching: React.FC<NextScheduledCoachingProps> = ({ coachingSessions, coworker }) => {
  const isActive = isCoworker(coworker)
  const formatDate = useFormatDate()

  const upcomingCoachingSession = orderBy(coachingSessions?.filter(
    (coachingSession) => coachingSession.status === 'draft' && (coachingSession.plannedDate !== null || coachingSession.plannedDate !== "")),
    'plannedDate',
    'desc').at(0)

  const { plannedDate } = upcomingCoachingSession || {}
  const formattedSessionDate = plannedDate && formatDate(new Date(plannedDate))

  return (
    <>
      <Flex justify="center" align="center">
        {isActive && (
          <b>{formattedSessionDate}</b>
        )}
      </Flex>
    </>
  )
}

export default NextScheduledCoaching
