import { BadgeDefinition } from 'lib/types/badge.types'

import { Widget } from 'components/composites/Shared/Widget'

import { BadgeEdit } from '../BadgeEdit'
import * as Styled from './BadgeGroupEdit.styling'

type BadgeGroupEditProps = {
  badgeGroup: BadgeDefinition[]
}

const BadgeGroupEdit = ({ badgeGroup }: BadgeGroupEditProps) => {
  return (
    <Widget>
      <Styled.Wrapper>
        {badgeGroup.map((badge) => (
          <BadgeEdit key={badge.badgeDefinitionId} badge={badge} />
        ))}
      </Styled.Wrapper>
    </Widget>
  )
}

export default BadgeGroupEdit
