import FormField from '@ingka/form-field'
import InputField from '@ingka/input-field'
import TextArea from '@ingka/text-area'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'

import { Subtitle } from 'components/primitives/Text'

import { AssignMissionFormFields } from '../AssignMissionPage.types'

type Props = {
  form: UseFormReturn<AssignMissionFormFields>
  coworker: Coworker
}

const TitleStep: React.FC<Props> = ({ form, coworker }) => {
  const { t } = useTranslation()
  const { control, formState } = form

  return (
    <>
      <Subtitle>{t('features.mission.assign-mission.steps.title')}</Subtitle>
      <Controller
        name="title"
        control={control}
        rules={{
          required: t(
            'features.mission.assign-mission.fields.name.errors.missing'
          ),
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormField
            data-testid="form-field-title"
            shouldValidate={formState.errors.title !== undefined}
            valid={formState.errors.title == null}
            validation={{
              msg: formState.errors.title?.message,
            }}
          >
            <InputField
              label={t('features.mission.assign-mission.fields.name.title')}
              id="title"
              data-testid="input-field-title"
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          </FormField>
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{
          required: t(
            'features.mission.assign-mission.fields.focus-points.errors.missing'
          ),
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormField
            data-testid="form-field-description"
            fieldHelper={{
              msg: t(
                'features.mission.assign-mission.fields.focus-points.hint',
                {
                  coworker: coworker.firstName,
                }
              ),
            }}
            shouldValidate={formState.errors.description !== undefined}
            valid={formState.errors.description == null}
            validation={{
              msg: formState.errors.description?.message,
            }}
          >
            <TextArea
              label={t(
                'features.mission.assign-mission.fields.focus-points.title'
              )}
              id="description"
              data-testid="input-field-description"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          </FormField>
        )}
      />
    </>
  )
}

export default TitleStep
