import Button from '@ingka/button'
import SSRIcon from '@ingka/ssr-icon'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import star from '@ingka/ssr-icon/paths/star'
import { Grid } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { formatDate } from 'config/internationalisation/locales.config'

import { MetricOwnerType } from 'lib/types/metric.types'
import { Mission, MissionStatus } from 'lib/types/mission.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useMissions } from 'state/slices/api'
import { useLocale } from 'state/slices/api/api.hooks'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { MissionCurrentValue } from 'components/features/Missions/MissionCurrentValue'
import { Caption, Heading, SubHeading } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import * as Styled from './CurrentMissionsPreview.styling'

type CurrentMissionsPreviewProps = {
  coworkerId: string
}

const CurrentMissionsPreview: React.FC<CurrentMissionsPreviewProps> = ({
  coworkerId,
}) => {
  const { t } = useTranslation()
  const { dateFnsCode } = useLocale()
  const { coworkerPath } = useSectionPaths()
  const {
    data: missions,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  } = useMissions(coworkerId)

  const activeMissions = missions?.filter(
    (mission: Mission) => mission.status === MissionStatus.Active
  )

  return (
    <>
      {isLoading && (
        <Widget>
          <LoadingIndicator height="300px" />
        </Widget>
      )}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && !isFetching && (
        <Widget
          header={
            <WidgetHeader
              title={t('features.mission.current-missions-preview.title')}
              ssrIcon={star}
            />
          }
          footer={
            <WidgetFooter>
              <Link to={`${coworkerPath}/missions`}>
                <Button
                  data-testid="btn-to-missions"
                  type="tertiary"
                  small
                  text={t('navigation.links.see-all-missions')}
                  ssrIcon={arrowRight}
                  iconPosition="trailing"
                />
              </Link>
            </WidgetFooter>
          }
        >
          <div>
            {activeMissions?.map((mission: Mission) => (
              <Grid key={mission.id}>
                <Grid.Col lg={5} xs={5}>
                  <Styled.StyledLink
                    to={`${coworkerPath}/missions/${mission.id}`}
                  >
                    <SubHeading>{mission.metricId}</SubHeading>
                  </Styled.StyledLink>
                  <Caption>
                    {formatDate(new Date(mission.endDate), dateFnsCode)}
                  </Caption>
                </Grid.Col>
                <Grid.Col lg={3} xs={3}>
                  <MissionCurrentValue
                    metricId={mission.metricId}
                    id={coworkerId}
                    ownerType={MetricOwnerType.Coworker}
                    idType={'coworker'}
                    period={{
                      start: new Date(mission.startDate),
                      end: new Date(mission.endDate),
                    }}
                  />
                </Grid.Col>
                <Grid.Col lg={2} xs={2}>
                  <SSRIcon paths={arrowRight} style={{ height: '2rem' }} />
                </Grid.Col>
                <Grid.Col lg={2} xs={2}>
                  <Heading>{mission.target}</Heading>
                </Grid.Col>
              </Grid>
            ))}
          </div>
        </Widget>
      )}
    </>
  )
}

export default CurrentMissionsPreview
