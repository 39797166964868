import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Column } from 'react-table'

import { Coworker, InactiveCoworkerProfile } from 'lib/types/coworker.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useFetchAllCoworkers } from 'state/slices/api/api.hooks'

import { CoworkerStatus } from 'components/features/Coworkers/CoworkersOverview/CoworkerStatus'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import * as Styled from './CoworkersOverview.styling'
import { CoworkersOverviewRowData } from './CoworkersOverview.types'
import NextScheduledCoaching from './CoworkerStatus/NextCoachingStatus.component'

export const useCoworkerOverviewColumns = () => {
  const { t } = useTranslation()
  return useMemo<Column<CoworkersOverviewRowData>[]>(
    () => [
      {
        Header: t('features.coworker.coworkers-overview.name'),
        accessor: 'name',
      },
      {
        Header: t('features.coworker.coworkers-overview.id'),
        accessor: 'id',
      },
      {
        Header: () => (
          <Styled.CenteredText>
            {t(
              'features.coworker.coworkers-overview.upcoming-coaching-session-status.title'
            )}
          </Styled.CenteredText>
        ),
        accessor: 'coworker',
        Cell: ({ row, value: coworker }) => (
          <FeatureToggle featureKey="coaching_session">
            <CoworkerStatus coworker={coworker} />
          </FeatureToggle>
        ),
      },
      {
        Header: () => (
          <Styled.CenteredText>
            {t(
              'features.coworker.coworkers-overview.upcoming-coaching-session-status.next-scheduled-session'
            )}
          </Styled.CenteredText>
        ),
        accessor: 'coachingSessions',
        Cell: ({ row, value: coachingSessions }) => (
          <FeatureToggle featureKey="coaching_session">
            <NextScheduledCoaching
              coachingSessions={coachingSessions}
              coworker={row.values.coworker}
            />
          </FeatureToggle>
        ),
      },
    ],
    [t]
  )
}

export const useCoworkerOverviewData = (legacyId: string, query: string) => {
  const { data: coworkers, ...meta } = useFetchAllCoworkers(legacyId)

  const { activeCoworkers = [], inactiveCoworkers = [] } = coworkers ?? {}

  const data = useMemo<CoworkersOverviewRowData[]>(
    () =>
      activeCoworkers
        .map((coworker) => ({
          id: coworker.legacyId,
          name: coworker.fullName,
          active: true,
          coworker: coworker as Coworker | InactiveCoworkerProfile,
          coachingSessions: coworker.coachingSessions,
        }))
        .concat(
          inactiveCoworkers.map((coworker) => ({
            id: coworker.legacyId,
            name: `${coworker.firstName} ${coworker.lastName}`,
            active: false,
            coworker: coworker,
            coachingSessions: undefined,
          }))
        ),
    [activeCoworkers, inactiveCoworkers]
  )
    .filter((row) =>
      query === ''
        ? true
        : row.name.toLowerCase().includes(query.toLowerCase()) ||
          row.id.toLowerCase().includes(query.toLowerCase())
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  return {
    data,
    ...meta,
  }
}

export const useHandleRowClicked = () => {
  const { fullPath } = useSectionPaths()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return useCallback(
    (row: CoworkersOverviewRowData) => {
      navigate(`${fullPath}/coworkers/${row.id}`, {
        state: {
          from: pathname,
        },
      })
    },
    [navigate, fullPath, pathname]
  )
}
