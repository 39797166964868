import Toast from '@ingka/toast'
import {
  Button,
  Divider,
  Flex,
  ModalProps,
  SegmentedControl,
  Text,
  Textarea,
} from '@mantine/core'
import { Modal } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUser } from 'hooks/users'

import { useSendFeedback } from 'state/slices/api'

import { FeedbackFormValues } from './FeedbackModal.types'

type FeedbackType = 'contact' | 'question' | 'feedback'

const ContactModal: React.FC<ModalProps> = (modalProps) => {
  const { t } = useTranslation()
  const { onClose } = modalProps
  const { data: user } = useUser()

  const [
    sendFeedback,
    { isLoading, isSuccess, isError, reset: resetSendFeedback },
  ] = useSendFeedback()

  const [debouncedIsLoading] = useDebouncedValue(isLoading, 500)
  const [showToast, setShowToast] = useState(false)

  const [contactType, setContactType] = useState<FeedbackType>('contact')

  const handleContactTypeChanged = useCallback(
    (value: FeedbackType) => setContactType(value),
    []
  )

  const {
    formState,
    register,
    handleSubmit,
    getValues,
    reset: resetForm,
  } = useForm<FeedbackFormValues>()

  useEffect(() => {
    if (isSuccess) {
      onClose()
      resetForm()
      resetSendFeedback()
    }
  }, [isSuccess, onClose, resetForm, resetSendFeedback])

  useEffect(() => {
    if (isSuccess) {
      setShowToast(true)
      setTimeout(() => setShowToast(false), 5000)
    }
  }, [isError, isSuccess])

  const handleOnContactSubmitted = useCallback(() => {
    if (!user) return
    const { message } = getValues()
    const { legacyId, name, email } = user

    sendFeedback({
      message,
      type: contactType,
      legacyId,
      name,
      email,
    })
  }, [sendFeedback, contactType, getValues, user])

  const translationsRoot = `features.meta.contact-form.${contactType}`

  return (
    <>
      <Toast
        isOpen={showToast}
        text={t('features.meta.contact-form.success')}
        onCloseRequest={() => setShowToast(false)}
      />
      <Modal
        size="lg"
        {...modalProps}
        aria-labelledby="contact"
        closeButtonProps={{
          radius: 'xl',
          size: 'md',
        }}
        title={t(`${translationsRoot}.title`)}
      >
        <form onSubmit={handleSubmit(handleOnContactSubmitted)}>
          <Text c="dimmed">{t(`${translationsRoot}.subtitle`)}</Text>
          <Divider my="sm" />
          <SegmentedControl
            color="primary"
            radius="xl"
            variant="outline"
            mb="md"
            data={[
              { value: 'contact', label: 'Contact' },
              { value: 'question', label: 'Question' },
              { value: 'feedback', label: 'Feedback' },
            ]}
            onChange={handleContactTypeChanged}
          />
          <Textarea
            placeholder={t(`${translationsRoot}.placeholder`)}
            mb="xs"
            minRows={4}
            {...register('message', {
              required: t(`features.meta.contact-form.errors.no-message`),
            })}
            error={formState.errors.message?.message}
          />
          {isError && (
            <Text c="red" size="xs">
              {t('features.meta.contact-form.errors.submission-failed')}
            </Text>
          )}
          <Flex justify="space-between" align="center" gap="md">
            <Text c="dimmed" size="xs">
              {t(`${translationsRoot}.addendum`)}
            </Text>
            {/* We add a slight margin to ensure that the modal doesn't grow
             *  if the addendum has two lines of text */}
            <Button
              radius="xl"
              loading={debouncedIsLoading}
              loaderPosition="center"
              type="submit"
              mt="xs"
            >
              {t(`${translationsRoot}.button-label`)}
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  )
}

export default ContactModal
