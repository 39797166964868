import { useCallback, useState } from 'react'
import Konami from 'react-konami-code'

import * as Styled from './KonamiCodeEasterEgg.styling'

const KonamiCodeEasterEgg: React.FC = () => {
  const [show, setShow] = useState(false)
  const onKonamiEntered = useCallback(() => {
    setShow(true)
  }, [])

  return (
    <>
      <Konami
        timeout={2000}
        action={onKonamiEntered}
        onTimeout={() => setShow(false)}
      />
      {show && <Styled.KonamiImage src="/images/yay-frog.gif" />}
    </>
  )
}

export default KonamiCodeEasterEgg
