import { Grid } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'
import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useCoworker, useMission } from 'state/slices/api'

import { MissionOverview } from 'components/features/Missions/MissionOverview'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

import { MissionActions } from './MissionActions'
import {
  useBackHref,
  useNavigateAwayOnInvalidMission,
} from './MissionPage.hooks'

const MissionPage: React.FC = () => {
  const { t } = useTranslation()
  const pageType = useCurrentPageType()

  const { coworkerId, missionId } = useParams()

  const { isSuccess: isCoworkerSuccess } = useCoworker(coworkerId)
  const { data: mission, isSuccess: isMissionSuccess } = useMission(
    coworkerId && missionId ? { coworkerId, missionId } : skipToken
  )

  useNavigateAwayOnInvalidMission()
  const backHref = useBackHref()

  useValidateParams(['coworkerId', 'missionId'])
  if (!coworkerId || !missionId) return <RedirectingLayout />

  return (
    <div data-testid="page-mission">
      <Header
        backHref={backHref}
        title={mission?.title ?? t('common.loading')}
      />
      {isCoworkerSuccess && isMissionSuccess && (
        <>
          <Grid>
            {pageType === 'teamlead' && (
              <Grid.Col>
                <MissionActions coworkerId={coworkerId} mission={mission} />
              </Grid.Col>
            )}
          </Grid>
          <MissionOverview coworkerId={coworkerId} missionId={missionId} />
        </>
      )}
    </div>
  )
}

export default MissionPage
