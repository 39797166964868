import cross from '@ingka/ssr-icon/paths/cross'
import home from '@ingka/ssr-icon/paths/home'
import questionMarkCircle from '@ingka/ssr-icon/paths/question-mark-circle'
import { Flex } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useUser } from 'hooks/users'

import { FeedbackModal } from 'components/features/Feedback/FeedbackModal'

import CoworkerSidebarContent from './CoworkerSidebarContent.component'
import * as Styled from './Sidebar.styling'
import TeamleadSidebarContent from './TeamleadSidebarContent.component'

type SidebarProps = {
  show?: boolean
  onCloseClicked?: () => void
}

const Sidebar: React.FC<SidebarProps> = ({ show, onCloseClicked }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { adminPath, coworkerPath, teamleadPath } = useSectionPaths()

  const { data: user } = useUser()

  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const shouldDisplay = show === undefined ? true : show

  return (
    <>
      {user && (
        <Styled.Sidebar show={shouldDisplay}>
          <Flex direction="column" w="100%">
            <Styled.CloseButton
              onClick={onCloseClicked}
              iconOnly
              ssrIcon={cross}
            />
            <Styled.SidebarButton
              ssrIcon={home}
              onClick={() => navigate('/')}
              $isActive={
                (user.role === 'teamlead' &&
                  pathname === `${teamleadPath}/coworkers`) ||
                (user.role === 'coworker' && pathname === coworkerPath) ||
                (user.role === 'admin' && pathname === adminPath)
              }
            >
              {t('navigation.sidebar-left.home')}
            </Styled.SidebarButton>
            {user && user.role === 'coworker' && <CoworkerSidebarContent />}
            {user && user.role === 'teamlead' && <TeamleadSidebarContent />}
          </Flex>

          <Styled.SidebarButton
            ssrIcon={questionMarkCircle}
            onClick={() => setShowFeedbackModal(true)}
          >
            {t('navigation.sidebar-left.help')}
          </Styled.SidebarButton>
        </Styled.Sidebar>
      )}
      <FeedbackModal
        opened={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />
    </>
  )
}

export default Sidebar
