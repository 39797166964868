import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import globe from '@ingka/ssr-icon/paths/globe'
import person from '@ingka/ssr-icon/paths/person'
import reward from '@ingka/ssr-icon/paths/reward'
import { Grid } from '@mantine/core'
import { Link } from 'react-router-dom'

import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { Header } from 'components/layout/Header'

const AdminPage = () => {
  return (
    <>
      <Header title="Admin" />
      <Grid>
        <Grid.Col lg={4} md={4}>
          <Widget
            header={<WidgetHeader ssrIcon={person} title="Manage Users" />}
            footer={
              <WidgetFooter>
                <Link to={'/admin/users'}>
                  <Button iconOnly type="tertiary" small ssrIcon={arrowRight} />
                </Link>
              </WidgetFooter>
            }
          />
        </Grid.Col>
        <Grid.Col lg={4} md={4}>
          <Widget
            header={<WidgetHeader ssrIcon={globe} title="Manage Countries" />}
            footer={
              <WidgetFooter>
                <Link to={'/admin/countries'}>
                  <Button iconOnly type="tertiary" small ssrIcon={arrowRight} />
                </Link>
              </WidgetFooter>
            }
          />
        </Grid.Col>

        <Grid.Col lg={4} md={4}>
          <Widget
            header={<WidgetHeader ssrIcon={reward} title="Manage Badges" />}
            footer={
              <WidgetFooter>
                <Link to={'/admin/badges'}>
                  <Button iconOnly type="tertiary" small ssrIcon={arrowRight} />
                </Link>
              </WidgetFooter>
            }
          />
        </Grid.Col>
      </Grid>
    </>
  )
}

export default AdminPage
