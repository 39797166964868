export type CoachingSession = {
  /** Unique identifier for the session */
  id: string
  missions: string[]
  actionPoints: string[]
  coworkerComment?: string
  status: CoachingSessionStatus
  createdBy: string
  createdDate: string
  plannedDate: string
  startedDate?: string
  completedDate?: string
  signedDate?: string
}

export enum CoachingSessionStatus {
  Draft = 'draft',
  InProgress = 'inProgress',
  Completed = 'completed',
  Signed = 'signed',
}

export enum ChangeType {
  Added = 'added',
  Completed = 'completed',
  Closed = 'closed',
  Cancelled = 'cancelled',
}
