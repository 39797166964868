import { useTranslation } from 'react-i18next'

import { DateHelper } from 'lib/utils/date.helper'

export const useValidators = () => {
  const { t } = useTranslation()
  const validateStartDate = (
    startDate: Date | null | [Date | null, Date | null],
    endDate: Date | null | [Date | null, Date | null]
  ) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (!startDate) {
      return t('pages.explore.fields.start-date.validation.required')
    }

    if (startDate > today) {
      return t('pages.explore.fields.start-date.validation.future-date')
    }

    const daysDifference = DateHelper.getDaysBetweenDates(
      startDate as Date,
      endDate as Date
    )

    if (daysDifference < 1) {
      return t('pages.explore.fields.start-date.validation.minimum-duration')
    }

    return true
  }

  const validateEndDate = (
    startDate: Date | null | [Date | null, Date | null],
    endDate: Date | null | [Date | null, Date | null]
  ) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (!startDate) {
      return t('pages.explore.fields.start-date.validation.required')
    }
    if (!endDate) {
      return t('pages.explore.fields.end-date.validation.required')
    }

    if (endDate > today) {
      return t('pages.explore.fields.end-date.validation.future-date')
    }

    return true
  }
  return {
    validateStartDate,
    validateEndDate,
  }
}
