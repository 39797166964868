import { space25 } from '@ingka/variables'
import { Flex } from '@mantine/core'

import { AssignMissionButton } from './AssignMissionButton'
import { MissionsActionsProps } from './MissionsActions.types'

const MissionActions: React.FC<MissionsActionsProps> = ({ coworkerId }) => {
  return (
    <Flex align="center" gap={space25}>
      <AssignMissionButton coworkerId={coworkerId} />
    </Flex>
  )
}

export default MissionActions
