import { Header } from 'components/layout/Header'

const QaFormConfigPage = () => {
  return (
    <>
      <Header title="QA Form Config" />
    </>
  )
}

export default QaFormConfigPage
