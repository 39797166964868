import { useTranslation } from 'react-i18next'
import {
  colourBrandBlue,
  colourBrandYellow,
  colourCampaignSustainability,
  colourNeutralGrey300,
} from 'styles/tokens/oldSkapa.tokens'

import { StatusIndicator } from 'components/composites/Shared/StatusIndicator'
import { CoworkersOverviewStatusProps } from './CoworkersOverviewStatus.types'
import { CoworkerCoachingStatus } from 'lib/types/coworker.types'

const CoworkersOverviewStatus: React.FC<CoworkersOverviewStatusProps> = ({ status }) => {
  const { t } = useTranslation()

  let color, label
  switch (status) {
    case CoworkerCoachingStatus.SessionDraft:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.session-draft')
      color = colourNeutralGrey300
      break
    case CoworkerCoachingStatus.MissingAssessments:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.missing-assessments')
      color = colourBrandYellow
      break
    case CoworkerCoachingStatus.PrepareSession:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.prepare-session')
      color = colourBrandYellow
      break
    case CoworkerCoachingStatus.AwaitingSign:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.awaiting-signature')
      color = colourBrandBlue
      break
    case CoworkerCoachingStatus.SessionReady:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.session-ready')
      color = colourCampaignSustainability

    case CoworkerCoachingStatus.NoActions:
      label = t('features.coworker.coworkers-overview.upcoming-coaching-session-status.no-actions')
      color = colourNeutralGrey300
  }
  return <StatusIndicator label={label} color={color} />
}

export default CoworkersOverviewStatus
