import { OperationalMetricId, operationalMetricIds } from './metrics.config'
import { QaMetricId } from './qa.config'

// Until we know which QA questions should be connected to which metrics,
// we connect all QA questions to all metrics.
export const connectedMetricsMatrix: Record<QaMetricId, OperationalMetricId[]> =
  {
    QARTT: operationalMetricIds,
    QAROP: operationalMetricIds,
    QARSO: operationalMetricIds,
    QAREN: operationalMetricIds,
    QARBI: operationalMetricIds,
    QAR01: operationalMetricIds,
    QAR02: operationalMetricIds,
    QAR03: operationalMetricIds,
    QAR04: operationalMetricIds,
    QAR05: operationalMetricIds,
    QAR06: operationalMetricIds,
    QAR07: operationalMetricIds,
    QAR08: operationalMetricIds,
    QAR09: operationalMetricIds,
    QAR10: operationalMetricIds,
    QAR11: operationalMetricIds,

    QASTT: operationalMetricIds,
    QASOP: operationalMetricIds,
    QASSO: operationalMetricIds,
    QASEN: operationalMetricIds,
    QASBI: operationalMetricIds,
    QAS01: operationalMetricIds,
    QAS02: operationalMetricIds,
    QAS03: operationalMetricIds,
    QAS04: operationalMetricIds,
    QAS05: operationalMetricIds,
    QAS06: operationalMetricIds,
    QAS07: operationalMetricIds,
    QAS08: operationalMetricIds,
    QAS09: operationalMetricIds,
    QAS10: operationalMetricIds,
    QAS11: operationalMetricIds,

    QSATT: operationalMetricIds,
    SAC: operationalMetricIds,
    QSA01: operationalMetricIds,
    QSA02: operationalMetricIds,
    QSA03: operationalMetricIds,
    QSA04: operationalMetricIds,
    QSA05: operationalMetricIds,
    QSA06: operationalMetricIds,
    QSA07: operationalMetricIds,
    QSA08: operationalMetricIds,
    QSA09: operationalMetricIds,
    QSA10: operationalMetricIds,
    QSA11: operationalMetricIds,
    QSA12: operationalMetricIds,
    QSA13: operationalMetricIds,
    QSA14: operationalMetricIds,
  }
