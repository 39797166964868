import Button from '@ingka/button'
import laptopPencil from '@ingka/ssr-icon/paths/laptop-pencil'
import magnifyingGlass from '@ingka/ssr-icon/paths/magnifying-glass'
import pieChart from '@ingka/ssr-icon/paths/pie-chart'
import star from '@ingka/ssr-icon/paths/star'
import { Grid } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { MissionStatus } from 'lib/types/mission.types'

import { useCurrentCoachingSession } from 'hooks/coachingSessions'
import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'
import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useCoworker, useTeamQuery } from 'state/slices/api'
import { useMissionsWrapper } from 'state/slices/api/modules/mision/mission.hooks'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { CoachingSessionHero } from 'components/features/CoachingSessions/CoachingSessionHero'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import StickyFooter from 'components/layout/StickyFooter/StickyFooter.component'
import { MissionsReadyForReviewMessage } from 'components/pages/shared/MissionsPage/ReviewMissionMessage'
import { Paragraph } from 'components/primitives/Text'
import { FeatureToggle } from 'components/shared/FeatureToggle'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

const ManageCoworkerPage: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const { teamleadPath } = useSectionPaths()
  const { coworkerId } = useParams()
  const {
    data: coworker,
    isLoading,
    isSuccess,
    isError,
  } = useCoworker(coworkerId)
  const { data: missions = [] } = useMissionsWrapper(coworkerId ?? skipToken)
  const missionsReadyForReview = missions.filter(
    (mission) => mission.status === MissionStatus.ReadyForReview
  )

  // Pre-fetch team and coaching session
  useTeamQuery(coworker?.teamId ?? skipToken)
  const { data: coachingSession } = useCurrentCoachingSession(
    coworkerId ?? skipToken
  )

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  return (
    <div data-testid="page-manage-coworker">
      <Header
        backHref={`${teamleadPath}/coworkers`}
        title={`${t('pages.manage-coworker.title')} - ${coworker?.fullName}`}
      />
      {isLoading && (
        <LoadingIndicator
          message={t('loading-messages.fetching-coworker-profile')}
        />
      )}
      {(isError || (isSuccess && !coworker)) && <UnexpectedErrorMessage />}
      {isSuccess && coworker && (
        <Grid>
          <FeatureToggle featureKey="coaching_session">
            <Grid.Col>
              <CoachingSessionHero coworkerId={coworkerId} />
            </Grid.Col>
          </FeatureToggle>

          {missionsReadyForReview != null &&
            missionsReadyForReview.length !== 0 && (
              <Grid.Col>
                <MissionsReadyForReviewMessage
                  coworker={coworker}
                  missions={missionsReadyForReview}
                />
              </Grid.Col>
            )}

          <Grid.Col lg={6} md={6}>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={laptopPencil}
                  title={t(
                    'pages.manage-coworker.widgets.quality-assessments.title'
                  )}
                  subtitle={t(
                    'pages.manage-coworker.widgets.quality-assessments.subtitle',
                    { coworker: coworker?.firstName }
                  )}
                />
              }
              footer={
                <WidgetFooter>
                  <Button
                    small
                    type="primary"
                    onClick={() =>
                      navigate(`${teamleadPath}/coworkers/${coworkerId}/qa`, {
                        state: {
                          from: pathname,
                        },
                      })
                    }
                  >
                    {t(
                      'pages.manage-coworker.widgets.quality-assessments.previous-assessments'
                    )}
                  </Button>
                </WidgetFooter>
              }
            />
          </Grid.Col>
          <Grid.Col lg={6} md={6}>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={pieChart}
                  title={t('pages.manage-coworker.widgets.explore.title')}
                  subtitle={t(
                    'pages.manage-coworker.widgets.explore.subtitle',
                    {
                      coworker: coworker.firstName,
                    }
                  )}
                />
              }
              footer={
                <WidgetFooter>
                  <Link to={`${teamleadPath}/coworkers/${coworkerId}/explore`}>
                    <Button
                      small
                      type="primary"
                      text={t('pages.manage-coworker.widgets.explore.see-data')}
                    />
                  </Link>
                </WidgetFooter>
              }
            />
          </Grid.Col>
          <Grid.Col lg={6} md={6}>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={star}
                  title={t('pages.manage-coworker.widgets.see-missions.title', {
                    coworker: coworker.firstName,
                  })}
                  subtitle={t(
                    'pages.manage-coworker.widgets.see-missions.subtitle',
                    { coworker: coworker.firstName }
                  )}
                />
              }
              footer={
                <WidgetFooter>
                  <Link
                    to={`${teamleadPath}/coworkers/${coworker?.legacyId}/missions`}
                  >
                    <Button
                      small
                      type="primary"
                      text={t(
                        'pages.manage-coworker.widgets.see-missions.action'
                      )}
                    />
                  </Link>
                </WidgetFooter>
              }
            />
          </Grid.Col>
          <FeatureToggle featureKey="coaching_session">
            <Grid.Col lg={6} md={6}>
              <Widget
                header={
                  <WidgetHeader
                    ssrIcon={magnifyingGlass}
                    title={t(
                      'pages.manage-coworker.widgets.coaching-sessions.title',
                      { coworker: coworker.firstName }
                    )}
                    subtitle={t(
                      'pages.manage-coworker.widgets.coaching-sessions.subtitle',
                      { coworker: coworker.firstName }
                    )}
                  />
                }
                footer={
                  <WidgetFooter>
                    <Link
                      to={`${teamleadPath}/coworkers/${coworker.legacyId}/coaching-sessions`}
                      state={{ from: pathname }}
                    >
                      <Button
                        data-testid="btn-to-coaching-sessions"
                        type="primary"
                        small
                      >
                        {t(
                          'pages.manage-coworker.widgets.coaching-sessions.action'
                        )}
                      </Button>
                    </Link>
                  </WidgetFooter>
                }
              />
            </Grid.Col>
          </FeatureToggle>
          <Grid.Col lg={6} md={6}>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={magnifyingGlass}
                  title={t(
                    'pages.manage-coworker.widgets.see-dashboard.title',
                    {
                      coworker: coworker.firstName,
                    }
                  )}
                  subtitle={t(
                    'pages.manage-coworker.widgets.see-dashboard.subtitle',
                    { coworker: coworker.firstName }
                  )}
                />
              }
              footer={
                <WidgetFooter>
                  <Button
                    data-testid="btn-see-dashboard"
                    type="primary"
                    small
                    onClick={() =>
                      navigate(`${teamleadPath}/cw/${coworker.legacyId}`, {
                        state: {
                          from: pathname,
                        },
                      })
                    }
                  >
                    {t('pages.manage-coworker.widgets.see-dashboard.action')}
                  </Button>
                </WidgetFooter>
              }
            />
          </Grid.Col>
        </Grid>
      )}

      {/** Sticky coaching session footer*/}
      <FeatureToggle featureKey="coaching_session">
        {coworker && coachingSession && (
          <StickyFooter>
            <Paragraph>
              {t(
                'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.start-guided-flow.title'
              )}
            </Paragraph>
            <Link
              to={`${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSession.id}`}
              state={{ from: pathname }}
            >
              <Button
                small
                type="primary"
                text={t(
                  'pages.manage-coworker.widgets.coaching-sessions.sticky-footer.start-guided-flow.actions'
                )}
              />
            </Link>
          </StickyFooter>
        )}
      </FeatureToggle>
    </div>
  )
}

export default ManageCoworkerPage
