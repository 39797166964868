import Skeleton from '@ingka/skeleton'
import { Stack } from '@mantine/core'

import { getSkeletonWidth } from 'lib/utils/skeletons.helper'

import { Widget } from 'components/composites/Shared/Widget'

const MISSION_DRAFT_SKELETON_COUNT = 2

const MissionDraftSkeleton: React.FC = () => {
  return (
    <Widget>
      <Stack>
        <Skeleton width={getSkeletonWidth(30, 40, 'ch')} />
        {Array.from({ length: MISSION_DRAFT_SKELETON_COUNT }).map(
          (_, index) => (
            <Skeleton width="100%" key={index} />
          )
        )}
      </Stack>
    </Widget>
  )
}

export default MissionDraftSkeleton
