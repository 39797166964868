import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { ActionPoint } from 'lib/types/actionPoint.types'

import { useCoworker } from 'state/slices/api'

// TODO: Temporary version until coaching sessions features are ready
export const useCurrentActionPoints = (
  coworkerId: string | undefined | SkipToken
) => {
  const { data, ...rest } = useCoworker(coworkerId || skipToken)

  return {
    data: [] as ActionPoint[],
    ...rest,
  }
}
