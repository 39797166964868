import { useLocation, useParams } from 'react-router-dom'

import { useUser } from 'hooks/users'

/**
 * @description
 * This hook is used to segment the current location (URL) into sections, allowing us to identify
 * if the user is in the admin, teamlead or coworker section. This allows us to link correctly when
 * a user may be in multiple sections.
 *
 * Be aware that the result returned only returns the root path of the given section, not the full path
 *
 * @example
 * const { currentPath, rootPath, adminPath, teamleadPath, coworkerPath } = useSegmentedLocation()
 * // currentPath: /admin/tl/TILEA/cw/SUPOR/missions
 * // rootPath: /admin
 * // adminPath: /admin
 * // teamleadPath: /admin/tl/TILEA
 * // coworkerPath: /admin/tl/TILEA/cw/SUPOR
 */
export const useSectionPaths = () => {
  const { coworkerId, teamleadId } = useParams()

  const { data: user } = useUser()

  const pathname = useLocation().pathname

  const isAdmin = pathname.includes('admin')
  const isTeamlead = teamleadId != null
  const isCoworker = pathname.includes(`cw/${coworkerId}`)
  const isDemo = pathname.includes('demo')

  let fullPath = ''
  let adminPath = undefined
  let coworkerPath = undefined
  let teamleadPath = undefined
  let demoPath = undefined

  if (isDemo) {
    fullPath = `${fullPath}/demo`
    demoPath = fullPath
  }
  if (isAdmin) {
    fullPath = `${fullPath}/admin`
    adminPath = fullPath
  }
  if (isTeamlead) {
    fullPath = `${fullPath}/tl/${teamleadId}`
    teamleadPath = fullPath
  }
  if (isCoworker) {
    fullPath = `${fullPath}/cw/${coworkerId}`
    coworkerPath = fullPath
  }

  const rootPath =
    user?.role === 'admin'
      ? adminPath
      : user?.role === 'teamlead'
      ? teamleadPath
      : user?.role === 'coworker'
      ? coworkerPath
      : ''

  const sections = {
    fullPath,
    rootPath,
    adminPath,
    teamleadPath,
    coworkerPath,
    demoPath,
  }

  return sections
}

/** Returns the type of the current page */
export const useCurrentPageType = () => {
  const { teamleadPath, coworkerPath, adminPath } = useSectionPaths()
  if (coworkerPath) {
    return 'coworker'
  }
  if (teamleadPath) {
    return 'teamlead'
  }
  if (adminPath) {
    return 'admin'
  }
  return undefined
}
