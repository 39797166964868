import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { useFormatDate } from 'hooks/formatting/useFormatDate.hooks'

import { CoachingSessionPreviewProps } from 'components/composites/CoachingSessions/CoachingSessionPreview/CoachingSessionPreview.types'
import { CoachingSessionStatusIndicator } from 'components/composites/CoachingSessions/CoachingSessionStatusIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'

import { useCoachingSessionHref } from './CoachingSessionPreview.hooks'

const CoachingSessionPreview: React.FC<CoachingSessionPreviewProps> = ({
  coachingSession,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const formatDate = useFormatDate()
  const href = useCoachingSessionHref(coachingSession)

  const { plannedDate, status } = coachingSession

  const formattedPlannedDate = formatDate(new Date(plannedDate))

  return (
    <Widget
      header={
        <WidgetHeader
          title={t('features.coaching-session.session-planned-for', {
            date: formattedPlannedDate,
          })}
          rightSideContent={<CoachingSessionStatusIndicator status={status} />}
        />
      }
      footer={
        <WidgetFooter>
          <Link to={href} state={{ from: pathname }}>
            <Button type="tertiary" small iconOnly ssrIcon={arrowRight} />
          </Link>
        </WidgetFooter>
      }
    />
  )
}

export default CoachingSessionPreview
