import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { CoachingSessionPreviewList } from 'components/features/CoachingSessions/CoachingSessionPreviewList'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

import { useBackHref } from './CoachingSessionsPage.hooks'

const CoachingSessionsPage: React.FC = () => {
  const { t } = useTranslation()

  const { coworkerId } = useParams()

  const backHref = useBackHref()

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  return (
    <div data-testid="page-coaching-sessions">
      <Header
        title={t('features.coaching-session.coaching-sessions')}
        backHref={backHref}
      />
      <CoachingSessionPreviewList coworkerId={coworkerId} />
    </div>
  )
}

export default CoachingSessionsPage
