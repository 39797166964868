import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { useCoachingSession, useMissions } from 'state/slices/api'

export const useMissionsForCoachingSession = (
  args: { coworkerId: string; coachingSessionId: string } | SkipToken
) => {
  const { data: coachingSession, ...metaCoachingSession } =
    useCoachingSession(args)
  const { data: missions, ...metaInteractions } = useMissions(
    args === skipToken ? skipToken : args.coworkerId
  )

  const relatedMissions =
    (coachingSession &&
      missions?.filter((mission) =>
        coachingSession.missions.includes(mission.id)
      )) ??
    []

  const meta = metaCoachingSession.isSuccess
    ? metaInteractions
    : metaCoachingSession

  return {
    ...meta,
    data: relatedMissions,
    currentData: relatedMissions,
  }
}
