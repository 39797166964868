import { Flex } from '@mantine/core'
import { t } from 'i18next'

import { useFormatDate } from 'hooks/formatting'

import { Caption, SubHeading } from 'components/primitives/Text'

import { ActionPointProps } from './ActionPoint.types'

const ActionPoint: React.FC<ActionPointProps> = ({
  actionPoint,
  actionContent,
}) => {
  const formatDate = useFormatDate()

  return (
    <Flex justify="space-between">
      <SubHeading>
        <b>{actionPoint.title}</b>
      </SubHeading>
      {actionPoint.startedDate && (
        <Caption>
          {t('features.action-point.assigned-at', {
            date: formatDate(new Date(actionPoint.startedDate)),
          })}
        </Caption>
      )}

      {actionContent}
    </Flex>
  )
}

export default ActionPoint
