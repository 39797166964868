import { useMemo } from 'react'

import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

export const useViewInQaHref = (coworkerId: string) => {
  const pageType = useCurrentPageType()
  const { teamleadPath, coworkerPath } = useSectionPaths()

  return useMemo(
    () =>
      pageType === 'coworker'
        ? `${coworkerPath}/qa`
        : pageType === 'teamlead'
        ? `${teamleadPath}/coworkers/${coworkerId}/qa`
        : '/',
    [coworkerId, coworkerPath, pageType, teamleadPath]
  )
}
