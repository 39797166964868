import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { Widget } from 'components/composites/Shared/Widget'
import { CoworkersOverview } from 'components/features/Coworkers/CoworkersOverview'
import { OLDCoworkersOverview } from 'components/features/Coworkers/OLDCoworkersOverview'
import { Header } from 'components/layout/Header'
import { FeatureToggle } from 'components/shared/FeatureToggle'

const ManageCoworkersPage: React.FC = () => {
  const { t } = useTranslation()
  const { teamleadId } = useParams()

  const { adminPath } = useSectionPaths()

  const backHref = adminPath ? `${adminPath}/users` : undefined

  return (
    <>
      <div data-testid="page-manage-coworkers">
        <Header backHref={backHref} title={t('features.coworker.title')} />
        <Widget>
          <FeatureToggle
            featureKey="coaching_session"
            fallbackComponent={
              <OLDCoworkersOverview legacyId={teamleadId!} showSearchBar />
            }
          >
            <CoworkersOverview legacyId={teamleadId!} showSearchBar />
          </FeatureToggle>
        </Widget>
      </div>
    </>
  )
}

export default ManageCoworkersPage
