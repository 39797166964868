import InlineMessage from '@ingka/inline-message'
import Switch from '@ingka/switch'
import { Col, Grid } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { useActionPointsForCoachingSession } from 'hooks/coachingSessions'
import { useMissionsForCoachingSession } from 'hooks/coachingSessions/useMissionsForCoachingSession.hooks'

import {
  useExtendActiveActionPoint,
  useExtendActiveMission,
  useRemoveExtendedActiveActionPoint,
  useRemoveExtendedActiveMission,
} from 'state/slices/api/modules/coachingSession'

import ActionPoint from 'components/composites/ActionPoints/ActionPoint/ActionPoint.component'
import { MissionPreview } from 'components/composites/Missions/MissionPreview'
import { Widget, WidgetSkeleton } from 'components/composites/Shared/Widget'
import { SubHeading } from 'components/primitives/Text'

import { MissionDraftSkeleton } from '../../CoachingSessionMissionDraft/MissionDraftSkeleton'
import { PlanReviewProps } from './PlanReview.types'

const ReviewPlan: React.FC<PlanReviewProps> = ({
  coworkerId,
  coachingSession,
}) => {
  const { t } = useTranslation()

  const {
    data: sessionMissions,
    isLoading: loadingMissions,
    isError: errorMissions,
    isSuccess: successMissions,
  } = useMissionsForCoachingSession({
    coworkerId,
    coachingSessionId: coachingSession.id,
  })

  const {
    data: sessionActionPoints,
    isLoading: loadingActionPoints,
    isError: errorActionPoints,
    isSuccess: successActionPoints,
  } = useActionPointsForCoachingSession({
    coworkerId,
    coachingSessionId: coachingSession.id,
  })

  const [extendMission] = useExtendActiveMission()
  const [removeExtendedMission] = useRemoveExtendedActiveMission()

  const [extendActionPoint] = useExtendActiveActionPoint()
  const [removeExtendedActionPoint] = useRemoveExtendedActiveActionPoint()

  const onMissionSwitchToggle = (args: React.BaseSyntheticEvent) => {
    const value = args.target.value
    const checked = args.target.checked

    if (checked)
      extendMission({
        coworkerId,
        coachingSessionId: coachingSession.id,
        missionId: value,
      })
    else
      removeExtendedMission({
        coworkerId,
        coachingSessionId: coachingSession.id,
        missionId: value,
      })
  }

  const onActionPointSwitchToggle = (args: React.BaseSyntheticEvent) => {
    const value = args.target.value
    const checked = args.target.checked

    if (checked)
      extendActionPoint({
        coworkerId,
        coachingSessionId: coachingSession.id,
        actionPointId: value,
      })
    else
      removeExtendedActionPoint({
        coworkerId,
        coachingSessionId: coachingSession.id,
        actionPointId: value,
      })
  }
  return (
    <>
      <SubHeading>
        {t('features.coaching-session.review-plan.missions')}
      </SubHeading>

      {loadingMissions && (
        <>
          <MissionDraftSkeleton />
        </>
      )}

      {errorMissions && (
        <>
          <InlineMessage
            title={t(
              'features.coaching-session.review-plan.errors.missions-fetch'
            )}
          />
        </>
      )}

      {successMissions && (
        <Grid>
          {sessionMissions?.map((mission) => (
            <Col span={6}>
              <MissionPreview
                mission={mission}
                headerContentRight={
                  <Switch
                    id={mission.id}
                    value={mission.id}
                    label={t('common.actions.extend')}
                    onChange={onMissionSwitchToggle}
                    checked={coachingSession.missions.includes(mission.id)}
                  />
                }
              />
            </Col>
          ))}
        </Grid>
      )}

      <SubHeading>
        {t('features.coaching-session.review-plan.action-points')}
      </SubHeading>

      {loadingActionPoints && <WidgetSkeleton />}

      {errorActionPoints && (
        <InlineMessage
          title={t(
            'features.coaching-session.review-plan.errors.action-points-fetch'
          )}
        />
      )}

      {successActionPoints &&
        sessionActionPoints?.map((actionPoint) => (
          <Widget>
            <ActionPoint
              key={actionPoint.id}
              actionPoint={actionPoint}
              actionContent={
                <Switch
                  id={actionPoint.id}
                  value={actionPoint.id}
                  label={t('common.actions.extend')}
                  onChange={onActionPointSwitchToggle}
                  checked={coachingSession.actionPoints.includes(
                    actionPoint.id
                  )}
                />
              }
            />
          </Widget>
        ))}
    </>
  )
}

export default ReviewPlan
