export const colourPrimary = {
  main: '#0058a3',
  light: '#1A72BD',
  veryLight: '#338BD6',
  dark: '#003F8A',
  veryDark: '#002570',
  text: '#fff',
}

export const colourSecondary = {
  main: '#ffdb00',
  light: '#ffe433',
  veryLight: '#ffeb66',
  dark: '#e6c700',
  veryDark: '#ccb100',
  text: '#fff',
}

/** 500ms */
export const debounceTimeDefault = 500

/** #F5F5F5 */
export const backgroundColourDefault = '#F5F5F5'

/** #FFF */
export const backgroundColourWhite = '#FFF'

export const shadowDefaultSize = '0px 2px 4px rgba(0,0,0,.1)'
export const shadowRaisedSize = '0px 4px 8px rgba(0,0,0,.2)'

/** Colour used to represent a co-worker */
export const coworkerColour = 'rgb(112, 164, 140)'

/** Colour used to represent a team */
export const teamColour = 'rgb(57, 88, 113)'

/* Colour used to represent a target */
export const targetColour = 'rgb(168, 79, 79)'

/** Colour used to represent a comparison */
export const comparisonColour = 'rgb(204, 188, 69)'
