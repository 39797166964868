import {
  breakpointM,
  breakpointXl,
  space75,
  space150,
  space200,
  space1350,
} from '@ingka/variables'

import { backgroundColourDefault } from 'styles/tokens/insikt.tokens'

import styled from 'styled-components'

export const MainContent = styled.main`
  max-width: ${breakpointXl};
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 ${space75};

  @media (min-width: ${breakpointM}) {
    padding: 0 ${space150};
  }
`

export const DefaultPageLayout = styled.div`
  display: flex;
`

/** Add padding-left 150px to compensate for the width of the sidebar which uses fixed positioning */
export const PageContent = styled.div`
  background-color: ${backgroundColourDefault};
  width: 100%;
  padding-bottom: ${space200};
  @media (min-width: ${breakpointM}) {
    padding-left: ${space1350};
  }
`
