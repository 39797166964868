import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'

import { useUser } from 'hooks/users'

import routes, { RouteConfig } from 'routes/routes'

import { AdminLayout, CoworkerLayout, TeamleadLayout } from 'components/layout'
import { DefaultPageLayout } from 'components/layout/DefaultPageLayout'
import { NotFoundPage } from 'components/pages/global/NotFoundPage'
import { StartPage } from 'components/pages/global/StartPage'
import { EasterEggs } from 'components/shared/EasterEggs'
import { ErrorBoundary } from 'components/shared/ErrorBoundary'

const Routing = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const { data: user } = useUser()

  // Whenever the path changes, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const PageContent = ({ route }: { route: RouteConfig }) => (
    <>
      <Helmet>
        <title>{`${t(route.nameTranslationKey)} - INSIKT`}</title>
      </Helmet>

      <ErrorBoundary>
        <route.component />
      </ErrorBoundary>
      <EasterEggs />
    </>
  )

  const buildRoute = (route: RouteConfig) => (
    <Route
      key={route.path}
      path={route.path}
      element={<PageContent route={route} />}
    />
  )

  const { cwRoutes, tlRoutes, adminRoutes } = routes
  const preparedCwRoutes = cwRoutes.map(buildRoute)
  const preparedTlRoutes = tlRoutes.map(buildRoute)
  const preparedAdminRoutes = adminRoutes.map(buildRoute)
  const preparedGlobalRoutes = routes.globalRoutes.map(buildRoute)

  return (
    <Routes>
      <Route path="/" element={<DefaultPageLayout />}>
        {preparedGlobalRoutes}
        {user?.role === 'coworker' && (
          <Route path="cw/:coworkerId" element={<CoworkerLayout />}>
            {preparedCwRoutes}
          </Route>
        )}
        {user?.role === 'teamlead' && (
          <Route path="tl/:teamleadId" element={<TeamleadLayout />}>
            <Route path="cw/:coworkerId" element={<CoworkerLayout />}>
              {preparedCwRoutes}
            </Route>
            {preparedTlRoutes}
            <Route path="" element={<StartPage />} />
          </Route>
        )}
        {user?.role === 'admin' && (
          <Route path="admin" element={<AdminLayout />}>
            {preparedAdminRoutes}
            <Route path="cw/:coworkerId" element={<CoworkerLayout />}>
              {preparedCwRoutes}
            </Route>
            <Route path="tl/:teamleadId" element={<TeamleadLayout />}>
              <Route path="cw/:coworkerId" element={<CoworkerLayout />}>
                {preparedCwRoutes}
              </Route>
              {preparedTlRoutes}
              <Route path="" element={<StartPage />} />
            </Route>
          </Route>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
export default Routing
