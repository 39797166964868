import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { ActionPoint } from 'lib/types/actionPoint.types'

import { useUser } from 'hooks/users'

import { useCoworker } from '../../api.hooks'
import { useActionPoints } from './actionPoint.slice'

export const useActionPointsWrapper = (coworkerId: string | SkipToken) => {
  const { data: user } = useUser()
  const { role } = user ?? {}

  const isTl = role === 'teamlead'

  const regularQuery = useActionPoints(isTl ? skipToken : coworkerId)
  const teamleadQuery = useCoworker(!isTl ? skipToken : coworkerId)

  if (isTl) {
    const { data, ...coworkerMeta } = teamleadQuery
    const actionPoints = (data as any)?.actionPoints as
      | ActionPoint[]
      | undefined

    return {
      ...coworkerMeta,
      data: actionPoints,
      currentData: actionPoints,
    }
  }

  return regularQuery
}
