import dataChart from '@ingka/ssr-icon/paths/data-chart'
import laptopPencil from '@ingka/ssr-icon/paths/laptop-pencil'
import reward from '@ingka/ssr-icon/paths/reward'
import star from '@ingka/ssr-icon/paths/star'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import * as Styled from './Sidebar.styling'

const CoworkerSidebarContent: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { coworkerPath } = useSectionPaths()

  return (
    <>
      <Styled.SidebarButton
        $isActive={pathname.startsWith(`${coworkerPath}/missions`)}
        ssrIcon={star}
        onClick={() => navigate(`${coworkerPath}/missions`)}
      >
        {t('pages.missions.title')}
      </Styled.SidebarButton>
      <Styled.SidebarButton
        $isActive={pathname.startsWith(`${coworkerPath}/explore`)}
        ssrIcon={dataChart}
        onClick={() => navigate(`${coworkerPath}/explore`)}
      >
        {t('pages.explore.title')}
      </Styled.SidebarButton>
      <Styled.SidebarButton
        $isActive={pathname.startsWith(`${coworkerPath}/badges`)}
        ssrIcon={reward}
        onClick={() => navigate(`${coworkerPath}/badges`)}
      >
        {t('pages.badges.title')}
      </Styled.SidebarButton>
      <Styled.SidebarButton
        $isActive={pathname.startsWith(`${coworkerPath}/qa`)}
        ssrIcon={laptopPencil}
        onClick={() => navigate(`${coworkerPath}/qa`)}
      >
        {t('pages.qa.title')}
      </Styled.SidebarButton>
    </>
  )
}

export default CoworkerSidebarContent
