import { endOfYesterday } from 'date-fns'
import { useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import {
  isMetricId,
  MetricId,
  metricsConfig,
} from 'config/domain/metrics.config'

import { DateHelper } from 'lib/utils/date.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useValidators } from './ExplorePage.validation'

export const useBackHref = () => {
  const { coworkerId } = useParams()
  const { coworkerPath, teamleadPath } = useSectionPaths()

  return coworkerPath || `${teamleadPath}/coworkers/${coworkerId}`
}

export const useSelectedMetric = (
  defaultMetricType: string = 'metrics',
  defaultMetric: MetricId = 'average_csat'
) => {
  const [searchParams] = useSearchParams()
  const paramsMetricName = searchParams.get('metric')

  const initialMetric =
    paramsMetricName && isMetricId(paramsMetricName)
      ? paramsMetricName
      : defaultMetric

  const [metricName, setMetricName] = useState<MetricId>(initialMetric)
  const [metricType, setMetricType] = useState(defaultMetricType)

  const handleMetricNameChanged = (newMetric: MetricId) =>
    setMetricName(newMetric)

  const handleMetricTypeChanged = (newMetricType: string) => {
    setMetricType(newMetricType)

    const defaultMetric =
      newMetricType === 'metrics'
        ? 'average_csat'
        : newMetricType === 'QAR'
        ? 'QARTT'
        : newMetricType === 'QAS'
        ? 'QASTT'
        : 'QSATT'

    handleMetricNameChanged(defaultMetric)
  }

  return {
    metricName,
    metricType,
    handleMetricNameChanged,
    handleMetricTypeChanged,
  }
}

export const useSelectedDateRange = (initialDateRange: number = 30) => {
  const [searchParams] = useSearchParams()

  const paramsSearchDate = searchParams.get('startDate')
  const paramsEndDate = searchParams.get('endDate')

  const initialStartDate =
    paramsSearchDate && DateHelper.isDateString(paramsSearchDate)
      ? new Date(paramsSearchDate)
      : DateHelper.subtractDays(endOfYesterday(), initialDateRange)
  const initialEndDate =
    paramsEndDate && DateHelper.isDateString(paramsEndDate)
      ? new Date(paramsEndDate)
      : endOfYesterday()

  const { validateStartDate, validateEndDate } = useValidators()
  const [selectedStartDate, setSelectedStartDate] = useState(initialStartDate)
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate)
  const [startDate, setStartDate] = useState(selectedStartDate)
  const [endDate, setEndDate] = useState(selectedEndDate)
  const [startDateError, setStartDateErrors] = useState<string>()
  const [endDateError, setEndDateErrors] = useState<string>()

  const handleStartDateChanged = (
    date: Date | null | [Date | null, Date | null]
  ) => {
    setSelectedStartDate(date as Date)

    const startDateError = validateStartDate(date, selectedEndDate)
    const endDateError = validateEndDate(date, selectedEndDate)

    if (typeof startDateError === 'string') {
      setStartDateErrors(startDateError)
    }
    if (typeof endDateError === 'string') {
      setEndDateErrors(endDateError)
    }
    if (startDateError === true && endDateError === true) {
      setStartDateErrors(undefined)
      setEndDateErrors(undefined)
      setStartDate(date as Date)
    }
  }

  const handleEndDateChanged = (
    date: Date | null | [Date | null, Date | null]
  ) => {
    setSelectedEndDate(date as Date)

    const startDateError = validateStartDate(selectedStartDate, date)
    const endDateError = validateEndDate(selectedStartDate, date)

    if (typeof startDateError === 'string') {
      setStartDateErrors(startDateError)
    }
    if (typeof endDateError === 'string') {
      setEndDateErrors(endDateError)
    }
    if (startDateError === true && endDateError === true) {
      setStartDateErrors(undefined)
      setEndDateErrors(undefined)
      setEndDate(date as Date)
    }
  }

  return {
    selectedStartDate,
    selectedEndDate,
    startDateError,
    endDateError,
    startDate,
    endDate,
    handleStartDateChanged,
    handleEndDateChanged,
  }
}

export const useMetricBehaviour = (metricName: MetricId) => {
  return (
    (metricName &&
      isMetricId(metricName) &&
      metricsConfig[metricName]?.metricBehaviour) ||
    undefined
  )
}

export const useAllowedMetrics = () =>
  useMemo(
    () =>
      Object.values(metricsConfig).filter(
        (metric) => metric.allowedUses.explore
      ),
    []
  )
