import { ActionPoint } from 'lib/types/actionPoint.types'

import { apiSlice } from 'state/slices/api/api.slice'

const missionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findActionPointByIdAndCoworkerId: builder.query<
      ActionPoint,
      { coworkerId: string; missionId: string }
    >({
      query: ({ coworkerId, missionId }) =>
        `/coworkers/${coworkerId}/actionPoints/${missionId}`,
      providesTags: ['Action Point'],
    }),
    findActionPointsByCoworkerId: builder.query<ActionPoint[], string>({
      query: (coworkerId) => `/coworkers/${coworkerId}/actionPoints`,
      providesTags: ['Action Point'],
    }),
  }),
})

export const {
  useFindActionPointByIdAndCoworkerIdQuery: useActionPoint,
  useFindActionPointsByCoworkerIdQuery: useActionPoints,
} = missionSlice
