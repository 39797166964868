import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigateWithReplace = () => {
  const navigate = useNavigate()
  return useCallback(
    (path: string) => navigate(path, { replace: true }),
    [navigate]
  )
}
