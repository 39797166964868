import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Mission } from 'lib/types/mission.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCloseMissionMutation } from 'state/slices/api'

export const useCloseMission = (coworkerId: string, mission: Mission) => {
  const navigate = useNavigate()
  const { teamleadPath } = useSectionPaths()

  const [closeMission, meta] = useCloseMissionMutation()

  const { isSuccess } = meta
  const handleCloseMission = (outcome: 'completed' | 'cancelled') => {
    closeMission({ coworkerId, missionId: mission.id, outcome })
  }

  useEffect(() => {
    // If the mission was cancelled successfully, navigate back to missions
    if (isSuccess) {
      navigate(`${teamleadPath}/coworkers/${coworkerId}/missions`)
    }
  }, [isSuccess, navigate, teamleadPath, coworkerId])

  return [handleCloseMission, meta] as const
}
