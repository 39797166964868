import { AssignMissionInput, Mission } from 'lib/types/mission.types'

import { apiSlice } from 'state/slices/api/api.slice'

type AssignMissionParams = {
  coworkerId: string
  missionInput: AssignMissionInput
}

type ExtendMissionParams = {
  coworkerId: string
  missionId: string
  extendedOn: string
  extendedTo: string
}

type CloseMissionParams = {
  coworkerId: string
  missionId: string
  outcome: 'completed' | 'cancelled'
}

const missionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findMissionByIdAndCoworkerId: builder.query<
      Mission,
      { coworkerId: string; missionId: string }
    >({
      query: ({ coworkerId, missionId }) =>
        `/coworkers/${coworkerId}/missions/${missionId}`,
      providesTags: ['Mission'],
    }),
    findMissionsByCoworkerId: builder.query<Mission[], string>({
      query: (coworkerId) => `/coworkers/${coworkerId}/missions`,
      providesTags: ['Mission'],
    }),

    _assignMissionOLD: builder.mutation<
      {
        missionId: string
      },
      AssignMissionParams
    >({
      query: ({ coworkerId, missionInput }) => ({
        url: `/coworkers/${coworkerId}/missions`,
        method: 'POST',
        body: missionInput,
      }),
      invalidatesTags: (result, error, arg) => ['Mission'],
    }),
    startMission: builder.mutation<
      void,
      { coworkerId: string; missionId: string }
    >({
      query: ({ coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}/start`,
        method: 'POST',
      }),
    }),
    extendMission: builder.mutation<Mission, ExtendMissionParams>({
      query: ({ coworkerId, missionId, extendedOn, extendedTo }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}/extend`,
        method: 'POST',
        body: { extendedOn, extendedTo },
      }),
      invalidatesTags: ['Mission'],
    }),
    closeMission: builder.mutation<void, CloseMissionParams>({
      query: ({ coworkerId, missionId, outcome }) => ({
        url: `/coworkers/${coworkerId}/missions/${missionId}/close`,
        body: { outcome },
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => ['Mission'],
    }),
  }),
})

export const {
  useFindMissionByIdAndCoworkerIdQuery: useMission,
  useFindMissionsByCoworkerIdQuery: useMissions,
  use_assignMissionOLDMutation,
  useCloseMissionMutation,
  useExtendMissionMutation,
} = missionSlice
