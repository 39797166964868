import Button from '@ingka/button'
import InlineMessage from '@ingka/inline-message'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import { space100 } from '@ingka/variables'
import { Flex, Grid } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { MetricOwnerType } from 'lib/types/metric.types'

import { useFormatDate } from 'hooks/formatting/useFormatDate.hooks'

import { useCoworker, useMission, useTeamQuery } from 'state/slices/api'

import { MissionStatusIndicator } from 'components/composites/Missions/MissionStatusIndicator'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { Stat } from 'components/features/Metrics/Stat'
import { MissionChart } from 'components/features/Missions/MissionChart'
import { useCoworkerLabel } from 'components/pages/shared/shared.hooks'
import { Caption, Paragraph, SubHeading } from 'components/primitives/Text'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useViewInQaHref } from './MissionOverview.hooks'
import { MissionOverviewProps } from './MissionOverview.types'

const MissionOverview: React.FC<MissionOverviewProps> = ({
  coworkerId,
  missionId,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const formatDate = useFormatDate()

  const {
    data: coworker,
    isLoading: isLoadingCoworker,
    isSuccess: isSuccessCoworker,
    isError: isErrorCoworker,
  } = useCoworker(coworkerId)
  const {
    data: mission,
    isLoading: isLoadingMissions,
    isSuccess: isMissionSucess,
    isError: isErrorMissions,
  } = useMission({ coworkerId, missionId })

  const coworkerLabel = useCoworkerLabel(coworker)
  const {
    data: team,
    isLoading: isLoadingTeam,
    isSuccess: isTeamSuccess,
    isError: isErrorTeam,
  } = useTeamQuery(coworker?.teamId)

  const viewInQaHref = useViewInQaHref(coworkerId)

  const isLoading = isLoadingCoworker || isLoadingMissions || isLoadingTeam
  const isError = isErrorCoworker || isErrorMissions || isErrorTeam
  const isSuccess = isSuccessCoworker && isMissionSucess && isTeamSuccess

  return (
    <>
      {/** Loading State */}
      {isLoading && <LoadingIndicator height="500px" />}
      {/** Error State */}
      {isError && <UnexpectedErrorMessage />}
      {/** Success State */}
      {isSuccess && (
        <>
          {!coworker && (
            <InlineMessage body={t('features.coworker.errors.not-found')} />
          )}
          {!mission && (
            <InlineMessage body={t('features.mission.errors.not-found')} />
          )}
          {!team && (
            <InlineMessage body={t('features.team.errors.not-found')} />
          )}
          {mission && coworker && team && (
            <Grid>
              <Grid.Col>
                <Caption>
                  {formatDate(new Date(mission.startDate))}
                  {' - '}
                  {formatDate(new Date(mission.endDate))}
                </Caption>

                <SubHeading>{t('features.mission.action-plan')}</SubHeading>
                {mission.description}
                <SubHeading>{t('pages.mission.target.title')}</SubHeading>
                <Paragraph>
                  {t('pages.mission.target.body', {
                    metric: mission.metricId,
                    target: mission.target,
                  })}
                </Paragraph>
              </Grid.Col>

              <Grid.Col>
                <Widget
                  header={
                    <WidgetHeader
                      title={t('features.mission.progress.title')}
                      subtitle={t('features.mission.progress.subtitle')}
                      rightSideContent={
                        <MissionStatusIndicator
                          status={
                            mission.result
                              ? mission.result.outcome
                              : mission.status
                          }
                        />
                      }
                    />
                  }
                  footer={
                    <WidgetFooter justify="flex-start">
                      <Link to={viewInQaHref} state={{ from: pathname }}>
                        <Button
                          type="tertiary"
                          small
                          text={t('navigation.links.view-in-qa')}
                          ssrIcon={arrowRight}
                        />
                      </Link>
                    </WidgetFooter>
                  }
                >
                  <Flex gap={space100}>
                    <Stat
                      title={coworkerLabel}
                      metricId={mission.metricId}
                      id={coworker.legacyId}
                      ownerType={MetricOwnerType.Coworker}
                      period={{
                        start: new Date(mission.startDate),
                        end: new Date(mission.endDate),
                      }}
                    />
                    <Stat
                      title={team.name}
                      metricId={mission.metricId}
                      id={coworker.teamId}
                      ownerType={MetricOwnerType.Team}
                      period={{
                        start: new Date(mission.startDate),
                        end: new Date(mission.endDate),
                      }}
                    />
                  </Flex>
                  <MissionChart
                    mission={mission}
                    coworkerId={coworker.legacyId}
                    teamId={coworker.teamId}
                  />
                </Widget>
              </Grid.Col>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default MissionOverview
