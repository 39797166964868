import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

import { useMission } from 'state/slices/api'

export const useBackHref = () => {
  const pageType = useCurrentPageType()
  const { teamleadPath, coworkerPath } = useSectionPaths()
  const { coworkerId } = useParams()

  const defaultPath =
    pageType === 'coworker'
      ? `${coworkerPath}/missions`
      : pageType === 'teamlead'
      ? `${teamleadPath}/coworkers/${coworkerId}/missions`
      : undefined

  return useDefaultBackHref(defaultPath)
}

export const useNavigateAwayOnInvalidMission = () => {
  const navigate = useNavigate()
  const { coworkerId, missionId } = useParams()
  const { data: mission, isSuccess } = useMission(
    missionId && coworkerId
      ? {
          coworkerId,
          missionId,
        }
      : skipToken
  )

  useEffect(() => {
    if (isSuccess && mission == null) {
      navigate('/')
    }
  })
}
