import Text from '@ingka/text'
import {
  durationStandardWeb,
  easeEnter,
  radiusCap,
  radiusS,
  shadowSizeDefault,
  space50,
  space75,
  space100,
  space150,
  thicknessThick,
} from '@ingka/variables'

import {
  backgroundColourWhite,
  colourPrimary,
} from 'styles/tokens/insikt.tokens'
import { colourBgInverse } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

type OuterWrapperProps = {
  colored?: boolean
}

export const BadgeLine = styled.div<OuterWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${space100};
  border: ${thicknessThick} solid ${colourPrimary.main};
  border-radius: 50px;
  padding-right: ${space150};
  background-color: ${backgroundColourWhite};
  ${(props) =>
    props.colored
      ? ``
      : `
    filter: grayscale(1);
    opacity: 0.4;
    background-color: '';
    `}
  &:hover {
    ${(props) => (props.colored ? `box-shadow: ${shadowSizeDefault}` : '')};
  }
`

export const Tooltip = styled.div`
  position: absolute;
  background-color: ${colourBgInverse};
  border-radius: ${radiusS};
  padding: ${space50} ${space75};
  transform: translateY(calc(-100% - ${space75})) translateX(-50%);
  top: 0;
  left: 50%;
  max-width: 300px;
  box-sizing: content-box;
  width: max-content;
`

type ExpandableBadgesProps = {
  show?: boolean
}

export const ExpandableBadges = styled.div<ExpandableBadgesProps>`
  display: flex;
  flex-direction: column;
  gap: ${space100};
  margin-left: ${space150};
  margin-bottom: ${space150};
  ${(props) => (props.show ? '' : 'display: none;')}

  transition: all ${easeEnter} ${durationStandardWeb};
`

export const ExpandableBadgeIconImg = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: ${radiusCap};
  z-index: 1;
  position: relative;
`

export const DescriptionText = styled(Text)<{ $awarded: boolean }>`
  font-style: ${({ $awarded }) => ($awarded ? 'italic' : 'normal')};
`
