import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCreateCoachingSession } from 'state/slices/api/modules/coachingSession/coachingSession.slice'

export const useHandleCreateCoachingSessionClicked = (coworkerId: string) => {
  const [createCoachingSession, meta] = useCreateCoachingSession()
  const { teamleadPath } = useSectionPaths()

  const navigate = useNavigate()

  const handleCreateCoachingSessionClicked = useCallback(() => {
    const createCoachingSessionArgs = {
      coworkerId,
      createdDate: new Date().toISOString(),
    }
    createCoachingSession(createCoachingSessionArgs).then((result) => {
      const coachingSessionId = (result as { data: string }).data
      const coachingSessionPath = `${teamleadPath}/coworkers/${coworkerId}/coaching-sessions/${coachingSessionId}`
      navigate(coachingSessionPath)
    })
  }, [coworkerId, createCoachingSession, navigate, teamleadPath])

  return [handleCreateCoachingSessionClicked, meta] as const
}
