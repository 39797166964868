import { colourSemanticNegative,colourSemanticPositive } from "styles/tokens/oldSkapa.tokens";

import { Heading } from "components/primitives/Text";
import styled from "styled-components";

export const PositiveNumber = styled(Heading)`
color: ${colourSemanticPositive};
`
export const NegativeNumber = styled(Heading)`
color: ${colourSemanticNegative};
`
