import Button from '@ingka/button'
import FormField from '@ingka/form-field'
import { Grid } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { MissionStatus } from 'lib/types/mission.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useUser } from 'hooks/users'

import { useExtendMissionMutation, useMission } from 'state/slices/api'

import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { Header } from 'components/layout/Header'
import { DatePicker } from 'components/primitives/DatePicker'

import { useValidators } from './ReviewMissionPage.validation'

type ExtendMissionProps = {
  endDate: Date
}

const ExtendMissionPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { teamleadPath } = useSectionPaths()
  const { coworkerId, missionId } = useParams()

  const { data: user } = useUser()
  const { data: mission, isLoading: isLoadingMissions } = useMission(
    coworkerId && missionId ? { coworkerId, missionId } : skipToken
  )

  const [extendMission] = useExtendMissionMutation()

  const { validateEndDate } = useValidators()

  useEffect(() => {
    if (
      !isLoadingMissions &&
      (!mission || !(mission.status !== MissionStatus.ReadyForReview))
    ) {
      navigate(`${teamleadPath}/coworkers/${coworkerId}`)
    }
  }, [coworkerId, isLoadingMissions, mission, navigate, teamleadPath])

  const extendForm = useForm<ExtendMissionProps>({
    defaultValues: {
      endDate: (() => {
        const date = new Date()
        date.setDate(date.getDate() + 31)
        return date
      })(),
    },
  })

  //React form hook's related
  const {
    control,
    watch,
    formState: { isSubmitSuccessful },
    handleSubmit,
  } = extendForm

  const { endDate } = watch()
  const onSubmit = () => {
    if (!coworkerId || !missionId || !user) return

    const { endDate } = watch()
    extendMission({
      coworkerId,
      missionId,
      extendedTo: DateHelper.dateToString(endDate as Date),
      extendedOn: DateHelper.dateToString(new Date()),
    })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate(`${teamleadPath}/coworkers/${coworkerId}`)
    }
  })

  return (
    <>
      <Header title={t('pages.extend-mission.title')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Widget
          header={
            <WidgetHeader title={`${t('pages.extend-mission.subtitle')}`} />
          }
          footer={
            <WidgetFooter>
              <Button
                small
                type="secondary"
                text={t('common.actions.cancel')}
                htmlType="submit"
                onClick={() =>
                  navigate(
                    `${teamleadPath}/coworkers/${coworkerId}/missions/${missionId}/review`
                  )
                }
              />
              <Button
                small
                type="primary"
                text={t('pages.review-mission.actions.extend-mission')}
                htmlType="submit"
              />
            </WidgetFooter>
          }
        >
          <Grid>
            <Grid.Col>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: t(
                    'pages.extend-mission.fields.end-date.errors.missing'
                  ),
                  validate: () => validateEndDate(endDate),
                }}
                render={({ field: { onChange, value } }) => (
                  <FormField>
                    <DatePicker
                      id="end-date"
                      label={t('pages.extend-mission.fields.end-date.title')}
                      onChange={onChange}
                      value={value}
                    />
                  </FormField>
                )}
              />
            </Grid.Col>
          </Grid>
        </Widget>
      </form>
    </>
  )
}
export default ExtendMissionPage
