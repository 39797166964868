import { UserRole } from 'lib/types/user.types'

import {
  AdminPage,
  ManageBadgesPage,
  ManageCountriesPage,
  UsersPage,
} from 'components/pages/admin'
import { QaFormConfigPage } from 'components/pages/admin/QaFormConfigPage'
import { BadgesPage, CoworkerPage } from 'components/pages/coworker'
import { GuestPage } from 'components/pages/global/GuestPage'
import { StartPage } from 'components/pages/global/StartPage'
import {
  CoachingSessionOverviewPage,
  CoachingSessionsPage,
  ExplorePage,
  InteractionPage,
  MissionPage,
  MissionsPage,
  QaPage,
} from 'components/pages/shared'
import {
  AssignMissionPage,
  EditCoachingSessionPage,
  ExtendMissionPage,
  ManageCoworkerPage,
  ManageCoworkersPage,
  ReviewMissionPage,
} from 'components/pages/teamlead'

export type RouteConfig = {
  path: string
  component: React.FC
  nameTranslationKey: string
  allowedRoles?: UserRole[]
}

const globalRoutes: RouteConfig[] = [
  {
    path: '',
    component: StartPage,
    nameTranslationKey: 'navigation.header.app-name',
  },
  {
    path: 'guest',
    component: GuestPage,
    nameTranslationKey: 'pages.guest.title',
  },
]

const adminRoutes = [
  {
    path: '',
    component: AdminPage,
    nameTranslationKey: 'pages.admin.title',
  },
  {
    path: 'badges',
    component: ManageBadgesPage,
    nameTranslationKey: 'pages.manage-badges.title',
  },
  {
    path: 'countries',
    component: ManageCountriesPage,
    nameTranslationKey: 'pages.manage-countries.title',
  },
  {
    path: 'users',
    component: UsersPage,
    nameTranslationKey: 'features.user.title',
  },
  {
    path: 'qa-form-config',
    component: QaFormConfigPage,
    nameTranslationKey: 'pages.qa-form-config.title',
  },
]

const tlRoutes = [
  {
    path: 'coworkers',
    component: ManageCoworkersPage,
    nameTranslationKey: 'features.coworker.coworkers-overview.title',
  },
  {
    path: 'coworkers/:coworkerId',
    component: ManageCoworkerPage,
    nameTranslationKey: 'pages.manage-coworker.title',
  },
  {
    path: 'coworkers/:coworkerId/missions/assign',
    component: AssignMissionPage,
    nameTranslationKey: 'features.mission.assign-mission.title',
  },
  {
    path: 'coworkers/:coworkerId/qa',
    component: QaPage,
    nameTranslationKey: 'pages.qa.title',
  },
  {
    path: 'coworkers/:coworkerId/qa/:interactionId',
    component: InteractionPage,
    nameTranslationKey: 'pages.interaction.title',
  },
  {
    path: 'coworkers/:coworkerId/missions/:missionId/review',
    component: ReviewMissionPage,
    nameTranslationKey: 'pages.review-mission.title',
  },
  {
    path: 'coworkers/:coworkerId/missions/:missionId/extend',
    component: ExtendMissionPage,
    nameTranslationKey: 'pages.extend-mission.title',
  },
  {
    path: 'coworkers/:coworkerId/explore',
    component: ExplorePage,
    nameTranslationKey: 'pages.explore.title',
  },
  {
    path: 'coworkers/:coworkerId/missions',
    component: MissionsPage,
    nameTranslationKey: 'pages.missions.title',
  },
  {
    path: 'coworkers/:coworkerId/missions/:missionId',
    component: MissionPage,
    nameTranslationKey: 'pages.missions.title',
  },
  {
    path: 'coworkers/:coworkerId/coaching-sessions',
    component: CoachingSessionsPage,
    nameTranslationKey: 'pages.coaching-sessions.title',
  },
  {
    path: 'coworkers/:coworkerId/coaching-sessions/:coachingSessionId',
    component: CoachingSessionOverviewPage,
    nameTranslationKey: 'pages.coaching-session.title',
  },
  {
    path: 'coworkers/:coworkerId/coaching-sessions/:coachingSessionId/edit',
    component: EditCoachingSessionPage,
    nameTranslationKey: 'features.coaching-session.edit-coaching-session.title',
  },
]

const cwRoutes = [
  {
    path: '',
    component: CoworkerPage,
    nameTranslationKey: 'pages.coworker.title',
  },
  {
    path: 'badges',
    component: BadgesPage,
    nameTranslationKey: 'pages.badges.title',
  },
  {
    path: 'explore',
    component: ExplorePage,
    nameTranslationKey: 'pages.explore.title',
  },
  {
    path: 'qa',
    component: QaPage,
    nameTranslationKey: 'pages.qa.title',
  },
  {
    path: 'qa/:interactionId',
    component: InteractionPage,
    nameTranslationKey: 'pages.interaction.title',
  },
  {
    path: 'missions',
    component: MissionsPage,
    nameTranslationKey: 'pages.missions.title',
  },
  {
    path: 'missions/:missionId',
    component: MissionPage,
    nameTranslationKey: 'pages.mission.title',
  },
  {
    path: 'coaching-sessions',
    component: CoachingSessionsPage,
    nameTranslationKey: 'pages.coaching-sessions.title',
  },
  {
    path: 'coaching-sessions/:coachingSessionId',
    component: CoachingSessionOverviewPage,
    nameTranslationKey: 'pages.coaching-session.title',
  },
]

const routes = {
  adminRoutes,
  cwRoutes,
  globalRoutes,
  tlRoutes,
}
export default routes
