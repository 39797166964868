import { CoachingSession } from 'lib/types/coachingSession.types'

import { apiSlice } from 'state/slices/api/api.slice'

const sessionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findCoachingSessionByIdAndCoworkerId: builder.query<
      CoachingSession,
      { coachingSessionId: string; coworkerId: string }
    >({
      query: ({ coachingSessionId, coworkerId }) =>
        `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}`,
      providesTags: ['Coaching Session'],
    }),
    findCoachingSessionsByCoworkerId: builder.query<CoachingSession[], string>({
      query: (coworkerId) => `/coworkers/${coworkerId}/coachingSessions`,
      providesTags: ['Coaching Session'],
    }),
    createCoachingSession: builder.mutation<
      string,
      {
        coworkerId: string
        createdDate: string
      }
    >({
      query: (coworkerId) => ({
        url: `/coworkers/${coworkerId}/coachingSessions`,
        method: 'POST',
      }),
      invalidatesTags: ['Coaching Session'],
    }),
    createMissionDraft: builder.mutation<
      void,
      {
        coachingSessionId: string
        coworkerId: string
        mission: {
          createdDate: string
          metricId: string
          connectedMetricId: string
          target: number
          endDate: string
          createdBy: string
        }
      }
    >({
      query: ({ coachingSessionId, coworkerId, mission }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/missions`,
        method: 'POST',
        body: mission,
      }),
      invalidatesTags: ['Coaching Session', 'Mission'],
    }),
    updateMissionDraft: builder.mutation<
      void,
      {
        coachingSessionId: string
        coworkerId: string
        missionId: string
        mission: any
      }
    >({
      query: ({ coachingSessionId, coworkerId, missionId, mission }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/missions/${missionId}`,
        method: 'PUT',
        body: mission,
      }),
      invalidatesTags: ['Coaching Session', 'Mission'],
    }),
    deleteMissionDraft: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; missionId: string }
    >({
      query: ({ coachingSessionId, coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/missions/${missionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coaching Session', 'Mission'],
    }),
    extendActiveMission: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; missionId: string }
    >({
      query: ({ coachingSessionId, coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/missions/${missionId}/extend`,
        method: 'PUT',
      }),
      invalidatesTags: ['Coaching Session', 'Mission'],
    }),
    removeExtendedActiveMission: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; missionId: string }
    >({
      query: ({ coachingSessionId, coworkerId, missionId }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/missions/${missionId}/extend`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coaching Session', 'Mission'],
    }),
    createActionPointDraft: builder.mutation<
      void,
      {
        actionPoint: {
          title: string
          createdBy: string
          createdDate: string
        }
        coachingSessionId: string
        coworkerId: string
      }
    >({
      query: ({ actionPoint, coachingSessionId, coworkerId }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/actionPoints`,
        method: 'POST',
        body: actionPoint,
      }),
      invalidatesTags: ['Coaching Session', 'Action Point'],
    }),
    updateActionPointDraft: builder.mutation<
      void,
      {
        coachingSessionId: string
        coworkerId: string
        actionPointId: string
        title: string
      }
    >({
      query: ({ coachingSessionId, coworkerId, actionPointId, title }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/actionPoints/${actionPointId}`,
        method: 'PUT',
        body: { title },
      }),
      invalidatesTags: ['Coaching Session', 'Action Point'],
    }),
    deleteActionPointDraft: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; actionPointId: string }
    >({
      query: ({ coachingSessionId, coworkerId, actionPointId }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/actionPoints/${actionPointId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coaching Session', 'Action Point'],
    }),
    extendActiveActionPoint: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; actionPointId: string }
    >({
      query: ({ coachingSessionId, coworkerId, actionPointId }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/actionPoints/${actionPointId}/extend`,
        method: 'PUT',
      }),
      invalidatesTags: ['Coaching Session', 'Action Point'],
    }),
    removeExtendedActiveActionPoint: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; actionPointId: string }
    >({
      query: ({ coachingSessionId, coworkerId, actionPointId }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/actionPoints/${actionPointId}/extend`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coaching Session', 'Action Point'],
    }),
    updateCoworkerComment: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; coworkerComment: string }
    >({
      query: ({ coachingSessionId, coworkerId, coworkerComment }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/coworkerComment`,
        method: 'PUT',
        body: { coworkerComment },
      }),
      invalidatesTags: ['Coaching Session'],
    }),
    startCoachingSession: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; startedDate: string }
    >({
      query: ({ coachingSessionId, coworkerId, startedDate }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/start`,
        method: 'POST',
        body: { startedDate },
      }),
      invalidatesTags: ['Coaching Session'],
    }),
    completeCoachingSession: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; completedDate: string }
    >({
      query: ({ coachingSessionId, coworkerId, completedDate }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/complete`,
        method: 'POST',
        body: { completedDate },
        invalidatesTags: ['Coaching Session'],
      }),
    }),
    signCoachingSession: builder.mutation<
      void,
      { coachingSessionId: string; coworkerId: string; signedDate: string }
    >({
      query: ({ coachingSessionId, coworkerId, signedDate }) => ({
        url: `/coworkers/${coworkerId}/coachingSessions/${coachingSessionId}/sign`,
        method: 'POST',
        body: { signedDate },
      }),
      invalidatesTags: ['Coaching Session'],
    }),
  }),
})

export const {
  useFindCoachingSessionByIdAndCoworkerIdQuery: useCoachingSession,
  useFindCoachingSessionsByCoworkerIdQuery: useCoachingSessions,
  useCreateCoachingSessionMutation: useCreateCoachingSession,
  useStartCoachingSessionMutation: useStartCoachingSession,
  useCompleteCoachingSessionMutation: useCompleteCoachingSession,
  useSignCoachingSessionMutation: useSignCoachingSession,
  useUpdateCoworkerCommentMutation: useUpdateCoworkerComment,
  useCreateMissionDraftMutation: useCreateMissionDraft,
  useCreateActionPointDraftMutation: useCreateActionPointDraft,
  useUpdateMissionDraftMutation: useUpdateMissionDraft,
  useUpdateActionPointDraftMutation: useUpdateActionPointDraft,
  useDeleteMissionDraftMutation: useDeleteMissionDraft,
  useDeleteActionPointDraftMutation: useDeleteActionPointDraft,
  useExtendActiveActionPointMutation: useExtendActiveActionPoint,
  useRemoveExtendedActiveActionPointMutation:
    useRemoveExtendedActiveActionPoint,
  useExtendActiveMissionMutation: useExtendActiveMission,
  useRemoveExtendedActiveMissionMutation: useRemoveExtendedActiveMission,
} = sessionSlice
