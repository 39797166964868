import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UiState {
  showSideBar: boolean
  showSideMenu: boolean
}

const initialState: UiState = {
  showSideBar: false,
  showSideMenu: false,
}

export const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setShowSideBar: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showSideBar: action.payload,
      }
    },
    setShowSideMenu: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showSideMenu: action.payload,
      }
    },
  },
})

export const { setShowSideBar, setShowSideMenu } = uiSlice.actions

export default uiSlice.reducer
