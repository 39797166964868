import FormField from '@ingka/form-field'
import InlineMessage from '@ingka/inline-message'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Coworker } from 'lib/types/coworker.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useValidators } from 'components/pages/teamlead/AssignMissionPage/AssignMissionPage.validation'
import { DatePicker } from 'components/primitives/DatePicker'
import { Subtitle } from 'components/primitives/Text'

import { AssignMissionFormFields } from '../AssignMissionPage.types'

type Props = {
  form: UseFormReturn<AssignMissionFormFields>
  coworker: Coworker
}

const DurationStep: React.FC<Props> = ({ form, coworker }) => {
  const { t } = useTranslation()
  const { control, formState, watch } = form
  const { startDate, endDate } = watch()

  const { validateStartDate, validateEndDate } = useValidators()

  const duration = DateHelper.getDaysBetweenDates(startDate, endDate)

  return (
    <>
      <Subtitle>{t('features.mission.assign-mission.steps.duration')}</Subtitle>
      <Controller
        name="startDate"
        control={control}
        rules={{
          required: t(
            'features.mission.assign-mission.fields.start-date.errors.missing'
          ),
          pattern: {
            value: /^\d{4}-\d{1,2}-\d{1,2}$/,
            message: t(
              'features.mission.assign-mission.fields.start-date.errors.invalid-pattern'
            ),
          },
          validate: validateStartDate,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormField
            data-testid="form-field-start-date"
            shouldValidate={formState.errors.startDate !== undefined}
            valid={formState.errors.startDate == null}
            validation={{
              msg: formState.errors.startDate?.message,
            }}
          >
            <DatePicker
              id="startDate"
              data-testid="input-field-startDate"
              label={t(
                'features.mission.assign-mission.fields.start-date.title'
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          </FormField>
        )}
      />
      <Controller
        name="endDate"
        control={control}
        rules={{
          required: t(
            'features.mission.assign-mission.fields.end-date.errors.missing'
          ),
          pattern: {
            value: /^\d{4}-\d{1,2}-\d{1,2}$/,
            message: t(
              'features.mission.assign-mission.fields.end-date.errors.invalid-pattern'
            ),
          },
          validate: () => validateEndDate(startDate, endDate),
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormField
            data-testid="form-field-end-date"
            fieldHelper={{
              msg: t('features.mission.assign-mission.fields.end-date.hint'),
            }}
            shouldValidate={formState.errors.endDate !== undefined}
            valid={formState.errors.endDate == null}
            validation={{
              msg: formState.errors.endDate?.message,
            }}
          >
            <DatePicker
              id="endDate"
              data-testid="input-field-endDate"
              label={t('features.mission.assign-mission.fields.end-date.title')}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            />
          </FormField>
        )}
      />

      {duration <= 28 && (
        <InlineMessage
          variant="cautionary"
          title={t(
            'features.mission.assign-mission.fields.duration.short-warning.title'
          )}
          body={t(
            'features.mission.assign-mission.fields.duration.short-warning.message'
          )}
        />
      )}
      {duration >= 61 && (
        <InlineMessage
          variant="cautionary"
          title={t(
            'features.mission.assign-mission.fields.duration.long-warning.title'
          )}
          body={t(
            'features.mission.assign-mission.fields.duration.long-warning.message'
          )}
        />
      )}
    </>
  )
}

export default DurationStep
