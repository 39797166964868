import { LoadingBall } from '@ingka/loading'

import { Subtitle } from 'components/primitives/Text'

import * as Styled from './LoadingIndicator.styling'
import { LodingIndicatorProps } from './LoadingIndicator.types'

/** Used to indicate that a component is waiting for something to complete.
 *
 * @prop {string} message - The message to display below the loading indicator.
 * @prop {string} height - The height of the loading indicator - defaults to 70vh.
 */
const LoadingIndicator: React.FC<LodingIndicatorProps> = ({
  message,
  height,
}) => (
  <Styled.Spacer height={height} data-testid="loading-placeholder">
    <LoadingBall />
    {message && <Subtitle>{message}</Subtitle>}
  </Styled.Spacer>
)

export default LoadingIndicator
