import Button from '@ingka/button'

import { colourSemanticPositive } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

export const UnderReviewButton = styled(Button)`
  .svg-icon {
    color: ${colourSemanticPositive};
  }
`
