import Button from '@ingka/button'
import InlineMessage from '@ingka/inline-message'
import { Stack } from '@mantine/core'

import { signOut } from 'config/firebase/firebase.config'

import { Routing } from 'routes'

import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { FullscreenCenter } from 'components/layout/FullScreenCenter'
import { Paragraph } from 'components/primitives/Text'

import { useInitData } from './App.hooks'

const App: React.FC = () => {
  const { isLoading, loadingMessage, error } = useInitData()

  let BlockingMessage: React.FC | undefined = undefined

  if (error) {
    BlockingMessage =
      'status' in error
        ? () => (
            <FullscreenCenter>
              <Stack>
                <InlineMessage
                  title={
                    <>
                      {error.status === 'PARSING_ERROR'
                        ? error.originalStatus
                        : error.status}
                    </>
                  }
                  body={
                    <>
                      {error.status === 'FETCH_ERROR' ||
                      error.status === 'TIMEOUT_ERROR'
                        ? error.error
                        : error.data}
                    </>
                  }
                />
                <Button
                  type="emphasised"
                  onClick={() => signOut()}
                  text="Sign out"
                />
              </Stack>
            </FullscreenCenter>
          )
        : () => (
            <FullscreenCenter>
              <InlineMessage title={error.name} body={error.message} />
              <Paragraph>{error.message}</Paragraph>
            </FullscreenCenter>
          )
  }

  if (isLoading) {
    BlockingMessage = undefined
  }

  return (
    <>
      {BlockingMessage && <BlockingMessage />}
      {isLoading && <LoadingIndicator message={loadingMessage} />}
      {!BlockingMessage && !isLoading && <Routing />}
    </>
  )
}

export default App
