import { useTranslation } from 'react-i18next'

import { Paragraph } from 'components/primitives/Text'

const PerformanceOverview: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Paragraph>
      {t(
        'features.coaching-session.performance-overview.placeholder-description'
      )}
    </Paragraph>
  )
}

export default PerformanceOverview
