import { SkipToken } from '@reduxjs/toolkit/dist/query'

import {
  CoachingSession,
  CoachingSessionStatus,
} from 'lib/types/coachingSession.types'

import { useCoachingSessions } from 'state/slices/api/modules/coachingSession/coachingSession.slice'

/** Fetches the currently upcoming or active coaching session for a co-worker with the given ID */
export const useCurrentCoachingSession = (coworkerId: string | SkipToken) => {
  const {
    data: coachingSessions,
    isSuccess,
    ...meta
  } = useCoachingSessions(coworkerId)

  let latestCoachingSession: CoachingSession | undefined
  let currentCoachingSession: CoachingSession | undefined

  if (!isSuccess) {
    currentCoachingSession = undefined
  }

  if (isSuccess) {
    latestCoachingSession = coachingSessions.find(
      (coachingSession) =>
        coachingSession.status === CoachingSessionStatus.Draft
    )
    currentCoachingSession = latestCoachingSession
  }

  return {
    data: latestCoachingSession,
    currentData: currentCoachingSession,
    isSuccess,
    ...meta,
  }
}
