import { space100 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import React from 'react'

import { Caption, Heading } from 'components/primitives/Text'

import * as Styled from './Widget.styling'

type WidgetHeaderProps = {
  /** If set, creates a heading with the provided text content. */
  title?: string | React.ReactNode
  /** If set, creates a subheading with the provided text content. */
  subtitle?: string | React.ReactNode
  /** If set, shows an icon next to the title */
  ssrIcon?: (prefix: string) => React.SVGProps<SVGElement>[]
  rightSideContent?: React.ReactNode
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  subtitle,
  ssrIcon,
  rightSideContent,
}) => {
  return (
    <Box mb={space100} data-testid="widget-header">
      <Flex justify="space-between" align="center">
        <Flex align="center">
          {ssrIcon && <Styled.HeaderIcon paths={ssrIcon} />}
          {React.isValidElement(title) ? (
            title
          ) : (
            <Heading data-testid="widget-header-title">{title}</Heading>
          )}
        </Flex>
        <Box sx={{ flexGrow: 0 }}>{rightSideContent}</Box>
      </Flex>
      {subtitle && (
        <>
          {React.isValidElement(subtitle) ? (
            subtitle
          ) : (
            <Caption data-testid="widget-header-subtitle">{subtitle}</Caption>
          )}
        </>
      )}
    </Box>
  )
}

export default WidgetHeader
