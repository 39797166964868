import { useParams } from 'react-router-dom'

import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import {
  useCurrentPageType,
  useSectionPaths,
} from 'hooks/navigation/useSectionPaths.hooks'

export const useBackHref = () => {
  const pageType = useCurrentPageType()
  const { teamleadPath, coworkerPath } = useSectionPaths()
  const { coworkerId } = useParams()

  const defaultPath =
    pageType === 'coworker'
      ? `${coworkerPath}/coaching-sessions`
      : pageType === 'teamlead'
      ? `${teamleadPath}/coworkers/${coworkerId}/coaching-sessions`
      : undefined

  return useDefaultBackHref(defaultPath)
}
