import Button from '@ingka/button'
import { LoadingBall } from '@ingka/loading'
import { space100 } from '@ingka/variables'
import { Flex, Grid } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { OperationalMetricId } from 'config/domain/metrics.config'

import { MetricOwnerType } from 'lib/types/metric.types'
import { MissionStatus } from 'lib/types/mission.types'
import { DateHelper } from 'lib/utils/date.helper'

import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCloseMissionMutation, useCoworker, useMissions, useTeamQuery } from 'state/slices/api'

import { MissionStatusIndicator } from 'components/composites/Missions/MissionStatusIndicator'
import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { Stat } from 'components/features/Metrics/Stat'
import { MissionChart } from 'components/features/Missions/MissionChart'
import { Header } from 'components/layout/Header'
import { Divider } from 'components/primitives/Divider'
import { Caption, Paragraph, SubHeading } from 'components/primitives/Text'

const ReviewMissionPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { coworkerId, missionId } = useParams()
  const { teamleadPath } = useSectionPaths()

  const { data: coworker, isLoading: isLoadingCoworker } =
    useCoworker(coworkerId)
  const { data: missions, isLoading: isLoadingMissions } = useMissions(
    coworkerId ?? skipToken
  )

  const { data: team, isLoading: isLoadingTeam } = useTeamQuery(coworker?.teamId)

  const [closeMission, { isSuccess: isCloseMissionSuccess }] =
    useCloseMissionMutation()

  const onSubmitCloseMission = (outcome: 'completed' | 'cancelled') => {
    if (!coworkerId || !missionId) return

    closeMission({ coworkerId, missionId, outcome })
  }

  useEffect(() => {
    if (isCloseMissionSuccess) {
      navigate(`${teamleadPath}/coworkers/${coworkerId}`)
    }
  }, [isCloseMissionSuccess, navigate, teamleadPath, coworkerId])

  const mission = missions?.find((mission) => mission.id === missionId)

  const {
    metricId,
    startDate: unparsedStartDate,
    endDate: unparsedEndDate,
  } = mission || {}

  const startDate = unparsedStartDate ? new Date(unparsedStartDate) : new Date()
  const endDate = unparsedEndDate ? new Date(unparsedEndDate) : new Date()

  const isLoading = isLoadingCoworker || isLoadingMissions || isLoadingTeam

  useEffect(() => {
    if (
      !isLoadingCoworker &&
      (!mission || !(mission.status === MissionStatus.ReadyForReview))
    ) {
      navigate(`${teamleadPath}/coworkers/${coworkerId}`)
    }
  }, [coworkerId, isLoadingCoworker, mission, navigate, teamleadPath])

  const backHref = useDefaultBackHref(`${teamleadPath}/coworkers/${coworkerId}`)

  return (
    <>
      <Header title={t('pages.review-mission.title')} backHref={backHref} />
      {isLoading || !mission || !coworker || !team ? (
        <LoadingBall />
      ) : (
        <Widget
          header={
            <>
              <WidgetHeader
                title={t('pages.review-mission.summary')}
                rightSideContent={
                  <MissionStatusIndicator status={mission.status} />
                }
              />
              <Caption>{`${t('pages.review-mission.description')}`}</Caption>
              <Divider />
            </>
          }
          footer={
            <WidgetFooter>
              <Button
                small
                type="primary"
                text={t('pages.review-mission.actions.complete-mission')}
                htmlType="submit"
                onClick={() => onSubmitCloseMission('completed')}
              />
              <Button
                small
                type="secondary"
                text={t('pages.review-mission.actions.cancel-mission')}
                htmlType="submit"
                onClick={() => onSubmitCloseMission('cancelled')}
              />
              <Button
                small
                type="secondary"
                text={t('pages.review-mission.actions.extend-mission')}
                htmlType="submit"
                onClick={() =>
                  navigate(
                    `${teamleadPath}/coworkers/${coworkerId}/missions/${missionId}/extend`,
                    {
                      state: {
                        from: pathname,
                      },
                    }
                  )
                }
              />
            </WidgetFooter>
          }
        >
          <SubHeading>{t('pages.review-mission.name.title')}</SubHeading>
          <Paragraph>{mission.title}</Paragraph>
          <Divider />
          <Caption>
            {DateHelper.dateToString(new Date(mission.startDate))}
            {' - '}
            {DateHelper.dateToString(new Date(mission.endDate))}
          </Caption>
          <SubHeading>
            {t('pages.review-mission.focus-points.title')}
          </SubHeading>
          <Paragraph>{`${mission.description}`}</Paragraph>
          <Paragraph>
            {t('pages.review-mission.target.mssg', {
              target: mission.target,
            })}
          </Paragraph>
          <Divider />

          <SubHeading>{t('pages.review-mission.result.title')}</SubHeading>
          <>
            <Grid>
              <Grid.Col>
                <Flex gap={space100}>
                  <Stat
                    title={coworker?.fullName}
                    metricId={metricId as OperationalMetricId}
                    id={coworker?.legacyId as string}
                    ownerType={MetricOwnerType.Coworker}
                    period={{
                      start: startDate,
                      end: endDate,
                    }}
                  />
                </Flex>
              </Grid.Col>
              <Divider />
              <Grid.Col>
                <MissionChart
                  mission={mission}
                  coworkerId={coworker.legacyId}
                  teamId={coworker.teamId}
                />
              </Grid.Col>
            </Grid>
          </>
        </Widget>
      )}
    </>
  )
}

export default ReviewMissionPage
