import Button from '@ingka/button'
import chevronDown from '@ingka/ssr-icon/paths/chevron-down'
import { space25, space150 } from '@ingka/variables'
import { Box, Flex } from '@mantine/core'
import { useState } from 'react'

import {
  AchievedBadgeDefinition,
  AwardedBadgeDefinition,
  BadgeDefinition,
  isAchievedBadgeDefinition,
  isAwardedBadgeDefinition,
} from 'lib/types/badge.types'

import { useFormatDate } from 'hooks/formatting'

import { DynamicTooltip, TooltipCaption } from 'components/composites/Shared/Tooltip'
import { Caption, SubHeading } from 'components/primitives/Text'

import { Badge } from '../Badge'
import * as Styled from './BadgeLine.styling'

type BadgeLineProps = {
  badge: BadgeDefinition | AchievedBadgeDefinition | AwardedBadgeDefinition
  expandableBadges?: AchievedBadgeDefinition[]
  showInColor?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const BadgeLine = ({
  badge,
  expandableBadges = [],
  showInColor,
  ...htmlAttributes
}: BadgeLineProps) => {
  const formatDate = useFormatDate()

  const [expand, setExpand] = useState(false)
  const [showTooltip, setShowtooltip] = useState(false)

  const unlocked = isAchievedBadgeDefinition(badge)

  const unlockDate = unlocked
    ? formatDate(new Date(badge.unlockDate))
    : undefined

  const isAwarded = isAwardedBadgeDefinition(badge)

  return (
    <Styled.BadgeLine colored={unlocked || showInColor} {...htmlAttributes}>
      <Flex
        pos="relative"
        justify="space-between"
        align="center"
        w="100%"
        gap={space150}
      >
        <Flex align="center" gap={space25}>
          <Box pos="relative">
            <Badge
              onMouseEnter={() => setShowtooltip(true)}
              onMouseLeave={() => setShowtooltip(false)}
              badge={badge}
              showInColor={unlocked || showInColor}
            />
            {badge.flair && (unlocked || showInColor) && (
              <DynamicTooltip role="tooltip" hidden={!showTooltip}>
                <TooltipCaption>{badge.flair}</TooltipCaption>
              </DynamicTooltip>
            )}
          </Box>

          <Flex direction="column" justify="center">
            <SubHeading>{badge.title}</SubHeading>
            {unlocked && <Caption>{unlockDate}</Caption>}
            <Styled.DescriptionText $awarded={isAwarded}>
              {isAwarded ? badge.message : badge.description}
            </Styled.DescriptionText>
          </Flex>
        </Flex>
        {expandableBadges.length !== 0 && (
          <Button
            iconOnly
            ssrIcon={chevronDown}
            type="tertiary"
            onClick={() => setExpand(!expand)}
          />
        )}
      </Flex>
      {expandableBadges.length !== 0 && (
        <Styled.ExpandableBadges show={expand}>
          {expandableBadges.map((badge) => (
            <BadgeLine key={badge.badgeDefinitionId} badge={badge} />
          ))}
        </Styled.ExpandableBadges>
      )}
    </Styled.BadgeLine>
  )
}

export default BadgeLine
