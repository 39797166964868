import InlineMessage from '@ingka/inline-message'
import { useTranslation } from 'react-i18next'

import { useUser } from 'hooks/users'

import { useInteractions } from 'state/slices/api/modules/interaction'

import {
  InteractionCard,
  InteractionCardSkeleton,
} from 'components/composites/Interactions/InteractionCard'
import { InteractionListProps } from 'components/features/Interactions/InteractionList/InteractionList.types'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

const InteractionList: React.FC<InteractionListProps> = ({ coworkerId }) => {
  const { t } = useTranslation()
  const {
    data: interactions = [],
    isLoading: isLoadingInteractions,
    isSuccess: isSuccessInteractions,
    isError: isErrorInteractions,
  } = useInteractions(coworkerId)

  const {
    data: user,
    isLoading: isLoadingUser,
    isSuccess: isSuccessUser,
    isError: isErrorUser,
  } = useUser()

  const enableComments = user && user.role !== 'coworker'

  const isLoading = isLoadingInteractions || isLoadingUser
  const isSuccess = isSuccessInteractions && isSuccessUser
  const isError = isErrorInteractions || isErrorUser

  return (
    <>
      {isLoading && <InteractionCardSkeleton />}
      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {interactions.length === 0 && (
            <InlineMessage
              variant="informative"
              title={t('features.interaction.errors.no-data')}
            />
          )}
          {interactions.length !== 0 &&
            interactions.map((interaction) => (
              <InteractionCard
                key={interaction.id}
                interaction={interaction}
                enableComments={enableComments}
              />
            ))}
        </>
      )}
    </>
  )
}

export default InteractionList
