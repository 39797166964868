import { Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { ActionPointListProps } from 'components/composites/ActionPoints/ActionPointList/ActionPointList.types'
import { Caption } from 'components/primitives/Text'

import ActionPoint from '../ActionPoint/ActionPoint.component'

const ActionPointList: React.FC<ActionPointListProps> = ({ actionPoints }) => {
  const { t } = useTranslation()

  return (
    <>
      {actionPoints?.length !== 0 && (
        <Stack>
          {actionPoints.map((actionPoint) => (
            <ActionPoint key={actionPoint.id} actionPoint={actionPoint} />
          ))}
        </Stack>
      )}
      {actionPoints == null ||
        (actionPoints.length === 0 && (
          <Caption>{t('features.action-point.no-action-points')}</Caption>
        ))}
    </>
  )
}

export default ActionPointList
