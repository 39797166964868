import { useTranslation } from 'react-i18next'

import { Mission } from 'lib/types/mission.types'
import { DateHelper } from 'lib/utils/date.helper'

import { comparisonColour } from 'styles/tokens/insikt.tokens'

import { MetricLineChart } from 'components/features/Metrics/MetricLineChart'

type MissionChartProps = {
  mission: Mission
  coworkerId: string
  teamId?: string
  currencyCode?: string
}

const MissionChart = ({ mission, coworkerId, teamId }: MissionChartProps) => {
  const { t } = useTranslation()

  const {
    metricId,
    target,
    startDate: unparsedStartDate,
    endDate: unparsedEndDate,
  } = mission
  const startDate = new Date(unparsedStartDate)
  const endDate = new Date(unparsedEndDate)

  const annotations = []

  // We always want to show a minimum of 90 days of data
  // If the start date is less than 90 days, set the start date to 90 days before the end date
  let ninetyDaysAgo = DateHelper.subtractDays(new Date(), 90)
  let chartStartDate = startDate
  if (startDate.getTime() > ninetyDaysAgo.getTime()) {
    chartStartDate = ninetyDaysAgo
  }

  // Add an annotation showing the starting and ending date of the mission
  annotations.push({
    xMin: new Date(mission.startDate).getTime(),
    xMax: new Date(mission.startDate).getTime(),
    borderColor: comparisonColour,
    borderWidth: 2,
    label: {
      display: true,
      position: 'end' as const,
      content: t('features.chart.labels.mission-start'),
    },
  })

  annotations.push({
    xMin: new Date(mission.endDate).getTime(),
    xMax: new Date(mission.endDate).getTime(),
    borderColor: comparisonColour,
    borderWidth: 2,
    label: {
      display: true,
      position: 'end' as const,
      content: t('features.chart.labels.mission-end'),
    },
  })

  return (
    <MetricLineChart
      data-testid="performance-chart"
      metricId={metricId}
      coworkerId={coworkerId}
      teamId={teamId}
      startDate={chartStartDate}
      endDate={endDate}
      target={target}
      annotations={annotations}
    />
  )
}

export default MissionChart
