import { badgeIconMap } from 'lib/badges/icons.map'
import * as badgeTypes from 'lib/types/badge.types'

import * as Styled from './Badge.styling'

type BadgeProps = {
  badge: badgeTypes.AchievedBadgeDefinition | badgeTypes.BadgeDefinition
  showInColor?: boolean
  showLock?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const Badge: React.FC<BadgeProps> = ({
  badge,
  showInColor,
  showLock = true,
  ...otherProps
}) => {
  const unlocked = badgeTypes.isAchievedBadgeDefinition(badge)
  const lockIcon = '🔒'
  const badgeIcon = badge.iconType
  const iconURL = badgeIconMap[badgeIcon]
  const badgeWithIcon = <Styled.BadgeImage src={iconURL} alt={badge.title} />

  const renderedIcon = showLock
    ? unlocked
      ? badgeWithIcon
      : lockIcon
    : badgeWithIcon

  return (
    <>
      <Styled.Badge
        id={`badge-${badge.badgeDefinitionId}`}
        colored={unlocked || showInColor}
        {...otherProps}
      >
        <Styled.EmojiWrapper colored={unlocked || showInColor}>
          <Styled.Emoji>{renderedIcon}</Styled.Emoji>
        </Styled.EmojiWrapper>
      </Styled.Badge>
    </>
  )
}

export default Badge
