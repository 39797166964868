import SkapaText from '@ingka/text'
import { radiusCap } from '@ingka/variables'

import { colourPrimary } from 'styles/tokens/insikt.tokens'
import { colourTextInverse } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

export const TextWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: ${radiusCap};
  background: ${colourPrimary.main};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled(SkapaText)`
  color: ${colourTextInverse};
`
