import { useCallback } from 'react'

import { formatDate } from 'config/internationalisation/locales.config'

import { useLocale } from 'state/slices/api/api.hooks'

export const useFormatDate = () => {
  const { dateFnsCode } = useLocale()
  return useCallback(
    (date: Date) => formatDate(date, dateFnsCode),
    [dateFnsCode]
  )
}
