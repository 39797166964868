import Banner from '@ingka/banner'
import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import laptopPencil from '@ingka/ssr-icon/paths/laptop-pencil'
import pieChart from '@ingka/ssr-icon/paths/pie-chart'
import reward from '@ingka/ssr-icon/paths/reward'
import star from '@ingka/ssr-icon/paths/star'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Tooltip from '@ingka/tooltip'
import { Grid, Stack } from '@mantine/core'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'

import { MissionStatus } from 'lib/types/mission.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'
import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useCoachingSessions, useCoworker, useMissions } from 'state/slices/api'

import {
  Widget,
  WidgetFooter,
  WidgetHeader,
} from 'components/composites/Shared/Widget'
import { CurrentActionPoints } from 'components/features/ActionPoints/CurrentActionPoints'
import { CoworkerActions } from 'components/features/Coworkers/CoworkerActions'
import { CurrentMissionsPreview } from 'components/features/Missions/CurrentMissionsPreview'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { FeatureToggle } from 'components/shared/FeatureToggle'

import { useBackHref } from './CoworkerPage.hooks'
import * as Styled from './CoworkerPage.styling'

const CoworkerPage: React.FC = () => {
  const { t } = useTranslation()
  const { coworkerId } = useParams()
  const { coworkerPath } = useSectionPaths()
  const { pathname } = useLocation()
  const backHref = useBackHref()

  const { data: coworker } = useCoworker(coworkerId)
  const { data: missions = [] } = useMissions(coworkerId ?? skipToken)

  const activeMissions = missions.filter(
    (mission) =>
      mission.status === MissionStatus.Active ||
      mission.status === MissionStatus.ReadyForReview,
  )
  const noMissions = activeMissions.length === 0

  // Pre-fetch coaching sessions
  useCoachingSessions(coworkerId ?? skipToken)

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  const insiktV2Countries = ['ES', 'SE', 'CA', 'NO', 'HR', 'RS', 'SI', 'RO']
  const isV2Country = insiktV2Countries.includes(coworker?.country ?? '')
  const insiktV2Link = 'https://v2.insikt.ingka.com'

  return (
    <div data-testid="page-coworker">
      {isV2Country && (
        <Banner
          emergency
          body={
            <>
              <span>
                {`Great news! Your country has access to the new version of our app`}
              </span>{' '}
              <Link
                to={insiktV2Link}
                style={{
                  color: 'white',
                  fontSize: '0.85rem',
                }}
              >
                {'Click here to view Insikt V2'}
              </Link>
            </>
          }
        />
      )}
      <Header
        backHref={backHref}
        title={coworker ? `Hej ${coworker?.firstName}!` : 'Hej!'}
      />
      <Stack>
        <CoworkerActions coworkerId={coworkerId} />
        <Grid>
          <Grid.Col>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={star}
                  title={t('features.mission.missions-overview.title')}
                />
              }
              footer={
                <WidgetFooter justify="flex-start">
                  {!noMissions &&
                    activeMissions.map((mission) => (
                      <React.Fragment key={mission.id}>
                        {mission.status === MissionStatus.ReadyForReview ? (
                          <Tooltip
                            tooltipText={t(
                              'features.mission.mission-is-ready-for-review',
                            )}
                            position="top"
                          >
                            <Link
                              to={`${coworkerPath}/missions/${mission.id}`}
                              state={{ from: pathname }}
                            >
                              <Styled.UnderReviewButton
                                data-testid="btn-to-mission"
                                type="tertiary"
                                small
                                text={t(mission.title)}
                                ssrIcon={warningTriangle}
                              />
                            </Link>
                          </Tooltip>
                        ) : (
                          <Link
                            to={`${coworkerPath}/missions/${mission.id}`}
                            state={{ from: pathname }}
                          >
                            <Button
                              data-testid="btn-to-mission"
                              type="primary"
                              small
                              text={t(mission.title)}
                              ssrIcon={star}
                            />
                          </Link>
                        )}
                      </React.Fragment>
                    ))}
                  <Link to={`${coworkerPath}/missions`}>
                    <Button
                      data-testid="btn-to-missions"
                      type="tertiary"
                      small
                      text={t('navigation.links.see-all-missions')}
                      ssrIcon={arrowRight}
                      iconPosition="trailing"
                    />
                  </Link>
                </WidgetFooter>
              }
            />
          </Grid.Col>
          <FeatureToggle featureKey="coaching_session">
            <Grid.Col lg={4} md={6}>
              <CurrentMissionsPreview coworkerId={coworkerId} />
            </Grid.Col>

            <Grid.Col lg={8} md={6}>
              <CurrentActionPoints coworkerId={coworkerId} />
            </Grid.Col>
          </FeatureToggle>

          <Grid.Col md={6}>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={pieChart}
                  title={t('pages.explore.title')}
                  subtitle={t('pages.explore.teaser')}
                />
              }
              footer={
                <WidgetFooter>
                  <Link to={`${coworkerPath}/explore`}>
                    <Button
                      data-testid="btn-to-explore"
                      type="tertiary"
                      small
                      iconOnly
                      ssrIcon={arrowRight}
                    />
                  </Link>
                </WidgetFooter>
              }
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={laptopPencil}
                  title={t('pages.qa.title')}
                  subtitle={t('pages.qa.teaser')}
                />
              }
              footer={
                <WidgetFooter>
                  <Link to={`${coworkerPath}/qa`}>
                    <Button
                      data-testid="btn-to-qa"
                      type="tertiary"
                      small
                      iconOnly
                      ssrIcon={arrowRight}
                    />
                  </Link>
                </WidgetFooter>
              }
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <Widget
              header={
                <WidgetHeader
                  ssrIcon={reward}
                  title={t('pages.badges.title')}
                  subtitle={t('pages.badges.teaser')}
                />
              }
              footer={
                <WidgetFooter>
                  <Link to={`${coworkerPath}/badges`}>
                    <Button
                      data-testid="btn-to-badges"
                      type="tertiary"
                      small
                      iconOnly
                      ssrIcon={arrowRight}
                    />
                  </Link>
                </WidgetFooter>
              }
            />
          </Grid.Col>

          <FeatureToggle featureKey="coaching_session">
            <Grid.Col md={6}>
              <Widget
                header={
                  <WidgetHeader
                    ssrIcon={star}
                    title={t('features.coaching-session.coaching-sessions')}
                  />
                }
                footer={
                  <WidgetFooter justify="flex-start">
                    <Link to={`${coworkerPath}/coaching-sessions`}>
                      <Button
                        data-testid="btn-to-coaching-sessions"
                        type="tertiary"
                        small
                        text={t(
                          'features.coaching-session.see-all-coaching-sessions',
                        )}
                        ssrIcon={arrowRight}
                        iconPosition="trailing"
                      />
                    </Link>
                  </WidgetFooter>
                }
              />
            </Grid.Col>
          </FeatureToggle>
        </Grid>
      </Stack>
    </div>
  )
}

export default CoworkerPage
