import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query'

import { Interaction } from 'lib/types/interaction.types'

import { useUser } from 'hooks/users'

import { useCoworker } from '../../api.hooks'
import { useInteractions } from './interaction.slice'

export const useInteractionsWrapper = (coworkerId: string | SkipToken) => {
  const { data: user } = useUser()
  const { role } = user ?? {}

  const isTl = role === 'teamlead'

  const regularQuery = useInteractions(isTl ? skipToken : coworkerId)
  const teamleadQuery = useCoworker(!isTl ? skipToken : coworkerId)

  if (isTl) {
    const { data, ...coworkerMeta } = teamleadQuery
    const interactions = (data as any)?.interactions as
      | Interaction[]
      | undefined

    let copy = interactions ? [...interactions] : undefined
    copy &&
      copy.sort((a, b) => {
        if (
          a.qualityAssessment?.evaluationDate &&
          b.qualityAssessment?.evaluationDate
        ) {
          // Both have dates, compare normally
          return (
            new Date(b.qualityAssessment?.evaluationDate).getTime() -
            new Date(a.qualityAssessment?.evaluationDate).getTime()
          )
        } else {
          // Neither has a date, treat them as equal
          return 0 - new Date(a.handledTimestamp).getTime()
        }
      })

    return {
      ...coworkerMeta,
      data: copy ? copy : interactions,
      currentData: interactions,
    }
  }

  const { data, ...coworkerMeta } = regularQuery

  let copy = data ? [...data] : undefined
  copy &&
    copy.sort((a, b) => {
      if (
        a.qualityAssessment?.evaluationDate &&
        b.qualityAssessment?.evaluationDate
      ) {
        // Both have dates, compare normally
        return (
          new Date(b.qualityAssessment?.evaluationDate).getTime() -
          new Date(a.qualityAssessment?.evaluationDate).getTime()
        )
      } else {
        // Neither has a date, treat them as equal
        return 0 - new Date(a.handledTimestamp).getTime()
      }
    })

  return {
    ...coworkerMeta,
    data: copy ? copy : data,
    currentData: data,
  }

  //return regularQuery
}
