import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { onAuthStateChanged, signIn } from 'config/firebase/firebase.config'

import { invalidateTags, useRegisterSignInMutation } from 'state/slices/api'
import { useAppDispatch } from 'state/utilities'

export const useAuthUser = () => {
  const dispatch = useAppDispatch()
  const [authUser, setAuthUser] = useState<User>()

  const [registerSignIn] = useRegisterSignInMutation()

  useEffect(() => {
    onAuthStateChanged().then((user) => {
      if (user) {
        setAuthUser(user)

        user.getIdToken()?.then(() => registerSignIn(user.uid))

        dispatch(invalidateTags(['Coworker']))
        dispatch(invalidateTags(['User']))
      } else {
        signIn()
      }
    })
  }, [dispatch, registerSignIn])

  return authUser
}
