import { useParams } from 'react-router-dom'

import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

export const useBackHref = () => {
  const { coworkerId } = useParams()
  const { coworkerPath, teamleadPath } = useSectionPaths()

  return useDefaultBackHref(
    `${coworkerPath}/qa` || `${teamleadPath}/coworkers/${coworkerId}/qa`
  )
}
