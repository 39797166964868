import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { CoachingSessionEditor } from 'components/features/CoachingSessions/CoachingSessionEditor'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

import { useBackHref } from './EditCoachingSessionPage.hooks'

const EditCoachingSessionPage: React.FC = () => {
  const { t } = useTranslation()
  const backHref = useBackHref()
  const { coworkerId, coachingSessionId } = useParams()

  useValidateParams(['coworkerId', 'coachingSessionId'])
  if (!coworkerId || !coachingSessionId) return <RedirectingLayout />

  return (
    <>
      <Header
        title={t('features.coaching-session.edit-coaching-session.title')}
        backHref={backHref}
      />
      <CoachingSessionEditor
        coworkerId={coworkerId!}
        coachingSessionId={coachingSessionId}
      />
    </>
  )
}

export default EditCoachingSessionPage
