import { Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useCurrentPageType } from 'hooks/navigation/useSectionPaths.hooks'
import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useCoworker } from 'state/slices/api'

import { MissionPreviewList } from 'components/features/Missions/MissionPreviewList'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'

import { MissionsActions } from './MissionsActions'
import { useBackHref } from './MissionsPage.hooks'

const MissionsPage: React.FC = () => {
  const { t } = useTranslation()

  const pageType = useCurrentPageType()

  const { coworkerId } = useParams()
  const { data: coworker } = useCoworker(coworkerId)

  const backHref = useBackHref()

  useValidateParams('coworkerId')
  if (!coworkerId) return <RedirectingLayout />

  return (
    <div data-testid="page-missions">
      <Header
        backHref={backHref}
        title={t('pages.tl-missions.missions.title', {
          coworker: coworker?.firstName,
        })}
      />
      <Stack>
        {pageType === 'teamlead' && <MissionsActions coworkerId={coworkerId} />}
        <MissionPreviewList coworkerId={coworkerId} />
      </Stack>
    </div>
  )
}

export default MissionsPage
