import Text from '@ingka/text'
import { radiusS, space50, space75 } from '@ingka/variables'

import {
  colourBgInverse,
  colourTextInverse,
} from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

type TooltipProps = {
  maxWidth?: string
}

// Imitation of @ingka/tooltip that supports dynamic content
export const DynamicTooltip = styled.div<TooltipProps>`
  position: absolute;
  background-color: ${colourBgInverse};

  border-radius: ${radiusS};
  padding: ${space50} ${space75};
  transform: translateY(calc(-100% - ${space75})) translateX(-50%);
  top: 0;
  left: 50%;
  max-width: 300px;
  box-sizing: content-box;
  width: max-content;
`

DynamicTooltip.defaultProps = {
  role: 'tooltip',
}

export const TooltipTitle = styled(Text)`
  color: ${colourTextInverse};
`

TooltipTitle.defaultProps = {
  headingSize: 's',
}

export const TooltipCaption = styled(Text)`
  color: ${colourTextInverse};
  font-style: italic;
`

TooltipCaption.defaultProps = {
  bodySize: 's',
}

export const TooltipBody = styled(Text)`
  color: ${colourTextInverse};
`

TooltipBody.defaultProps = {
  bodySize: 's',
}
