import SSRIcon from '@ingka/ssr-icon'
import SkapaTooltip from '@ingka/tooltip'
import {
  durationStandardWeb,
  easeEnter,
  radiusS,
  space50,
  space100,
} from '@ingka/variables'

import {
  backgroundColourWhite,
  shadowDefaultSize,
  shadowRaisedSize,
} from 'styles/tokens/insikt.tokens'

import styled from 'styled-components'

export const Widget = styled.div`
  background-color: ${backgroundColourWhite};

  box-shadow: ${shadowDefaultSize};
  padding: ${space100};
  border-radius: ${radiusS};
  transition: all ${easeEnter} ${durationStandardWeb};

  :hover {
    box-shadow: ${shadowRaisedSize};
  }
`

export const HeaderIcon = styled(SSRIcon)`
  margin-right: ${space50};
  height: 2rem;
  width: 2rem;
`

export const Tooltip = styled(SkapaTooltip)`
  margin-left: auto;
  white-space: pre-wrap;
`
