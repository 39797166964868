import Text from '@ingka/text'
import { thicknessThick } from '@ingka/variables'

import { colourPrimary } from 'styles/tokens/insikt.tokens'

import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`

export const BadgeWrapper = styled.div<{ colored?: boolean }>`
  border: ${thicknessThick} solid ${colourPrimary.main};
  border-radius: 50%;
  ${(props) => (props.colored ? '' : 'filter: grayscale(1)')};
`

export const TextWrapper = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
