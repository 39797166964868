import { User, UserPrefs } from 'lib/types/user.types'

import { apiSlice } from 'state/slices/api/api.slice'

type UpdateUserPrefsParams = {
  userId: string
  prefs: UserPrefs
}

const userPrefsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchPrefs: builder.query<UserPrefs, string>({
      query: (userId) => ({
        url: `/users/${userId}/prefs`,
      }),
      providesTags: ['Prefs'],
    }),
    updatePrefs: builder.mutation<User, UpdateUserPrefsParams>({
      query: ({ userId, prefs }) => ({
        url: `/users/${userId}/prefs`,
        method: 'PUT',
        body: { prefs },
      }),
      invalidatesTags: (result, error, arg) => ['Prefs'],
    }),
  }),
})

export const { useFetchPrefsQuery, useUpdatePrefsMutation } = userPrefsSlice
