import ActionList, { ActionListItem } from '@ingka/action-list'
import Button from '@ingka/button'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sheets,
} from '@ingka/modal'
import Toast from '@ingka/toast'
import { space200 } from '@ingka/variables'
import { Stack } from '@mantine/core'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { APP_VERSION } from 'config/app.config'
import { signOut } from 'config/firebase/firebase.config'

import { useUser } from 'hooks/users'

import { useSyncUserInfo } from 'state/slices/api'

import { Caption, Heading } from 'components/primitives/Text'

import { CoworkerRoleMenuPage, LanguageMenuPage } from './MenuPages'
import * as Styled from './SideMenu.styling'

type SideMenuProps = {
  children?: React.ReactNode
  show: boolean
  onClose: () => void
}

type MenuPageName = 'main' | 'language' | 'coworker-role'

const SideMenu = ({ show, onClose }: SideMenuProps) => {
  const { t } = useTranslation()
  const { data: user } = useUser()
  const [showSyncUserInfoToast, setShowSyncUserInfoToast] = useState(false)
  const [syncUserInfo, { isLoading: isLoadingSyncUserInfo }] = useSyncUserInfo()

  const [menuPage, setMenuPage] = useState<MenuPageName>('main')

  const handleSyncInfoClicked = useCallback(() => {
    if (!user) return
    syncUserInfo(user.id).then(() => window.location.reload())
  }, [syncUserInfo, user])

  const MainMenuPage = () => (
    <>
      <Toast
        isOpen={showSyncUserInfoToast}
        onCloseRequest={() => setShowSyncUserInfoToast(false)}
        text={t('features.user.sync-user-info.success')}
      />
      <Styled.Section>
        <Heading>{t('navigation.sidebar-right.profile.title')}</Heading>
        <ActionList id="profile-action-list">
          <ActionListItem
            label={t('navigation.sidebar-right.profile.name')}
            caption={user?.name}
          />
          <ActionListItem
            label={t('navigation.sidebar-right.profile.email')}
            caption={user?.email}
          />
          <ActionListItem
            label={t('navigation.sidebar-right.profile.role')}
            caption={t(`common.roles.${user?.role}`)}
          />
        </ActionList>
        <Stack mt={space200}>
          <Caption>
            {t('navigation.sidebar-right.sync-info-description')}
          </Caption>
          <Button
            data-testid="btn-sync-info"
            type="secondary"
            onClick={handleSyncInfoClicked}
            loading={isLoadingSyncUserInfo}
          >
            {t('navigation.sidebar-right.sync-info')}
          </Button>
        </Stack>
      </Styled.Section>
      <Styled.Section>
        <Heading>{t('navigation.sidebar-right.settings.title')}</Heading>
        <ActionList id="settings-action-list">
          <ActionListItem
            onClick={() => setMenuPage('language')}
            label={t('navigation.sidebar-right.select-language.title')}
            caption={t('navigation.sidebar-right.select-language.message')}
            control="navigational"
          />
        </ActionList>
      </Styled.Section>
      <Styled.Section>
        <Heading>{t('navigation.sidebar-right.about.title')}</Heading>
        <ActionList id="about-action-list">
          <ActionListItem
            label={t('navigation.sidebar-right.version')}
            caption={APP_VERSION}
          />
        </ActionList>
      </Styled.Section>
      <Stack>
        <Button data-testid="btn-sign-out" type="primary" onClick={signOut}>
          {t('navigation.sidebar-right.sign-out')}
        </Button>
      </Stack>
    </>
  )

  return (
    <Modal visible={show} handleCloseBtn={onClose}>
      <Sheets
        header={
          <ModalHeader
            backBtnClick={
              menuPage !== 'main' ? () => setMenuPage('main') : undefined
            }
          />
        }
        footer={<ModalFooter />}
      >
        <ModalBody>
          {menuPage === 'main' && <MainMenuPage />}
          {menuPage === 'language' && <LanguageMenuPage />}
          {menuPage === 'coworker-role' && <CoworkerRoleMenuPage />}
        </ModalBody>
      </Sheets>
    </Modal>
  )
}

export default SideMenu
