import { QaMetricId } from 'config/domain/qa.config'

export type Mission = {
  id: string
  title: string
  description: string
  startDate: string
  endDate: string
  startValue: number
  metricId: QaMetricId
  target: number
  status: MissionStatus
  result?: MissionResult
  extensions: number
}

export type MissionResult = {
  outcome: MissionOutcome
  finalScore: number
}

export enum MissionStatus {
  Draft = 'draft',
  Active = 'active',
  Closed = 'closed',
  ReadyForReview = 'readyForReview',
}

export enum MissionOutcome {
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export type AssignMissionInput = {
  title: string
  description: string
  startDate: string
  endDate: string
  metricId: string
  target: number
}
