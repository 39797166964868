import { endOfYesterday } from 'date-fns'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { DateHelper } from 'lib/utils/date.helper'

import { useDefaultBackHref } from 'hooks/navigation/useDefaultBackHref.hooks'
import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoworker } from 'state/slices/api'

export const useBackHref = () => {
  const { coworkerId } = useParams()
  const { coworkerPath, teamleadPath } = useSectionPaths()

  return useDefaultBackHref(
    coworkerPath || `${teamleadPath}/coworkers/${coworkerId}`
  )
}

export const useDataSelectionForm = () => {
  const { coworkerId } = useParams()
  const { isSuccess } = useCoworker(coworkerId)

  const form = useForm({
    defaultValues: {
      question: 'total',
      startDate: DateHelper.subtractDays(endOfYesterday(), 90),
      endDate: endOfYesterday(),
    },
  })

  const { reset } = form

  // Once the co-worker has been loaded, reset the form
  useEffect(() => {
    if (isSuccess) {
      reset()
    }
  }, [isSuccess, reset])

  return form
}
