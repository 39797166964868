import InputField from '@ingka/input-field'
import { durationStandardWeb, easeEnter, space100 } from '@ingka/variables'

import { UserRole } from 'lib/types/user.types'

import { colourBackdropLight } from 'styles/tokens/oldSkapa.tokens'

import styled from 'styled-components'

type TableRowProps = {
  userRole: UserRole
}

export const TableRow = styled.tr<TableRowProps>`
  transition: background-color ${easeEnter} ${durationStandardWeb};

  .table-body--striped &:hover {
    ${({ userRole }) =>
      userRole === UserRole.Coworker || userRole === UserRole.TeamLead
        ? 'cursor: pointer;'
        : ''}
    background-color: ${colourBackdropLight};
  }
`

export const SearchField = styled(InputField)`
  margin-top: ${space100};
`
