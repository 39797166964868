import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

/**
 *  Checks if the URL params required for the page to function are present.
 *  If not, navigate to the start page
 */
export const useValidateParams = (expectedParams: string | string[]) => {
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const expectedParamsArray = Array.isArray(expectedParams)
      ? expectedParams
      : [expectedParams]

    const missingParams = expectedParamsArray.filter(
      (param) => !Object.keys(params).includes(param)
    )

    if (missingParams.length > 0) {
      navigate('/')
    }
  }, [expectedParams, navigate, params])
}
