import InlineMessage from '@ingka/inline-message'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useValidateParams } from 'hooks/navigation/useValidateParams'

import { useInteraction } from 'state/slices/api'

import { InteractionCard } from 'components/composites/Interactions/InteractionCard'
import { LoadingIndicator } from 'components/composites/Shared/LoadingIndicator'
import { Header } from 'components/layout/Header'
import { RedirectingLayout } from 'components/layout/RedirectingLayout'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useBackHref } from './InteractionPage.hooks'

const InteractionPage: React.FC = () => {
  const { coworkerId, interactionId } = useParams()
  const { t } = useTranslation()

  const backHref = useBackHref()

  const {
    data: interaction,
    isLoading: isLoadingInteraction,
    isError: isErrorInteraction,
    isSuccess: isSuccessInteraction,
  } = useInteraction(
    coworkerId && interactionId ? { coworkerId, interactionId } : skipToken
  )

  const isError = isErrorInteraction
  const isSuccess = isSuccessInteraction

  useValidateParams(['coworkerId', 'interactionId'])
  if (!coworkerId || !interactionId) return <RedirectingLayout />

  return (
    <div data-testid="page-interaction">
      <Header backHref={backHref} title={`${t('pages.interaction.title')}`} />
      {isLoadingInteraction && (
        <LoadingIndicator
          message={t('loading-messages.fetching-interactions')}
        />
      )}

      {isError && <UnexpectedErrorMessage />}
      {isSuccess && (
        <>
          {!interaction && (
            <InlineMessage
              variant="cautionary"
              title={t('features.interaction.errors.not-found')}
            />
          )}
          {interaction && <InteractionCard interaction={interaction} />}
        </>
      )}
    </div>
  )
}

export default InteractionPage
