import { MetricId } from 'config/domain/metrics.config'

import {
  MetricOwner,
  MetricValueType,
  PeriodicMetric,
  PeriodType,
  TimeRange,
} from 'lib/types/metric.types'

import { apiSlice } from 'state/slices/api/api.slice'

export type QueryMetricParams = {
  metricId: MetricId
  owner: MetricOwner
  periodType: PeriodType
  valueType: MetricValueType
  timeRange: TimeRange
}

export type QueryDynamicMetricParams = Omit<
  QueryMetricParams,
  'periodType' | 'valueType'
>

const metricsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    queryMetric: builder.query<PeriodicMetric[], QueryMetricParams>({
      query: (params) => ({
        url: `/metrics/${params.owner.type}/${params.owner.id}`,
        method: 'GET',
        params: {
          metric: params.metricId,
          startDate: params.timeRange.start,
          endDate: params.timeRange.end,
          periodType: params.periodType,
          valueType: params.valueType,
        },
      }),
      // For simplicity, we simply tag each query individually using a combination of the parameters
      providesTags: (result, error, params) => [
        {
          type: 'Metric',
          id: `Metric-${params.owner.type}-${params.owner.id}-${params.metricId}-${params.timeRange.start}-${params.timeRange.end}-${params.periodType}`,
        },
      ],
    }),
    queryDynamicMetric: builder.query<number, QueryDynamicMetricParams>({
      query: (params) => ({
        url: `/metrics/${params.owner.type}/${params.owner.id}/dynamic`,
        method: 'GET',
        params: {
          metric: params.metricId,
          startDate: params.timeRange.start,
          endDate: params.timeRange.end,
        },
      }),
      // For simplicity, we simply tag each query individually using a combination of the parameters
      providesTags: (result, error, params) => [
        {
          type: 'Metric',
          id: `Metric-${params.owner.type}-${params.owner.id}-${params.metricId}-${params.timeRange.start}-${params.timeRange.end}-dynamic`,
        },
      ],
    }),
  }),
})

export const {
  useQueryMetricQuery: useMetrics,
  useQueryDynamicMetricQuery: useMetric,
} = metricsSlice
