import Button from '@ingka/button'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import crossCircle from '@ingka/ssr-icon/paths/cross-circle'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Paragraph } from 'components/primitives/Text'

import { useCloseMission } from './CancelMissionButton.hooks'
import { CancelMissionButtonProps } from './CancelMissionButton.types'

const CancelMissionButton: React.FC<CancelMissionButtonProps> = ({
  coworkerId,
  mission,
}) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  const [handleCloseMission, { isLoading }] = useCloseMission(
    coworkerId,
    mission
  )

  const handleCloseMissionClicked = useCallback(() => {
    handleCloseMission('cancelled')
  }, [handleCloseMission])

  return (
    <>
      <Button
        small
        ssrIcon={crossCircle}
        iconPosition="trailing"
        type="primary"
        text={t('pages.tl-missions.cancel-mission.actions.cancel-mission')}
        htmlType="submit"
        onClick={() => setVisible(true)}
      />
      <Modal visible={visible} handleCloseBtn={() => setVisible(false)}>
        <Prompt
          title={t('pages.tl-missions.cancel-mission.title')}
          titleId="cancel-mission-modal"
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                small
                type="secondary"
                text={t('common.actions.cancel')}
                onClick={() => setVisible(false)}
              />
              <Button
                small
                type="primary"
                text={t(
                  'pages.tl-missions.cancel-mission.actions.confirm-cancel'
                )}
                htmlType="submit"
                onClick={handleCloseMissionClicked}
                loading={isLoading}
              />
            </ModalFooter>
          }
        >
          <Paragraph>
            {t('pages.tl-missions.cancel-mission.cancel-warning')}
          </Paragraph>
        </Prompt>
      </Modal>
    </>
  )
}

export default CancelMissionButton
