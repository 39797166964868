import handWithHeart from '@ingka/ssr-icon/paths/hand-with-heart'
import Text from '@ingka/text'
import { useTranslation } from 'react-i18next'

import { ActionPointList } from 'components/composites/ActionPoints/ActionPointList'
import LoadingIndicator from 'components/composites/Shared/LoadingIndicator/LoadingIndicator.component'
import { Widget, WidgetHeader } from 'components/composites/Shared/Widget'
import { UnexpectedErrorMessage } from 'components/shared/UnexpectedErrorMessage'

import { useCurrentActionPoints } from './CurrentActionPoints.hooks'
import { CurrentActionPointsProps } from './CurrentActionPoints.types'

const CurrentActionPoints: React.FC<CurrentActionPointsProps> = ({
  coworkerId,
}) => {
  const { t } = useTranslation()

  // get action points for the coworker
  const {
    data: actionPoints,
    isError,
    isLoading,
    isSuccess,
  } = useCurrentActionPoints(coworkerId)

  return (
    <>
      <Widget
        header={
          <WidgetHeader
            ssrIcon={handWithHeart}
            title={t('features.action-point.current-action-points.title')}
            subtitle={t('features.action-point.current-action-points.subtitle')}
          />
        }
      >
        {isLoading && <LoadingIndicator height="300px" />}
        {isError && <UnexpectedErrorMessage />}
        {isSuccess && (
          <>
            {actionPoints && actionPoints.length !== 0 && (
              <ActionPointList actionPoints={actionPoints} />
            )}
            {!actionPoints ||
              (actionPoints.length === 0 && (
                <Text>
                  {t('features.action-point.current-action-points.no-data')}
                </Text>
              ))}
          </>
        )}
      </Widget>
    </>
  )
}

export default CurrentActionPoints
