import FormField from '@ingka/form-field'
import Select, { OptGroup, Option } from '@ingka/select'
import React, { useCallback } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { operationalMetricIds } from 'config/domain/metrics.config'
import { qarGroups } from 'config/domain/qarForm.config'
import { qasGroups } from 'config/domain/qasForm.config'
import { qsaGroups } from 'config/domain/qsaForm.config'

import { Coworker } from 'lib/types/coworker.types'

import { Subtitle } from 'components/primitives/Text'

import { AssignMissionFormFields } from '../AssignMissionPage.types'
import { useValidators } from '../AssignMissionPage.validation'
import { connectedMetricsMatrix } from 'config/domain/connectedMetrics.config'

type Props = {
  form: UseFormReturn<AssignMissionFormFields>
  coworker: Coworker
}

const MetricStep: React.FC<Props> = ({ form, coworker }) => {
  const { t } = useTranslation()
  const { control, formState, watch, resetField, register } = form
  const { validateMetric } = useValidators()

  const [assessmentType, setAssessmentType] = React.useState('QAR')
  const [connectedMetric, setConnectedMetric] = React.useState('')

  const { metricId, connectedMetricId } = watch() // watch input value by passing the name of it

  const handleTypeChanged = useCallback(
    (value: string) => {
      setAssessmentType(value)
      resetField('metricId')
    },
    [resetField]
  )

  const handleConnectedMetricChanged = (value: string) => {
    setConnectedMetric(value)
  }

  return (
    <>
      <Subtitle>{t('features.mission.assign-mission.steps.metric')}</Subtitle>
      <FormField
        data-testid="form-field-assessment-type"
        fieldHelper={{
          msg: t('features.mission.assign-mission.fields.assessment-type.hint'),
        }}
      >
        <Select
          label={t(
            'features.mission.assign-mission.fields.assessment-type.title'
          )}
          id="metric"
          data-testid="input-field-assessment-type"
          onChange={(e) => handleTypeChanged(e.target.value)}
        >
          <Option name={t('qa.assessment-type.QAR')} value="QAR" />
          <Option name={t('qa.assessment-type.QAS')} value="QAS" />
          <Option name={t('qa.assessment-type.QSA')} value="QSA" />
        </Select>
      </FormField>
      <Controller
        name="metricId"
        control={control}
        rules={{
          required: t(
            'features.mission.assign-mission.fields.metric.errors.missing'
          ),
          validate: () => validateMetric(metricId),
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormField
            data-testid="form-field-metric"
            fieldHelper={{
              msg: t('features.mission.assign-mission.fields.metric.hint', {
                coworker: coworker.firstName,
              }),
            }}
            shouldValidate={formState.errors.metricId !== undefined}
            valid={formState.errors.metricId == null}
            validation={{
              msg: formState.errors.metricId?.message as string,
            }}
          >
            <Select
              label={t('features.mission.assign-mission.fields.metric.title')}
              id="metric-id"
              data-testid="input-field-metric"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              {assessmentType === 'QAR' && (
                <>
                  <Option name={t('qa.QARTT')} value="QARTT" />
                  {Object.values(qarGroups).map((group) => (
                    <OptGroup groupName={t(`qa.${group.id}`)} key={group.id}>
                      <Option name={t(`qa.${group.id}`)} value={group.id} />
                      {Object.values(group.questions).map((question) => (
                        <Option
                          key={question.id}
                          name={t(`qa.${question.id}`)}
                          value={question.id}
                        />
                      ))}
                    </OptGroup>
                  ))}
                </>
              )}
              {assessmentType === 'QAS' && (
                <>
                  <Option name={t('qa.QASTT')} value="QASTT" />
                  {Object.values(qasGroups).map((group) => (
                    <OptGroup groupName={t(`qa.${group.id}`)} key={group.id}>
                      <Option name={t(`qa.${group.id}`)} value={group.id} />
                      {Object.values(group.questions).map((question) => (
                        <Option
                          key={question.id}
                          name={t(`qa.${question.id}`)}
                          value={question.id}
                        />
                      ))}
                    </OptGroup>
                  ))}
                </>
              )}
              {assessmentType === 'QSA' && (
                <>
                  <Option name={t('qa.QSATT')} value="QSATT" />
                  {Object.values(qsaGroups).map((group) => (
                    <OptGroup groupName={t(`qa.${group.id}`)} key={group.id}>
                      <Option name={t(`qa.${group.id}`)} value={group.id} />
                      {Object.values(group.questions).map((question) => (
                        <Option
                          key={question.id}
                          name={t(`qa.${question.id}`)}
                          value={question.id}
                        />
                      ))}
                    </OptGroup>
                  ))}
                </>
              )}
            </Select>
          </FormField>
        )}
      />

      <Controller
        name="connectedMetricId"
        control={control}
        rules={{
          required: t(
            'features.mission.assign-mission.fields.connected-metric.errors.missing'
          ),
          validate: () => validateMetric(connectedMetric),
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormField
            data-testid="form-field-connedted-metric"
            fieldHelper={{
              msg: t(
                'features.mission.assign-mission.fields.connected-metric.hint',
                {
                  coworker: coworker.firstName,
                }
              ),
            }}
            shouldValidate={formState.errors.connectedMetricId !== undefined}
            valid={formState.errors.connectedMetricId == null}
            validation={{
              msg: formState.errors.connectedMetricId?.message as string,
            }}
          >
            <Select
              label={t(
                'features.mission.assign-mission.fields.connected-metric.title'
              )}
              id="connected-metric-id"
              data-testid="input-field-connected-metric"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
            >
              {operationalMetricIds.map((id) => {
                return (
                  <Option name={t(`common.metrics.${id}.name`)} value={id} />
                )
              })}
            </Select>
          </FormField>
        )}
      />
    </>
  )
}

export default MetricStep
