import Button from '@ingka/button'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { AssignMissionButtonProps } from './AssignMissionButton.types'

const AssignMissionButton: React.FC<AssignMissionButtonProps> = ({
  coworkerId,
}) => {
  const { t } = useTranslation()
  const { teamleadPath } = useSectionPaths()
  const { pathname } = useLocation()

  return (
    <Link
      to={`${teamleadPath}/coworkers/${coworkerId}/missions/assign`}
      state={{ from: pathname }}
    >
      <Button
        small
        type="primary"
        text={t('features.mission.assign-mission.title')}
      />
    </Link>
  )
}

export default AssignMissionButton
