import InlineMessage from '@ingka/inline-message'
import { Grid } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { InteractionPreview } from 'components/composites/Interactions/InteractionPreview'

import { InteractionPreviewListProps } from './InteractionPreviewList.types'

const InteractionPreviewList: React.FC<InteractionPreviewListProps> = ({
  interactions,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {interactions.length === 0 && (
        <InlineMessage
          variant="cautionary"
          title={t('features.interaction.errors.no-data')}
        />
      )}
      {interactions.length !== 0 && (
        <Grid>
          {interactions.map((interaction) => (
            <Grid.Col md={6} lg={4} key={interaction.resourceId}>
              <InteractionPreview interaction={interaction} />
            </Grid.Col>
          ))}
        </Grid>
      )}
    </>
  )
}

export default InteractionPreviewList
