import Button from '@ingka/button'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { CoachingSessionStatus } from 'lib/types/coachingSession.types'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useCoachingSessions } from 'state/slices/api'

import {
  Widget,
  WidgetFooter,
  WidgetHeader,
  WidgetSkeleton,
} from 'components/composites/Shared/Widget'

import { CoworkerActionsProps } from './CoworkerActions.types'

const CoworkerActions: React.FC<CoworkerActionsProps> = ({ coworkerId }) => {
  const { t } = useTranslation()
  const { coworkerPath } = useSectionPaths()
  const { pathname } = useLocation()

  const {
    data: coachingSessions = [],
    isLoading,
    isSuccess,
  } = useCoachingSessions(coworkerId)

  const coachingSessionAwaitingSignature = coachingSessions.find(
    (coachingSession) =>
      coachingSession.status === CoachingSessionStatus.Completed
  )

  return (
    <>
      {isLoading && (
        <>
          <WidgetSkeleton />
        </>
      )}

      {isSuccess && (
        <>
          {coachingSessionAwaitingSignature && (
            <Widget
              header={
                <WidgetHeader
                  title={t(
                    'features.coaching-session.awaiting-signature.title'
                  )}
                  subtitle={t(
                    'features.coaching-session.awaiting-signature.subtitle'
                  )}
                />
              }
              footer={
                <WidgetFooter>
                  <Link
                    to={`${coworkerPath}/coaching-sessions/${coachingSessionAwaitingSignature?.id}`}
                    state={{ from: pathname }}
                  >
                    <Button
                      type="primary"
                      small
                      text={t(
                        'features.coaching-session.actions.go-to-coaching-session'
                      )}
                    />
                  </Link>
                </WidgetFooter>
              }
            />
          )}
        </>
      )}
    </>
  )
}

export default CoworkerActions
