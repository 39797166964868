import 'fast-text-encoding'
import { getAnalytics, isSupported } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  connectAuthEmulator,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  User,
} from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

import {
  firebaseConfigDev,
  firebaseConfigPreProd,
  firebaseConfigProd,
} from './firebaseProjects.config'

export const isLocalEnv = () => window.location.hostname === 'localhost'

if (process.env.REACT_APP_ENV === undefined) {
  console.warn(
    `REACT_APP_ENV has not been set. This will cause errors when trying to to use the API.`
  )
}

if (process.env.REACT_APP_FUNCTIONS_LOCATION === undefined) {
  console.warn(
    `REACT_APP_FUNCTIONS_LOCATION has not been set. This will cause errors when trying to use the API.`
  )
}

export const firebase = initializeApp(
  process.env.REACT_APP_ENV === 'prod'
    ? firebaseConfigProd
    : process.env.REACT_APP_ENV === 'pre-prod'
    ? firebaseConfigPreProd
    : firebaseConfigDev
)

export const analytics = isSupported().then((isSupported) => {
  if (isSupported) {
    getAnalytics()
  }
})
export const auth = getAuth()
export const functions = getFunctions(
  firebase,
  process.env.REACT_APP_FUNCTIONS_LOCATION
)

export const onAuthStateChanged = async (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export const signIn = async () => {
  const provider = new OAuthProvider('microsoft.com')
  provider.setCustomParameters({
    tenant: '720b637a-655a-40cf-816a-f22f40755c2c',
  })

  // If the browser is Firefox, use a popup instead of a redirect - ||

  if (
    navigator.userAgent.toLowerCase().indexOf('firefox') !== -1 ||
    window.location.hostname === 'localhost'
  ) {
    await signInWithPopup(auth, provider)
  } else {
    await signInWithRedirect(auth, provider)
  }
}

export const signOut = async () => {
  await auth.signOut()
  await signIn()
}

if (isLocalEnv()) {
  // If the code is running in a test environment,
  // disable unnecessary warnings from the emulator
  connectAuthEmulator(auth, 'http://localhost:9099', {
    disableWarnings: process.env.JEST_WORKER_ID != null,
  })
  connectFunctionsEmulator(functions, 'localhost', 5002)
}
